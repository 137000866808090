import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(3),
  },
}));

export function ForgotPasswordWait({ email, onSwitch }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography>
        We've sent an email to <b>{email}</b> with a password reset link. If the
        email does not show up within a minute, make sure to check your spam
        folder.
      </Typography>
      <Button variant="secondary" onClick={onSwitch} fullWidth>
        Back to sign in
      </Button>
    </div>
  );
}
