import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { MultiQuestionSelector } from './multi_question_selector';
import { QuestionSelector } from './question_selector';
import { getRelevantQuestions } from '../common/helpers';
import { FREE_TEXT_TYPES, YES_NO_LOGIC_TYPES } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 6),
  },
  type: {
    marginBottom: theme.spacing(2),
  },
}));

// Percentages require an ending %, that could cause problems if people are mixing types
const NUMBER_LOGIC_TYPES = ['integer', 'dollars'];

const getTypesForAutoFill = (answerType) => {
  if (NUMBER_LOGIC_TYPES.includes(answerType)) {
    return NUMBER_LOGIC_TYPES;
  }

  if (FREE_TEXT_TYPES.includes(answerType)) {
    return FREE_TEXT_TYPES;
  }

  return [answerType];
};

export const AutoFillInput = ({
  answerType,
  questions,
  currentQuestionId,
  setAutoFill,
  autoFill,
}) => {
  const classes = useStyles();
  const setType = (e) => {
    setAutoFill({ ...autoFill, type: e.target.value });
  };
  const setRaw = (e) => {
    setAutoFill({ ...autoFill, raw: e.target.value });
  };
  const isNumber = NUMBER_LOGIC_TYPES.includes(answerType);
  const isBoolean = YES_NO_LOGIC_TYPES.includes(answerType);
  const isFreeText = FREE_TEXT_TYPES.includes(answerType);
  const isRawSupported = isNumber || isFreeText || isBoolean;
  const isSumSupported = isNumber;
  const relevantQuestions = getRelevantQuestions(
    questions,
    [currentQuestionId],
    false,
    getTypesForAutoFill(answerType),
  )
    .filter((q) => q.id !== currentQuestionId)
    .filter((q) => !q.isText && !q.isSignature);

  const renderInput = () => {
    switch (autoFill.type) {
      case 'sum':
        return (
          <MultiQuestionSelector
            questions={relevantQuestions}
            questionIds={autoFill.questionIds}
            op="+"
            onUpdate={(questionIds) => {
              setAutoFill({ ...autoFill, questionIds });
            }}
          />
        );
      case 'copy':
        return (
          <QuestionSelector
            questionId={autoFill.questionId}
            updateAnswerTo={(e) =>
              setAutoFill({ ...autoFill, questionId: e.target.value })
            }
            questions={relevantQuestions}
          />
        );
      case 'raw':
        if (isNumber) {
          return (
            <TextField
              id="text"
              type="number"
              label="Number"
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
              value={autoFill.raw}
              onChange={setRaw}
              fullWidth
            />
          );
        }

        if (isBoolean) {
          return (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="raw">Answer</InputLabel>
              <Select
                label="Answer"
                labelId="raw"
                value={String(autoFill.raw)}
                onChange={(e) =>
                  setAutoFill({ ...autoFill, raw: e.target.value === 'true' })
                }
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          );
        }

        return (
          <TextField
            id="text"
            type="text"
            label="Text"
            variant="outlined"
            value={autoFill.raw}
            onChange={setRaw}
            fullWidth
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" fullWidth className={classes.type}>
        <InputLabel>Type</InputLabel>
        <Select label="Type" value={autoFill.type} onChange={setType}>
          <MenuItem value="copy">Copy from question</MenuItem>
          <MenuItem value="raw" disabled={!isRawSupported}>
            Pre-determined value
          </MenuItem>
          <MenuItem value="sum" disabled={!isSumSupported}>
            Sum
          </MenuItem>
        </Select>
      </FormControl>

      {renderInput()}
    </div>
  );
};
