/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useReducer } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';

import { useSupport } from 'components/support';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { CurrencyProvider } from 'services/providers/currency';
import { Spinner } from 'components/spinner';
import { Navigator } from 'components/lp_doc/navigator';
import { SubdocQuestionnaireAllDoneDisplay } from 'components/lp_doc/all_done/subdoc_questionnaire_all_done';
import { SAVED, SAVING, UNSAVED } from 'components/lp_doc/saved';
import { PdfProvider } from 'components/lp_doc/pdf_provider';
import { SubdocProvider } from 'components/lp_doc/subdoc_provider';
import { SectionOverview } from 'components/lp_doc/section_overview';
import { QuestionStepper } from 'components/lp_doc/question_stepper';
import { DiligenceQuestionStepper } from 'components/lp_doc/diligence_question_stepper';

import { useMe } from 'services/providers/me';
import {
  missingDiligenceDataReducerActions,
  missingDiligenceDataReducerReducer,
} from 'components/lp_doc/diligence/missing_diligence_data_reducer';
import { structureAnnotatedComments } from 'services/utils';
import { hasAdditionalDataBeenProvided } from 'components/lp_doc/diligence/utils';
import { DocumentsDrawerProvider } from 'components/documents_drawer';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { ProfileUsersModal } from './profile/profile_users_modal';
import { sortCollaborators } from './profile/helpers';
import { LpDocPageWithTopBar } from './lp_doc_page_with_toolbar/index';
import { Terms } from './terms';
import { ProfileSelectFlow } from './profile/profile_select_flow/index';
import {
  commentReducerActions,
  commentsReducer,
} from './comments_reducer_utils';
import { FundPrefillFlow } from './fund_prefill_flow';

const getNumLpDocAnswers = (lpDoc) =>
  Object.keys(
    lpDoc?.sections?.reduce(
      (allAnswers, section) => ({
        ...allAnswers,
        ...section?.questions?.reduce((sectionAnswers, question) => {
          if (
            question.answer !== null &&
            question.label !== section?.document?.commitmentLabel
          ) {
            return {
              ...sectionAnswers,
              [question.label]: question.answer,
            };
          }
          return sectionAnswers;
        }, {}),
      }),
      {},
    ) || {},
  ).length;

export function LpDocPage({ setShowTopBar, refresh }) {
  const { lpDocumentId } = useParams();
  const [lpDoc, setLpDoc] = useState(null);
  const [lpDiligence, setLpDiligence] = useState(null);
  const [missingDiligenceDataErrors, dispatchMissingDiligenceDataErrors] =
    useReducer(missingDiligenceDataReducerReducer, {});
  const [lpAcceptance, setLpAcceptance] = useState(null);
  const [activeProfile, setActiveProfile] = useState(null);
  const [availableProfiles, setAvailableProfiles] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [enableLpRequestedReview, setEnableLpRequestedReview] = useState(false);
  const [hasLpRequestedReview, setHasLpRequestedReview] = useState(false);
  const [commentsPerQuestion, dispatch] = useReducer(commentsReducer, {});
  const { hasDraftComment } = useSupport();
  const [me] = useMe();

  // Used for tracking frontend profile setup workflow- holds what option
  // they selected for their profile.
  const [setupProfileId, setSetupProfileId] = useState(null);

  // Determines if we're restarting the questionnaire during the profile setup
  // workflow
  const [restarting, setRestarting] = useState(null);
  const [forcePrefill, setForcePrefill] = useState(false);

  // Used to modify collaborators when switching profiles
  const [setupCollaborators, setSetupCollaborators] = useState([]);

  const [saving, setSaving] = useState(SAVED);
  const [navState, setNavState] = useState({
    innerStep: 0,
    outerStep: 0,
    showDiligenceNav: false,
    showAnimation: true,
  });
  const [lpAccessDialogOpen, setLpAccessDialogOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [numLpDocAnswers, setNumLpDocAnswers] = useState(0);
  const [showUseFundPrefillFlow, setShowUseFundPrefillFlow] = useState(false);

  const { DISCARD_ANSWER_MODAL_FIX } = useFeatureFlags();
  // deprecated when DISCARD_ANSWER_MODAL_FIX is on
  const [showNavWarning, setShowNavWarning] = useState(false);

  const changed = DISCARD_ANSWER_MODAL_FIX
    ? saving === UNSAVED
    : showNavWarning;
  const navRef = useRef();
  const history = useHistory();

  function submitExperienceReview(reviewType, reviewText, provideRecs) {
    api.reviewExperience({
      lpId: lpDoc.lpId,
      lpClosingId: lpDocumentId,
      reviewType,
      reviewText,
      provideRecs,
    });
  }

  function getLpDoc() {
    api
      .lpClosing({ lpClosingId: lpDocumentId })
      .then((response) => {
        setLpDoc(response.data);
        setAvailableProfiles(response.data.availableProfiles);
        setEnableLpRequestedReview(response.data.enableLpRequestedReview);
        const numAnswers = getNumLpDocAnswers(response.data);
        setNumLpDocAnswers(numAnswers);
        const initLpDoc = response.data;
        const initActiveProfile = response.data.investorProfile;
        setShowUseFundPrefillFlow(
          !initActiveProfile && !initLpDoc.isStarted && numAnswers > 0,
        );

        const { comments } = response.data;
        dispatch({
          type: commentReducerActions.INITIALIZE_DATA,
          payload: {
            comments,
          },
        });

        // cannot rely on commentsPerQuestion to be populated at this point
        // so process questions ourselves
        const initialCommentsPerQuestion = structureAnnotatedComments(comments);

        // go to the right initial place
        const newNav = new Navigator({
          lpDoc: response.data,
          navState,
          setNavState,
          setSelectedQuestionId,
          commentsPerQuestion: initialCommentsPerQuestion,
          lpDiligence,
        });
        newNav.goToInitialQuestion();
      })
      .catch((error) => {
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 403
        ) {
          history.push(urls.DASH_URL);
        }
      });
  }

  function getLpAcceptance() {
    api.lpDocAcceptanceGet({ lpDocumentId }).then((response) => {
      setLpAcceptance(response.data);
    });
  }

  function getCollaborators() {
    api
      .getLpClosingCollaborators({ lpClosingId: lpDocumentId })
      .then((response) => {
        setCollaborators(sortCollaborators(response.data));
      });
  }

  function getDiligenceQuestionIdsToValidate() {
    const subdocDiligenceQuestions =
      lpDoc.sections.find((s) => s.type === 'DILIGENCE')?.questions || [];
    const lpDiligenceQuestions =
      lpDiligence?.sections.find((s) => s.type === 'DILIGENCE')?.questions ||
      [];

    const diligenceQuestions = [
      ...subdocDiligenceQuestions,
      ...lpDiligenceQuestions,
    ];
    if (!diligenceQuestions.length) {
      return [];
    }

    // as we set both a child node's name and type on its parent,
    // technically we can only infer that a child has been started
    // if there is data beyond just those 2 values
    const startedDiligenceQuestions = diligenceQuestions.filter((q) =>
      hasAdditionalDataBeenProvided(q.answer, ['name', 'type']),
    );
    const independentDiligenceQuestions = startedDiligenceQuestions.filter(
      (q) => !q.answer.idToReuse,
    );

    return independentDiligenceQuestions.map((q) => q.label);
  }

  function resetDiligenceQuestionValidation() {
    const lpClosingId = lpDoc?.diligenceLpClosing?.id || lpDocumentId;
    return api
      .lpClosingDiligenceQuestionValidation({
        lpClosingId,
        questionsIdsToValidate: getDiligenceQuestionIdsToValidate(),
      })
      .then((resp) => {
        dispatchMissingDiligenceDataErrors({
          type: missingDiligenceDataReducerActions.INITIALIZE_DATA,
          payload: {
            errors: resp.data,
          },
        });
      });
  }

  function updateSingleDiligenceQuestionValidation(qId) {
    const lpClosingId = lpDoc?.diligenceLpClosing?.id || lpDocumentId;
    return api
      .lpClosingDiligenceQuestionValidation({
        lpClosingId,
        questionsIdsToValidate: [qId],
      })
      .then((resp) => {
        dispatchMissingDiligenceDataErrors({
          type: missingDiligenceDataReducerActions.UPDATE_QUESTION_ERRORS,
          payload: {
            label: qId,
            errors: resp.data[qId],
          },
        });
      });
  }

  useEffect(getLpDoc, []);
  const [initializedDiligence, setInitializedDiligence] = useState(false);
  const [updateDiligence, setUpdateDiligence] = useState(0);
  useEffect(() => {
    if (!lpDoc?.diligenceLpClosing) {
      return;
    }
    if (lpDiligence && lpDiligence.isCompleted) {
      return;
    }
    api
      .lpClosing({ lpClosingId: lpDoc?.diligenceLpClosing?.id })
      .then((response) => {
        setLpDiligence(response.data);
        // go to the right initial place
        if (!initializedDiligence) {
          const newNav = new Navigator({
            lpDoc,
            navState,
            setNavState,
            setSelectedQuestionId,
            lpDiligence: response.data,
          });
          newNav.goToInitialQuestion();
          setInitializedDiligence(true);
        }

        const { comments } = response.data;
        dispatch({
          type: commentReducerActions.INITIALIZE_DATA,
          payload: {
            comments,
          },
        });
      })
      .catch((error) => {
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 403
        ) {
          history.push(urls.DASH_URL);
        }
      });
  }, [
    lpDoc?.id,
    lpDoc?.diligenceLpClosing?.id,
    updateDiligence,
    lpDoc?.legalName,
  ]);

  useEffect(() => {
    const hasDiligence =
      lpDoc?.sections?.some((section) => section.type === 'DILIGENCE') ||
      lpDiligence?.sections?.some((section) => section.type === 'DILIGENCE');
    if (!hasDiligence) {
      return;
    }

    resetDiligenceQuestionValidation();
  }, [lpDoc?.id, lpDiligence?.id]);
  useEffect(getLpAcceptance, []);
  useEffect(getCollaborators, []);

  const nav = new Navigator({
    lpDoc,
    navState,
    setNavState,
    setSelectedQuestionId,
    commentsPerQuestion,
    lpDiligence,
  });
  navRef.current = nav;

  const { steps } = nav;
  const currentStep = nav.getCurrentSection();
  const question = nav.getCurrentQuestion();

  const currentLpClosingId = currentStep?.lpClosingId;

  useEffect(() => {
    if (
      lpDoc?.diligenceLpClosing?.id &&
      !lpDoc?.isCompleted &&
      !lpDiligence?.isCompleted
    ) {
      if (lpDoc?.investorProfile?.id !== lpDiligence?.investorProfile?.id) {
        setForcePrefill(true);
        setActiveProfile(null);
        return;
      }
    }

    setActiveProfile(
      currentLpClosingId === lpDoc?.id
        ? lpDoc?.investorProfile
        : lpDiligence?.investorProfile,
    );
  }, [
    lpDoc?.investorProfile?.id,
    lpDiligence?.investorProfile?.id,
    lpDoc?.diligenceLpClosing?.id,
  ]);

  if (
    lpDoc === null ||
    lpAcceptance === null ||
    (lpDoc.diligenceLpClosing && lpDiligence === null)
  ) {
    return <Spinner fullScreen />;
  }

  function onAccept() {
    setLpAcceptance((lpa) => ({ ...lpa, isAccepted: true }));
  }

  if (lpAcceptance.text && !lpAcceptance.isAccepted) {
    return (
      <Terms
        text={lpAcceptance.text}
        onAccept={onAccept}
        fundName={lpDoc.fundName}
        documentDisplayType={lpAcceptance.documentDisplayType}
      />
    );
  }

  function updateCurrentDoc(doc) {
    if (currentLpClosingId === lpDoc.id) {
      setLpDoc(doc);
    } else if (currentLpClosingId === lpDiligence.id) {
      setLpDiligence(doc);
    }
  }

  function onComment(newLpDoc) {
    const { sections, ...commentData } = newLpDoc;
    updateCurrentDoc((oldLpDoc) => ({ ...oldLpDoc, sections }));

    if (commentData) {
      dispatch({
        type: commentReducerActions.ADD_COMMENT,
        payload: {
          commentData,
        },
      });
    }
  }

  function sendLPComment({ label, lpComment, isDiligence }) {
    function onSuccess(response) {
      onComment(response.data);
    }

    return api
      .createLpComment({
        lpClosingId: currentLpClosingId,
        label,
        lpComment,
        isDiligence,
      })
      .then(onSuccess)
      .catch((e) => {
        // rethrow the error so that lower level components
        // can catch it and display their own error messages
        throw e;
      });
  }

  function onAnswer(newLpDoc) {
    updateCurrentDoc((oldLpDoc) => ({ ...oldLpDoc, ...newLpDoc }));

    const { comments } = newLpDoc;

    if (comments) {
      dispatch({
        type: commentReducerActions.REINITIALIZE_COMMENTS_ON_ANSWER,
        payload: {
          comments,
        },
      });
    }

    nav.save();
  }

  function submitAnswer({
    label,
    answer,
    skipQuestion = null,
    missingDataErrorLabelsToClear = [],
  }) {
    setSaving(SAVING);

    function onSuccess(response) {
      setSaving(SAVED);
      onAnswer(response.data);
      if (lpDoc?.legalNameQuestionIds.includes(label)) {
        setUpdateDiligence((c) => c + 1);
      }

      if (missingDataErrorLabelsToClear?.length > 0) {
        dispatchMissingDiligenceDataErrors({
          type: missingDiligenceDataReducerActions.CLEAR_QUESTION_ERRORS,
          payload: {
            labels: missingDataErrorLabelsToClear,
          },
        });
      }
    }

    return api
      .lpDocAnswer({
        lpClosingId: currentLpClosingId,
        label,
        answer,
        skipQuestion,
      })
      .then(onSuccess)
      .catch((error) => {
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 403
        ) {
          refresh();
        } else {
          throw error;
        }
      });
  }

  function onConfirmSection({ sectionId }) {
    setSaving(SAVING);

    function onSuccess(response) {
      setSaving(SAVED);
      onAnswer(response.data);
    }

    return api
      .confirmSection({
        lpId: lpDoc.lpId,
        lpClosingId: currentLpClosingId,
        sectionId,
      })
      .then(onSuccess);
  }

  const diligenceComplete =
    !lpDoc.diligenceLpClosing || lpDiligence?.isCompleted;
  const lpComplete = lpDoc.isCompleted;

  if (diligenceComplete && lpComplete) {
    // can ignore online countersigner only docs as they do
    // not impact the appearance of the all done page
    const areAllSignedDocsOffline = lpDoc.docs.every((doc) =>
      doc.requireSignature ? doc?.file?.fileId : true,
    );

    return (
      <SubdocQuestionnaireAllDoneDisplay
        lpClosingId={lpDoc.id}
        fundName={lpDoc.fundName}
        completionText={lpDoc.subdocCompleteText}
        hasLpRequestedReview={hasLpRequestedReview}
        isOffline={areAllSignedDocsOffline}
        enableReviewForm={lpDoc.experienceReviewFormEnabled}
        submitExperienceReview={submitExperienceReview}
      />
    );
  }

  const isOwnerOfActiveProfile = lpDoc?.ownedProfileIds.includes(
    activeProfile?.id,
  );

  // Profile selection/setup only happens when you don't have a profile and
  // it's not your first time. If you have availableProfiles that means it's
  // not your first time.
  const showProfile =
    !activeProfile &&
    availableProfiles.length > 0 &&
    lpDoc.hasProfileQuestions &&
    me.canUseProfiles;

  if (showProfile) {
    return (
      <ProfileSelectFlow
        setupProfileId={setupProfileId}
        setSetupProfileId={setSetupProfileId}
        organizationName={lpDoc.organizationName}
        fundName={lpDoc.fundName}
        fundLogo={lpDoc.fundLogo}
        lpName={lpDoc.lpName}
        lpClosingId={lpDocumentId}
        availableProfiles={availableProfiles}
        restarting={restarting}
        forcePrefill={forcePrefill}
        setupCollaborators={setupCollaborators}
        setSetupCollaborators={setSetupCollaborators}
        ownedProfileIds={lpDoc.ownedProfileIds}
        documentDisplayType={lpAcceptance.documentDisplayType}
        numLpDocAnswers={numLpDocAnswers}
        refresh={refresh}
      />
    );
  }

  if (showUseFundPrefillFlow) {
    return (
      <FundPrefillFlow
        lpClosingId={lpDocumentId}
        lpName={lpDoc.lpName}
        legalName={lpDoc.legalName}
        organizationName={lpDoc.organizationName}
        refresh={refresh}
      />
    );
  }

  function QuestionStepperComponent({ ...props }) {
    return (
      <QuestionStepper
        navRef={navRef}
        saving={saving}
        setShowNavWarning={setShowNavWarning}
        {...props}
      />
    );
  }

  function DiligenceQuestionStepperComponent({ ...props }) {
    return (
      <DiligenceQuestionStepper
        navRef={navRef}
        saving={saving}
        setShowNavWarning={setShowNavWarning}
        {...props}
      />
    );
  }

  const { currency } = lpDoc;

  return (
    <PdfProvider downloadable={lpDoc.downloadable}>
      <SubdocProvider subdoc={lpDoc.docs.find((doc) => doc.isSubdoc)}>
        <CurrencyProvider currency={currency}>
          <Prompt
            when={changed}
            message="You have not submitted your documents. Are you sure you want to leave?"
          />
          <Prompt
            when={hasDraftComment}
            message="You have unsaved comments. Are you sure you want to leave?"
          />
          <DocumentsDrawerProvider lpDoc={lpDoc} nav={navRef.current}>
            <LpDocPageWithTopBar
              lpDoc={lpDoc}
              lpDiligence={lpDiligence || lpDoc}
              numCollaborators={collaborators.length}
              showNavWarning={changed}
              activeProfile={activeProfile}
              availableProfiles={availableProfiles}
              setActiveProfile={setActiveProfile}
              setSetupProfileId={setSetupProfileId}
              setLpAccessDialogOpen={setLpAccessDialogOpen}
              enableLpRequestedReview={enableLpRequestedReview}
              setHasLpRequestedReview={setHasLpRequestedReview}
              saving={saving}
              setSaving={setSaving}
              question={question}
              currentStep={currentStep}
              steps={steps}
              nav={nav}
              navRef={navRef}
              onAnswer={onAnswer}
              updateLpDoc={submitAnswer}
              QuestionStepperComponent={QuestionStepperComponent}
              DiligenceQuestionStepperComponent={
                DiligenceQuestionStepperComponent
              }
              SectionView={
                <SectionOverview
                  onConfirmSection={onConfirmSection}
                  navRef={navRef}
                  setSaving={setSaving}
                />
              }
              setShowTopBar={setShowTopBar}
              setRestarting={setRestarting}
              selectedQuestionId={selectedQuestionId}
              comments={commentsPerQuestion?.[question?.label] || []}
              sendLPComment={sendLPComment}
              missingDiligenceDataErrors={missingDiligenceDataErrors}
              resetDiligenceQuestionValidation={(label) => {
                updateSingleDiligenceQuestionValidation(label);
              }}
              updateSingleDiligenceQuestionValidation={
                updateSingleDiligenceQuestionValidation
              }
            />
          </DocumentsDrawerProvider>
          <ProfileUsersModal
            open={lpAccessDialogOpen}
            handleClose={() => {
              setLpAccessDialogOpen(false);
            }}
            lpClosingId={lpDocumentId}
            profileId={lpDoc?.investorProfile?.id}
            isClaimedProfile={lpDoc?.investorProfile?.isClaimed}
            profileDisplayName={activeProfile?.displayName}
            isProfileOwner={isOwnerOfActiveProfile}
            collaborators={collaborators}
            getCollaborators={getCollaborators}
          />
        </CurrencyProvider>
      </SubdocProvider>
    </PdfProvider>
  );
}
