import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { Typography, Icons, Chip } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2, 0),
  },
  iconAndText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

function EnabledChip({ enabled }) {
  if (enabled) {
    return <Chip label="Enabled" variant="success" size="small" />;
  }
  return <Chip label="Not enabled" size="small" />;
}

export function MethodTable({
  passwordEnabled = true,
  totpEnabled = false,
  smsEnabled = false,
}) {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.paper}>
      <div className={classes.row}>
        <div className={classes.iconAndText}>
          <Icons.PasswordOutlined color="action" />
          <Typography variant="card-heading">Password</Typography>
        </div>
        <EnabledChip enabled={passwordEnabled} />
      </div>
      <Divider />
      <div className={classes.row}>
        <div className={classes.iconAndText}>
          <Icons.PhoneIphoneOutlined color="action" />
          <Typography variant="card-heading">Authenticator app</Typography>
        </div>
        <EnabledChip enabled={totpEnabled} />
      </div>
      <Divider />
      <div className={classes.row}>
        <div className={classes.iconAndText}>
          <Icons.SmsOutlined color="action" />
          <Typography variant="card-heading">Text message</Typography>
        </div>
        <EnabledChip enabled={smsEnabled} />
      </div>
    </Paper>
  );
}
