import React from 'react';
import { DiligenceTIN } from 'components/lp_doc/diligence/components/tin';
import {
  TYPE_PENSION_SCHEME,
  TYPE_INDIVIDUAL,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LLC,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_PRIVATE_TRUST,
  TYPE_PARTNERSHIP,
  TYPE_SCHOOL_ENDOWMENT,
} from '../../../constants';

import { TinHqAddressDetails } from '../../../components/details/tin_and_hq_address_details';
import { PensionScheme } from '../../../components/details/pension_scheme';
import { IndividualDetails } from '../../../components/details/individual';

import { RootSourceOfWealthDetails } from '../../../subsection_question_display/details_subsection/jurisdiction_specific_details/root_source_of_wealth_data';

export function AdditionalCaymanV2Details({
  isRootNode,
  form,
  formErrors,
  handleChange,
}) {
  const shouldDisplayTinAndHq = [
    TYPE_LIMITED_PARTNERSHIP,
    TYPE_PRIVATE_COMPANY,
    TYPE_LISTED_COMPANY,
    TYPE_LLC,
    TYPE_CHARITY_OR_FOUNDATION,
    TYPE_SCHOOL_ENDOWMENT,
    TYPE_PARTNERSHIP,
  ].includes(form.type);

  const isNestedIndividual = form.type === TYPE_INDIVIDUAL;
  const isPrivateTrust = form.type === TYPE_PRIVATE_TRUST;
  const isPensionScheme = form.type === TYPE_PENSION_SCHEME;

  return (
    <>
      {shouldDisplayTinAndHq ? (
        <TinHqAddressDetails
          // use type as key so that we reset the state of the tin component
          // when the type changes but we still render this comp
          key={form.type}
          tin={form.tin}
          tinError={formErrors.tin}
          hqAddress={form.hqAddress}
          hqAddressError={formErrors.hqAddress}
          handleChange={handleChange}
        />
      ) : null}

      {isNestedIndividual ? (
        <IndividualDetails
          dob={form.dob}
          dobError={formErrors.dob}
          ssn={form.ssn}
          ssnError={formErrors.ssn}
          useSsn={form.useSsn}
          handleChange={handleChange}
          residentialAddress={form.residentialAddress}
          residentialAddressError={formErrors.residentialAddress}
        />
      ) : null}

      {isPrivateTrust ? (
        <DiligenceTIN
          label="TIN"
          tin={form.tin}
          tinError={form.tinError}
          onChange={(e) => {
            handleChange('tin', e.target.value);
          }}
        />
      ) : null}

      {isPensionScheme ? (
        <PensionScheme
          schemeAdminDetails={form.schemeAdminDetails}
          schemeAdminDetailsError={formErrors.schemeAdminDetails}
          amlEntityDetails={form.amlEntityDetails}
          amlEntityDetailsError={formErrors.amlEntityDetails}
          handleChange={handleChange}
        />
      ) : null}

      {isRootNode ? (
        <RootSourceOfWealthDetails
          sourceOfWealth={form.sourceOfWealth}
          sourceOfWealthError={formErrors.sourceOfWealth}
          sourceOfWealthDetails={form.sourceOfWealthDetails}
          sourceOfWealthDetailsError={formErrors.sourceOfWealthDetails}
          handleChange={handleChange}
        />
      ) : null}
    </>
  );
}
