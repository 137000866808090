import { jurisdictions } from './constants';
import { USA_REQUIREMENTS } from './jurisdiction_requirements/usa/usa_requirements_config';
import { USA_V2_REQUIREMENTS } from './jurisdiction_requirements/usa_v2/usa_v2_requirements_config';
import { CAYMAN_REQUIREMENTS } from './jurisdiction_requirements/cayman/cayman_requirements_config';
import { SEI_REQUIREMENTS } from './jurisdiction_requirements/sei/sei_requirements_config';
import { LUXEMBOURG_REQUIREMENTS } from './jurisdiction_requirements/luxembourg/luxembourg_requirements_config';
import { USA_V3_REQUIREMENTS } from './jurisdiction_requirements/usa_v3/usa_v3_requirements_config';
import { CAYMAN_V2_REQUIREMENTS } from './jurisdiction_requirements/cayman_v2/cayman_v2_requirements_config';

const TYPE_TO_REQUIREMENTS = {
  [jurisdictions.USA]: USA_REQUIREMENTS,
  [jurisdictions.USA_V2]: USA_V2_REQUIREMENTS,
  [jurisdictions.CAYMAN]: CAYMAN_REQUIREMENTS,
  [jurisdictions.CAYMAN_V2]: CAYMAN_V2_REQUIREMENTS,
  [jurisdictions.SEI]: SEI_REQUIREMENTS,
  [jurisdictions.LUXEMBOURG]: LUXEMBOURG_REQUIREMENTS,
  [jurisdictions.USA_V3]: USA_V3_REQUIREMENTS,
};

function getJurisdictionRequirements(jurisdiction, ignoreGroupings = true) {
  const allRequirements = TYPE_TO_REQUIREMENTS[jurisdiction];

  if (!ignoreGroupings) {
    return allRequirements;
  }

  const onlyOwnerRequirements = Object.values(allRequirements);
  return onlyOwnerRequirements.reduce(
    (accRequirements, groupRequirements) => ({
      ...accRequirements,
      ...groupRequirements,
    }),
    {},
  );
}

export function getOwnerRequirementsForJurisdiction(jurisdiction) {
  return getJurisdictionRequirements(jurisdiction, true);
}

export function getGroupedOwnerRequirementsForJurisdiction(jurisdiction) {
  return getJurisdictionRequirements(jurisdiction, false);
}
