import React, { useState, useEffect } from 'react';
import { PasswordField } from 'components/password_field';

export function DebouncedPasswordField({
  initialValue,
  setDebouncedPassword,
  ...fieldProps
}) {
  const [password, setPassword] = useState(initialValue || '');

  useEffect(() => {
    if (password === '') {
      setDebouncedPassword('');
      return () => {};
    }
    const timeout = setTimeout(() => {
      setDebouncedPassword(password);
    }, 350);
    return () => clearTimeout(timeout);
  }, [password]);

  return (
    <PasswordField
      {...fieldProps}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
  );
}
