import * as React from 'react';
import { Alert, useConfirm } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import * as api from 'services/api';
import { useClosing } from 'services/providers/closing';
import { useToast } from './toast';

export function useDeleteInvestorsConfirmation({ investors, onDelete }) {
  const { fundId, closingId } = useParams();
  const [{ closing }] = useClosing();
  const confirm = useConfirm();
  const { toast } = useToast();

  return React.useCallback(() => {
    const count = investors.length;
    const plural = count > 1 ? 's' : '';
    const lpClosingIds = investors.map((i) => i.id);
    const anyInvestorHasDiligence = investors.some((i) => i.diligenceLpClosing);

    confirm({
      title: `Delete investor${plural}?`,
      subtitle: `${count} investor${plural} selected`,
      description: (
        <>
          {anyInvestorHasDiligence && closing?.diligenceClosingName ? (
            <Alert severity="info">
              Investors will also be deleted from {closing.diligenceClosingName}
              .
            </Alert>
          ) : null}
          Deleted investors will no longer have access to their documents. This
          action can't be undone.
        </>
      ),
      destructive: true,
      confirmationText: 'Delete',
    })
      .then(() => {
        api.deleteClosingDocs({ fundId, closingId, lpClosingIds }).then(() => {
          toast(`Deleted ${count} investor${plural}.`);
          onDelete();
        });
      })
      .catch(() => {});
  }, [investors, onDelete, fundId, closingId]);
}
