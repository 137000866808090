import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useConfirm } from '@passthrough/uikit';
import { useSession } from 'services/providers/session';
import { AccountPage } from './account';
import { MFASetupPage } from './mfa_setup';

const ACCOUNT_SETTINGS_VIEW = 1;
const MFA_SETUP_VIEW = 2;

export function UserSettingsPage() {
  const [view, setView] = useState(ACCOUNT_SETTINGS_VIEW);
  const [showLeaveWarning, setShowLeaveWarning] = useState(true);
  const confirm = useConfirm();
  const { disableTimeoutDialog } = useSession();

  const handleBack = () => {
    if (!showLeaveWarning) {
      setView(ACCOUNT_SETTINGS_VIEW);
      return;
    }

    confirm({
      title: 'Leave two-factor authentication (2FA) setup?',
      description: 'Your 2FA settings have not been saved.',
      destructive: true,
      confirmationText: 'Leave',
    })
      .then(() => setView(ACCOUNT_SETTINGS_VIEW))
      .catch(() => {});
  };

  const handleSetupFinished = () => {
    disableTimeoutDialog();
    setShowLeaveWarning(false);
  };

  useEffect(() => {
    if (view === MFA_SETUP_VIEW && showLeaveWarning) {
      window.onbeforeunload = () => true;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [view, showLeaveWarning]);

  if (view === MFA_SETUP_VIEW) {
    return (
      <>
        <Prompt
          when={showLeaveWarning}
          message="Your 2FA settings have not been saved. Are you sure you want to leave?"
        />
        <MFASetupPage
          onBack={handleBack}
          showBackButton={showLeaveWarning}
          onFinished={handleSetupFinished}
        />
      </>
    );
  }
  if (view === ACCOUNT_SETTINGS_VIEW) {
    return <AccountPage goToMFASetup={() => setView(MFA_SETUP_VIEW)} />;
  }
  return null;
}
