import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Typography, Button, Chip, Icons, Alert } from '@passthrough/uikit';
import { RadioCardChoice, RadioCardContainer } from 'components/radio_card';
import { useWhiteLabelConfig } from 'services/providers/theme';

import * as constants from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  withChip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export function SelectMethodStep({
  loading,
  method,
  setMethod,
  methodError,
  onContinue,
  onBack,
}) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  return (
    <div className={classes.container}>
      <Alert severity="success">
        {productName} will use this password after setup is complete.
      </Alert>
      <div>
        <Typography variant="card-heading">
          Select your preferred method
        </Typography>
        <Typography>
          Choose a method to receive your two-factor authentication (2FA) code.
        </Typography>
      </div>
      <RadioCardContainer selectedChoice={method} setSelectedChoice={setMethod}>
        <RadioCardChoice
          choiceKey={constants.TOTP_METHOD}
          choiceValue={constants.TOTP_METHOD}
          choiceBackground="white"
          cardContent={
            <div className={classes.withChip}>
              <Typography>Authenticator app</Typography>
              <Chip label="Most secure" variant="info" size="small" />
            </div>
          }
          icon={<Icons.PhoneIphoneOutlined color="action" />}
        />
        <RadioCardChoice
          choiceKey={constants.SMS_METHOD}
          choiceValue={constants.SMS_METHOD}
          choiceBackground="white"
          choiceTitle="Text message"
          icon={<Icons.SmsOutlined color="action" />}
        />
        {methodError ? (
          <FormHelperText error>{methodError}</FormHelperText>
        ) : null}
      </RadioCardContainer>
      <div className={classes.buttons}>
        <Button
          fullWidth
          variant="primary"
          onClick={onContinue}
          loading={loading}
        >
          Continue
        </Button>
        <Button fullWidth variant="secondary" onClick={onBack}>
          Back to password setup
        </Button>
      </div>
    </div>
  );
}
