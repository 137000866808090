import React from 'react';
import { Typography, Link, UIKitSettingsProvider } from '@passthrough/uikit';

const ARTICLE_URL =
  'https://support.passthrough.com/en_us/how-to-enable-2-factor-authentication-HkLedFHh9';

export function SupportArticleLink() {
  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      <Typography variant="body" size="small">
        <Link href={ARTICLE_URL} variant="external">
          What is two-factor authentication (2FA)?
        </Link>
      </Typography>
    </UIKitSettingsProvider>
  );
}
