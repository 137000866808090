import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormHelperText from '@material-ui/core/FormHelperText';

import { Modal, Alert, Typography } from '@passthrough/uikit';

import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';
import {
  SCREENING_CATEGORIES,
  DEFAULT_SCREENING_CATEGORIES,
} from 'services/constants';

const Category = ({ label, checked, onChange, disabled }) => (
  <FormControlLabel
    control={
      <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
    }
    label={label}
  />
);

export function DiligenceSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  const defaultCategories = Object.values(DEFAULT_SCREENING_CATEGORIES);

  const shouldDisableSave = initialState.enableAdverseMediaOnAllNodes;

  // Show alert when enabling adverse media on all nodes
  const showAdverseMediaAlert =
    form.enableAdverseMediaOnAllNodes &&
    form.enableAdverseMediaOnAllNodes !==
      initialState.enableAdverseMediaOnAllNodes;

  const screeningCategoriesSetting = () => (
    <>
      <div>
        <Typography variant="card-heading">Screening categories</Typography>
      </div>
      {showAdverseMediaAlert ? (
        <Alert severity="warning">
          There will be a significant increase in updates for the investors.
          Confirm the compliance requirements before saving. This action cannot
          be undone.
          <br /> Note that it may take up to 24 hours for adverse media alerts
          to be reflected for existing investors.
        </Alert>
      ) : null}
      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors.enableAdverseMediaOnAllNodes}
      >
        {defaultCategories.map((category) => (
          <Category key={category} label={category} checked disabled />
        ))}

        <Category
          key={SCREENING_CATEGORIES.ADVERSE_MEDIA}
          label={SCREENING_CATEGORIES.ADVERSE_MEDIA}
          checked={form.enableAdverseMediaOnAllNodes}
          disabled={initialState.enableAdverseMediaOnAllNodes}
          onChange={() => {
            setForm({
              ...initialState,
              enableAdverseMediaOnAllNodes: !form.enableAdverseMediaOnAllNodes,
            });
          }}
        />

        {errors.enableAdverseMediaOnAllNodes ? (
          <FormHelperText>{errors.enableAdverseMediaOnAllNodes}</FormHelperText>
        ) : null}
      </FormControl>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Edit diligence settings"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      primaryButtonDisabled={shouldDisableSave}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      {screeningCategoriesSetting()}
    </Modal>
  );
}
