import React from 'react';
import { FileUpload } from '@passthrough/uikit';
import { getEmptyFile } from 'components/file_upload';
import { ShowCaseBoxRow } from '../../show_case';

export const SingleFileUploadShowCase = ({ uploadFiles }) => {
  const [files, setFiles] = React.useState(getEmptyFile());

  return (
    <ShowCaseBoxRow>
      <FileUpload
        files={files}
        onAccept={(uploadedFiles) => setFiles(uploadedFiles)}
        onDelete={() => {
          setFiles([]);
        }}
        uploadFiles={uploadFiles}
      />
    </ShowCaseBoxRow>
  );
};
