import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    alignSelf: 'end',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(0, 2),
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

export const MenuCard = ({ title, children, actions, onClose, fullScreen }) => {
  const classes = useStyles();

  return (
    <Fade key="step_1" in>
      <div className={fullScreen ? classes.fullScreen : undefined}>
        <div>
          <div className={classes.header}>
            <Typography variant="card-heading">{title}</Typography>

            <IconButton
              onClick={onClose}
              size="small"
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>{children}</div>
        </div>
        <div className={classes.actions}>{actions}</div>
      </div>
    </Fade>
  );
};
