import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@passthrough/uikit';

import { PasswordField } from 'components/password_field';
import { DebouncedPasswordField } from 'components/debounced_password_field';
import { StrengthIndicator } from 'components/strength_indicator';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(3),
  },
  passwords: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
}));

export function NewPasswordForm({ onError, onSuccess }) {
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(null);
  const [passwordWarning, setPasswordWarning] = useState('');
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!password) {
      setPasswordScore(null);
      setPasswordError('');
      return;
    }

    api
      .checkPasswordForPasswordReset({ password })
      .then((response) => {
        const { score, feedback } = response.data;
        setPasswordError('');
        setPasswordScore(score);
        setPasswordWarning(feedback.warning);
        setPasswordSuggestions(feedback.suggestions);
      })
      .catch((error) => {
        if (error?.response?.data?.password) {
          setPasswordError(error.response.data.password);
        } else {
          onError();
        }
      });
  }, [password]);

  const computedRetypePasswordError =
    retypePasswordError && password !== retypePassword
      ? retypePasswordError
      : '';

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    api
      .resetPassword({ password, retypePassword })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        if (error?.response?.data?.password) {
          setPasswordError(error.response.data.password);
        } else if (error?.response?.data?.retypePassword) {
          setRetypePasswordError(error.response.data.retypePassword);
        } else {
          onError();
        }
        setLoading(false);
      });
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <div className={classes.passwords}>
        <div>
          <DebouncedPasswordField
            id="password"
            size="medium"
            label="Password"
            variant="outlined"
            error={!!passwordError || (!!password && passwordScore <= 2)}
            helperText={passwordError}
            fullWidth
            autoComplete="new-password"
            autoFocus
            setDebouncedPassword={setPassword}
          />
          <StrengthIndicator
            score={passwordScore}
            warning={passwordWarning}
            suggestions={passwordSuggestions}
          />
        </div>
        <PasswordField
          id="password-again"
          label="Password again"
          variant="outlined"
          value={retypePassword}
          error={!!computedRetypePasswordError}
          helperText={computedRetypePasswordError}
          onChange={(e) => {
            setRetypePassword(e.target.value);
          }}
          fullWidth
          autoComplete="new-password"
        />
      </div>
      <Button
        loading={loading}
        fullWidth
        variant="primary"
        onClick={handleSubmit}
        type="submit"
      >
        Change password
      </Button>
    </form>
  );
}
