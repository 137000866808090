import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  UIKitSettingsProvider,
  Icons,
  Link,
  Chip,
} from '@passthrough/uikit';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { DiffStyle } from 'components/Diff';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  icon: {
    width: theme.spacing(4),
    minWidth: 0,
    flex: '0 0 auto',
  },

  iconMargin: {
    marginTop: 0,
  },

  text: {
    flex: 1,
    wordBreak: 'break-word',
    margin: 0,
  },

  singleDocEntry: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0, 5),
    margin: 0,
    alignItems: 'center',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    flex: 1,
  },
}));

function isPdfFile(fileName) {
  try {
    return fileName.split('.').reverse()[0] === 'pdf';
  } catch {
    return false;
  }
}

function OpenDocumentButton({ fileName, type, url, handleOpenDocument }) {
  const canOpenInApp = isPdfFile(fileName);

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      {canOpenInApp ? (
        <Button
          variant="secondary"
          size="small"
          onClick={() => handleOpenDocument({ type, fileName, url })}
        >
          Open
        </Button>
      ) : (
        <Link variant="external" href={url}>
          Open
        </Link>
      )}
    </UIKitSettingsProvider>
  );
}

function SingleDocCardEntry({
  type,
  file,
  handleOpenDocument,
  lastSubmittedAnswer,
  isDeleted = false,
}) {
  const classes = useStyles();

  const name = file?.fileName;
  const url = file?.fileUrl;
  const previousFile = lastSubmittedAnswer?.find(
    (lastFile) => lastFile.fileId === file?.fileId,
  );
  const isNew = Boolean(lastSubmittedAnswer) && !previousFile;
  return (
    <ListItem alignItems="flex-start" className={classes.singleDocEntry}>
      <ListItemText disableTypography className={classes.text}>
        {isDeleted ? (
          <DiffStyle isDeleted thin>
            <Typography variant="label" size="small" color="text.secondary">
              {name}
            </Typography>
          </DiffStyle>
        ) : (
          <Typography variant="label" size="small">
            <DiffStyle isNew={isNew}>{name}</DiffStyle>
          </Typography>
        )}
      </ListItemText>
      {!isDeleted && (
        <OpenDocumentButton
          fileName={name}
          type={type}
          url={url}
          handleOpenDocument={handleOpenDocument}
        />
      )}
    </ListItem>
  );
}

export function MultiDocCardEntry({
  type,
  fileData,
  handleOpenDocument,
  lastSubmittedAnswer,
}) {
  const classes = useStyles();
  const dataWasSubmitted =
    fileData.length > 0 || lastSubmittedAnswer?.length > 0;

  const deletedFiles = lastSubmittedAnswer?.filter(
    (lastFile) => !fileData.some((file) => file.fileId === lastFile.fileId),
  );

  return (
    <>
      <ListItem alignItems={dataWasSubmitted ? 'flex-start' : 'center'}>
        <ListItemIcon
          className={clsx(classes.icon, {
            [classes.iconMargin]: dataWasSubmitted,
          })}
        >
          <Icons.InsertDriveFileOutlined />
        </ListItemIcon>
        <ListItemText disableTypography className={classes.text}>
          {type ? (
            <Typography
              variant="body"
              size="medium"
              color={dataWasSubmitted ? 'text.primary' : 'text.secondary'}
            >
              {type}
            </Typography>
          ) : null}
        </ListItemText>
        {!dataWasSubmitted && (
          <Chip variant="neutral" size="small" label="Not provided" />
        )}
      </ListItem>

      {dataWasSubmitted &&
        fileData?.map((file) => (
          <SingleDocCardEntry
            key={file.fileId}
            type={type}
            file={file}
            handleOpenDocument={handleOpenDocument}
            lastSubmittedAnswer={lastSubmittedAnswer}
          />
        ))}

      {deletedFiles?.map((file) => (
        <SingleDocCardEntry
          key={file.fileId}
          type={type}
          file={file}
          handleOpenDocument={handleOpenDocument}
          lastSubmittedAnswer={lastSubmittedAnswer}
          isDeleted
        />
      ))}
    </>
  );
}
