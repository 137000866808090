import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  UIKitSettingsProvider,
  Icons,
  Link,
  Chip,
} from '@passthrough/uikit';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { DiffStyle } from 'components/Diff';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  icon: {
    width: theme.spacing(4),
    minWidth: 0,
    flex: '0 0 auto',
  },

  iconMargin: {
    marginTop: '4px',
  },

  text: {
    flex: 1,
    wordBreak: 'break-word',
    margin: 0,
  },
}));

function isPdfFile(fileName) {
  try {
    return fileName.split('.').reverse()[0] === 'pdf';
  } catch {
    return false;
  }
}

function OpenDocumentButton({ fileName, type, url, handleOpenDocument }) {
  const canOpenInApp = isPdfFile(fileName);

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      {canOpenInApp ? (
        <Button
          variant="secondary"
          size="small"
          onClick={() => handleOpenDocument({ type, fileName, url })}
        >
          Open
        </Button>
      ) : (
        <Link variant="external" href={url}>
          Open
        </Link>
      )}
    </UIKitSettingsProvider>
  );
}

export function DocumentCardEntry({
  type,
  fileData,
  handleOpenDocument,
  lastSubmittedAnswer,
}) {
  const classes = useStyles();

  const dataWasSubmitted =
    Object.keys(fileData).length > 0 || lastSubmittedAnswer;
  const name = fileData?.fileName;
  const url = fileData?.fileUrl;
  const isNew =
    Boolean(lastSubmittedAnswer) &&
    lastSubmittedAnswer?.fileId !== fileData?.fileId;

  return (
    <ListItem alignItems={dataWasSubmitted ? 'flex-start' : 'center'}>
      <ListItemIcon
        className={clsx(classes.icon, {
          [classes.iconMargin]: dataWasSubmitted,
        })}
      >
        <Icons.InsertDriveFileOutlined />
      </ListItemIcon>
      <ListItemText disableTypography className={classes.text}>
        {type ? (
          <Typography
            variant="body"
            size="medium"
            color={dataWasSubmitted ? 'text.primary' : 'text.secondary'}
          >
            {type}
          </Typography>
        ) : null}

        {lastSubmittedAnswer?.fileName &&
        lastSubmittedAnswer?.fileName !== name ? (
          <DiffStyle isDeleted thin>
            <Typography variant="label" size="small" color="text.secondary">
              {lastSubmittedAnswer?.fileName}
            </Typography>
          </DiffStyle>
        ) : null}
        {dataWasSubmitted ? (
          <Typography variant="label" size="small">
            <DiffStyle isNew={isNew}>{name}</DiffStyle>
          </Typography>
        ) : null}
      </ListItemText>
      {dataWasSubmitted ? (
        <OpenDocumentButton
          fileName={name}
          type={type}
          url={url}
          handleOpenDocument={handleOpenDocument}
        />
      ) : (
        <Chip variant="neutral" size="small" label="Not provided" />
      )}
    </ListItem>
  );
}
