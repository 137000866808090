import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';
import * as api from 'services/api';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';
import { useWhiteLabelConfig } from 'services/providers/theme';

import { ExperienceSelector } from '../experience_selector';
import { ExperienceItem } from '../experience_item';
import { ExploreStorage } from '../utils';
import { MenuCard } from './menu_card';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    zIndex: 2,
    maxWidth: '450px',
  },
  step2Div: {
    margin: theme.spacing(1, 0),
  },
}));

export function MilestoneMenu({ onClose, open, anchor, fullScreen }) {
  const classes = useStyles();
  const [experience, setExperience] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errorToast } = useToast();
  const current = ExploreStorage.getExperience();
  const { productName } = useWhiteLabelConfig();

  const onSubmit = () => {
    setLoading(true);

    api
      .switchExploreExperience(experience)
      .then((response) => {
        ExploreStorage.setExperience(experience);
        window.location.href = response.data.url;
      })
      .catch((err) => {
        const msg = getSingleErrorFromResponse(err.response);
        errorToast(msg);
        setLoading(false);
      });
  };

  const handleClose = () => {
    onClose();
    setExperience(null);
  };

  const content = (
    <MenuCard
      onClose={handleClose}
      title="You have completed a milestone!"
      fullScreen={fullScreen}
      actions={
        <>
          <span />
          <Button
            variant="primary"
            onClick={onSubmit}
            disabled={!experience}
            loading={loading}
          >
            Start
          </Button>
        </>
      }
    >
      <ExperienceItem variant="done" experience={current} />
      <div className={classes.step2Div}>
        <Typography variant="label" color="text.secondary">
          Select a new experience to continuing exploring {productName}.
        </Typography>
      </div>
      <ExperienceSelector
        exclude={current}
        experience={experience}
        setExperience={setExperience}
      />
    </MenuCard>
  );

  if (fullScreen) {
    return (
      <Dialog open={open} fullScreen onClose={handleClose}>
        {content}
      </Dialog>
    );
  }

  return (
    <Popper
      open={open}
      anchorEl={anchor?.current}
      disablePortal
      onClose={handleClose}
      placement="top-end"
      className={classes.root}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      }}
    >
      <Paper elevation={3}>{content}</Paper>
    </Popper>
  );
}
