import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Icons } from '@passthrough/uikit';
import { OTPInput } from 'components/otp_input';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  input: {
    backgroundColor: 'white',
  },
}));

export function TOTPForm({
  onSubmit,
  totpCode,
  setTotpCode,
  totpError,
  setTotpError,
  loading,
  onBack = () => {},
  onLogin = () => {},
}) {
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit()
      .then(onLogin)
      .catch(() => {});
  }

  function handleBack() {
    setTotpCode('');
    setTotpError('');
    onBack();
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <Icons.PhoneIphoneOutlined color="action" />
      <div>
        <Typography variant="card-heading">Authenticator app</Typography>
        <Typography variant="body" size="small" color="text.secondary">
          Open your two-factor authenticator app to view your authentication
          code.
        </Typography>
      </div>
      <TextField
        InputProps={{
          inputComponent: OTPInput,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="totpCode"
        label="Authentication code"
        name="totpCode"
        autoComplete="one-time-code"
        autoFocus
        error={!!totpError}
        helperText={totpError}
        value={totpCode}
        onChange={(e) => setTotpCode(e.target.value)}
        disabled={loading}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ inputMode: 'numeric' }}
      />
      <Button
        fullWidth
        variant="primary"
        type="submit"
        onClick={handleSubmit}
        loading={loading}
        disabled={!totpCode}
      >
        Verify
      </Button>
      <Button fullWidth variant="secondary" onClick={handleBack}>
        Back
      </Button>
    </form>
  );
}
