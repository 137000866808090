import React from 'react';
import {
  Chip,
  InternalChip,
  ClickableChip,
  Icons,
  Typography,
} from '@passthrough/uikit';
import { capitalizeFirstLetter } from 'services/utils';
import { ShowCaseBox, ComponentShowCase, ShowCaseBoxRow } from '../show_case';

const VARIANTS = ['info', 'error', 'neutral', 'success', 'warning'];

export const ChipShowCase = () => (
  <ComponentShowCase title="Chip">
    <ShowCaseBox>
      <ShowCaseBoxRow>
        {VARIANTS.map((variant) => (
          <Chip
            key={variant}
            variant={variant}
            label={capitalizeFirstLetter(variant)}
          />
        ))}
        <Chip size="small" label="Small" />
        <Chip size="medium" label="Medium" />
        <Chip label="Text + Icon" icon={<Icons.Delete />} />
        <InternalChip label="Internal chip" />
      </ShowCaseBoxRow>
      <Typography mt={2} align="center">
        <strong>Clickable Chips</strong>
      </Typography>
      <ShowCaseBoxRow mt={2}>
        <ClickableChip label="Clickable chip selected" filter selected />
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={2}>
        {VARIANTS.map((variant) => (
          <ClickableChip
            key={variant}
            variant={variant}
            label={capitalizeFirstLetter(variant)}
          />
        ))}
      </ShowCaseBoxRow>
      <ShowCaseBoxRow mt={2}>
        {VARIANTS.map((variant) => (
          <ClickableChip
            key={variant}
            variant={variant}
            label={capitalizeFirstLetter(variant)}
            defaultIcon={<Icons.Delete />}
          />
        ))}
      </ShowCaseBoxRow>
    </ShowCaseBox>
  </ComponentShowCase>
);
