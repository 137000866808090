import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Typography } from '@passthrough/uikit';
import { SignInPageContainer } from 'components/sign_in_page_container';
import { Spinner } from 'components/spinner';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { getMethod } from 'pages/mfa_setup_flow/constants';

import { VerifySecondFactor } from './verify_second_factor';
import { NewPasswordForm } from './new_password';
import { VERIFIED_SECOND_FACTOR, CLICKED_LINK } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    maxWidth: '460px',
    width: '100%',
    gap: theme.spacing(3),
  },
}));

function LinkError({ userId }) {
  const history = useHistory();
  return (
    <>
      <Alert severity="error">
        The password reset link you used is expired or invalid. Don't worry, you
        can request a new one below.
      </Alert>
      <Button
        variant="secondary"
        fullWidth
        onClick={() => history.push(urls.signinUrl({ userId }))}
      >
        Back to sign in
      </Button>
    </>
  );
}

export function ForgotPassword({ onSuccess }) {
  const history = useHistory();
  const [loadingAlreadyLoggedIn, setLoadingAlreadyLoggedIn] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [linkError, setLinkError] = useState(false);
  const [mfaMethod, setMfaMethod] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const classes = useStyles();

  function checkAlreadyLoggedIn() {
    api
      .me()
      .then(() => {
        history.replace(urls.DASH_URL);
      })
      .catch(() => {
        setLoadingAlreadyLoggedIn(false);
      });
  }

  function fetchPasswordResetInfo() {
    api
      .resetPasswordInfo()
      .then((response) => {
        const { userId: id, isSms, isTotp, status } = response.data;

        setMfaMethod(getMethod({ isSms, isTotp }));
        setTokenStatus(status);
        setUserId(id);
      })
      .catch(() => {
        setLinkError(true);
      })
      .finally(() => {
        setLoadingInfo(false);
      });
  }

  useEffect(checkAlreadyLoggedIn, []);
  useEffect(fetchPasswordResetInfo, []);

  if (loadingAlreadyLoggedIn || loadingInfo) {
    return <Spinner fullScreen />;
  }

  const showError = linkError || !mfaMethod;
  const onError = () => setLinkError(true);

  function handleSuccess() {
    onSuccess();
    history.replace(urls.signinUrl({ userId }));
  }

  return (
    <SignInPageContainer>
      <div className={classes.container}>
        <Typography variant="section-heading">
          {tokenStatus === VERIFIED_SECOND_FACTOR
            ? 'Change your password'
            : 'Reset your password'}
        </Typography>

        {showError ? (
          <LinkError userId={userId} />
        ) : (
          <>
            {tokenStatus === CLICKED_LINK ? (
              <VerifySecondFactor
                mfaMethod={mfaMethod}
                onVerify={() => setTokenStatus(VERIFIED_SECOND_FACTOR)}
                onError={onError}
              />
            ) : null}
            {tokenStatus === VERIFIED_SECOND_FACTOR ? (
              <NewPasswordForm onError={onError} onSuccess={handleSuccess} />
            ) : null}
          </>
        )}
      </div>
    </SignInPageContainer>
  );
}
