export const ExploreStorage = {
  getExperience: () => window.localStorage.getItem('pt-explore-exp'),

  setExperience: (experience) =>
    window.localStorage.setItem('pt-explore-exp', experience),

  getForm: () =>
    JSON.parse(window.localStorage.getItem('pt-explore-form') || '{}'),

  setForm: (form) =>
    window.localStorage.setItem('pt-explore-form', JSON.stringify(form)),
};

export const getExperiences = (productName) => [
  {
    title: 'Fund Manager',
    subtitle: `Navigate ${productName} as an admin. Create closings, add LPs, and countersign agreements.`,
    value: 'general_partner',
  },
  {
    title: 'First-time Investor',
    subtitle: `Complete a demo subscription agreement as a first-time ${productName} investor.`,
    value: 'investor_new',
  },
  {
    title: 'Repeat Investor',
    subtitle: `Complete a demo subscription agreement as a repeat ${productName} investor, pre-filling previously entered information where applicable.`,
    value: 'investor_repeat',
  },
  {
    title: 'KYC/AML Subject',
    subtitle: `Complete a KYC/AML diligence request as a first-time ${productName} user.`,
    value: 'investor_kyc',
  },
];

export const getExperience = (productName, experience) =>
  getExperiences(productName).find((exp) => exp.value === experience);
