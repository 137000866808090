import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { Alert } from 'components/alert';
import LockIcon from '@material-ui/icons/LockOutlined';

import { KeyValuePair } from 'components/key_value';
import { StrengthIndicator } from 'components/strength_indicator';
import { PasswordField } from 'components/password_field';

const useStyles = makeStyles((theme) => ({
  passwords: {
    marginTop: theme.spacing(1),
  },
  lockIcon: {
    marginTop: '10px',
  },
  alert: {
    marginTop: theme.spacing(1),
  },
}));

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

export function AddPassword({
  enablePassword,
  setEnablePassword,
  password,
  passwordError,
  passwordScore,
  setPassword,
  passwordWarning,
  passwordSuggestions,
  retypePassword,
  retypePasswordError,
  setRetypePassword,
  socialProvider,
}) {
  const classes = useStyles();

  return (
    <KeyValuePair iconClassName={classes.lockIcon} input icon={<LockIcon />}>
      <FormControlLabel
        control={
          <Switch
            checked={enablePassword}
            onChange={() => {
              setEnablePassword((s) => !s);
            }}
            name="enable-password"
            color="primary"
          />
        }
        label="Use a password for 2-step authentication"
        labelPlacement="end"
      />
      <Collapse in={enablePassword} appear={false} mountOnEnter unmountOnExit>
        <Alert severity="info" className={classes.alert}>
          Adding a password will sign you out on all devices, including this
          one.
          {socialProvider ? (
            <>
              {' '}
              Additionally, you will not be able to sign in using{' '}
              {toTitleCase(socialProvider)}.
            </>
          ) : null}
        </Alert>
        <Grid container spacing={2} className={classes.passwords}>
          <Grid item xs={12}>
            <PasswordField
              id="password"
              size="medium"
              label="Password"
              variant="outlined"
              value={password}
              error={!!passwordError || (!!password && passwordScore <= 2)}
              helperText={passwordError}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              fullWidth
              autoComplete="new-password"
            />
            <StrengthIndicator
              score={passwordScore}
              warning={passwordWarning}
              suggestions={passwordSuggestions}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              id="password-again"
              size="medium"
              label="Password again"
              variant="outlined"
              value={retypePassword}
              error={!!retypePasswordError}
              helperText={retypePasswordError}
              onChange={(e) => {
                setRetypePassword(e.target.value);
              }}
              fullWidth
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
      </Collapse>
    </KeyValuePair>
  );
}
