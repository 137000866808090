import React from 'react';

import { SignInPageContainer } from 'components/sign_in_page_container';
import { SetupFlow } from './flow';

export function MFASetupFlowPage({ me }) {
  return (
    <SignInPageContainer>
      <SetupFlow me={me} />
    </SignInPageContainer>
  );
}
