import axios from 'axios';
import {
  MAX_LARGE_FILE_UPLOAD_SIZE,
  MAX_SMALL_FILE_UPLOAD_SIZE,
} from 'services/file/constants';

export function uploadSmallFile(file, uploadUrl) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return axios.put(uploadUrl, formData, config);
}

const uploadLargeFile = (file, uploadUrl) => {
  const formData = new FormData();
  formData.append('file', file);

  function generateSignedUrl() {
    return axios.post(uploadUrl, { name: file.name });
  }

  function uploadToGoogle({ data }) {
    return axios
      .put(data.url, formData, {
        withCredentials: false,
        headers: {
          'X-Goog-Content-Length-Range': `0,${MAX_LARGE_FILE_UPLOAD_SIZE}`,
          'content-type': 'multipart/form-data',
          'x-csrftoken': null,
        },
      })
      .then(() => axios.get(uploadUrl, { params: { file_id: data.file_id } }));
  }

  return generateSignedUrl().then(uploadToGoogle);
};

export const uploadFile = (file, url) => {
  if (file.size > MAX_SMALL_FILE_UPLOAD_SIZE) {
    return uploadLargeFile(file, `${url}large/`);
  }
  return uploadSmallFile(file, url);
};

export function uploadFiles(acceptedFiles, uploadSingleFile, url) {
  const requests = [];

  acceptedFiles.forEach((acceptedFile) => {
    const request = uploadSingleFile(acceptedFile, url);
    requests.push(request);
  });
  return axios
    .all(requests)
    .then(
      axios.spread((...responses) => [
        ...responses.map((response) => ({
          fileId: response.data.id,
          fileUrl: response.data.file,
          fileName: response.data.name,
        })),
      ]),
    )
    .catch((error) => {
      if (error.response && error.response.data.file) {
        const errorMsg = error.response.data.file;
        throw new Error(errorMsg);
      }
      throw error;
    });
}
