import React from 'react';

import { SettingsGroup, Setting } from 'components/settings_group';

import { BooleanValue } from 'components/boolean_value';

export function CustomWorkflowsSettings({
  onEdit,
  readOnly,
  optOutOfCustomApproval = true,
}) {
  return (
    <SettingsGroup
      header="Custom workflows"
      onEdit={onEdit}
      readOnly={readOnly}
      internal
    >
      <Setting
        label="Apply custom workflows"
        description="Apply custom approval workflows to investors in this closing"
      >
        <BooleanValue
          label={!optOutOfCustomApproval ? 'Enabled' : 'Disabled'}
          bool={!optOutOfCustomApproval}
        />
      </Setting>
    </SettingsGroup>
  );
}
