import React from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  group: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  op: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export function QuestionSelector({
  questionId,
  updateAnswerTo,
  questions,
  addRow,
  deleteRow,
  handleClickItem,
  op,
}) {
  const classes = useStyles();
  const question = questions.find((q) => q.id === questionId);
  return (
    <div className={classes.row}>
      {op ? <Typography className={classes.op}>{op}</Typography> : null}
      {question && handleClickItem ? (
        <IconButton
          aria-label="find"
          onClick={() => {
            handleClickItem(question);
          }}
        >
          <FindInPageIcon />
        </IconButton>
      ) : null}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="answer-from">Answer from</InputLabel>
        <Select
          label="Answer from"
          labelId="answer-from"
          value={questionId}
          onChange={updateAnswerTo}
        >
          {questions.map((q) =>
            q.isSection ? (
              <ListSubheader key={q.sectionName}>{q.sectionName}</ListSubheader>
            ) : (
              <MenuItem key={q.id} value={q.id}>
                {q.id}
                {': '}
                {q?.question?.substring(0, 50)}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
      {addRow ? (
        <IconButton aria-label="add" onClick={addRow}>
          <AddIcon />
        </IconButton>
      ) : null}
      {deleteRow ? (
        <IconButton aria-label="delete" onClick={deleteRow}>
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
}
