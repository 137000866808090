import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Tab from '@material-ui/core/Tab';

import { UnreadBadge } from 'components/unread_badge';

import { matchStatuses } from '../constants';

const useIconStyles = makeStyles((theme) => ({
  upperSpacing: {
    marginTop: '2px',
  },
  greenIcon: {
    color: theme.palette.primary.main,
  },
  greyIcon: {
    color: theme.palette.primary.grey,
  },
  warningIcon: {
    fill: theme.palette.error.main,
  },
}));

function PotentialMatchIcon({ matchStatus, className }) {
  const classes = useIconStyles();

  if (matchStatus === matchStatuses.TRUE_MATCH) {
    return (
      <NotificationsIcon
        className={clsx(classes.warningIcon, classes.upperSpacing, className)}
      />
    );
  }

  if (matchStatus === matchStatuses.NOT_SURE) {
    return (
      <NotificationsIcon
        className={clsx(classes.greyIcon, classes.upperSpacing, className)}
      />
    );
  }

  return null;
}

const useTrueMatchTabStyle = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selected: {
    '&$selected': {
      color: theme.palette.error.text,
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.background,
    },
    backgroundColor: theme.palette.success.background,
  },
}));

const useNoMatchTabStyle = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.grey,
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.background,
    },
    backgroundColor: theme.palette.success.background,
  },
}));

const useUnevaluatedTabStyle = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  selected: {
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.background,
    },
    backgroundColor: theme.palette.success.background,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1, 1, 2),
    fontWeight: '400',
    maxWidth: 'none',
    opacity: 1,
  },
  tabLabel: {
    fontSize: '1rem',
    display: 'flex',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
  },
  iconRightSpace: {
    marginRight: theme.spacing(1),
  },
  nameContainer: {
    textAlign: 'left',
    width: '100%',
  },
  notAMatch: {
    textDecoration: 'line-through',
  },
  isNotAMatch: {
    color: theme.palette.primary.grey,
  },
  isAMatch: {
    color: theme.palette.error.text,
  },
  isNotSure: {
    color: theme.palette.primary.grey,
  },
  unreadBadge: {
    right: '0',
  },
}));

export function PotentialMatchTab({
  name,
  value,
  matchStatus,
  isMatchRead,
  ...props
}) {
  const classes = useStyles();

  const noMatchStyles = useNoMatchTabStyle();
  const trueMatchStyles = useTrueMatchTabStyle();
  const unevaluatedStyles = useUnevaluatedTabStyle();

  const isUnevaluated = matchStatus === matchStatuses.NO_CHOICE;
  const isNotAMatch = matchStatus === matchStatuses.NOT_TRUE_MATCH;
  const isAMatch = matchStatus === matchStatuses.TRUE_MATCH;
  const isNotSure = matchStatus === matchStatuses.NOT_SURE;

  function getTabStyles() {
    if (isAMatch) {
      return trueMatchStyles;
    }

    if (isUnevaluated) {
      return unevaluatedStyles;
    }

    return noMatchStyles;
  }

  return (
    <Tab
      value={value}
      disableRipple
      classes={getTabStyles()}
      className={clsx(classes.root, {
        [classes.isAMatch]: isAMatch,
        [classes.isNotSure]: isNotSure,
        [classes.isNotAMatch]: isNotAMatch,
      })}
      label={
        <div className={classes.tabLabel}>
          <PotentialMatchIcon
            matchStatus={matchStatus}
            className={classes.iconRightSpace}
          />

          <div
            className={clsx(classes.nameContainer, {
              [classes.notAMatch]: isNotAMatch,
            })}
          >
            {name}
          </div>

          {!isMatchRead ? (
            <UnreadBadge className={classes.unreadBadge} />
          ) : null}
        </div>
      }
      {...props}
    />
  );
}
