import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons, Link, Typography } from '@passthrough/uikit';
import { PasswordField } from 'components/password_field';

import { EmailChip } from './email_chip';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    backgroundColor: 'white',
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

export function PasswordForm({
  email,
  onSwitch,
  onSubmit,
  password,
  setPassword,
  passwordError,
  loading,
  onForgotPassword,
}) {
  const classes = useStyles();
  const location = useLocation();

  function handleForgotPassword(e) {
    e.preventDefault();
    onForgotPassword();
  }

  return (
    <form className={classes.form} onSubmit={(e) => onSubmit(e)} noValidate>
      <div className={classes.top}>
        <EmailChip email={email} onClick={onSwitch} />
        <PasswordField
          InputProps={{
            className: classes.input,
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          id="password"
          label="Password"
          name="password"
          autoFocus
          error={!!passwordError}
          helperText={passwordError}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
      </div>
      {/* Have to read from window because we aren't in the feature flag
      context here */}
      {window.ENABLE_PASSWORD_RESET && onForgotPassword ? (
        <Link
          href={location.pathname}
          variant="internal"
          onClick={handleForgotPassword}
        >
          <Typography size="small">Forgot your password?</Typography>
        </Link>
      ) : null}
      <div className={classes.submit}>
        <Button
          type="submit"
          fullWidth
          variant="primary"
          endIcon={<Icons.ArrowForward />}
          loading={loading}
          htmlProps={{ style: { maxWidth: '100%' } }}
        >
          Next
        </Button>
      </div>
    </form>
  );
}
