import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Collapse from '@material-ui/core/Collapse';
import Slide from '@material-ui/core/Slide';
import CreationIcon from 'mdi-material-ui/Creation';

import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { useToast } from 'services/toast';
import { useFundEdit } from 'services/providers/fund';
import { TabLabelWithNumChip } from 'pages/review_v2/tab_label_with_num_chip';
import {
  parseRelevantProps,
  filterEmptyDataFields,
  getDocumentFields,
  getOwnerFields,
  getDetailKeysForAdminEditing,
  buildInitialForm,
  annotateOwnersWithTypes,
} from 'components/lp_doc/diligence/utils';
import { getQuestionsAsNodes } from 'components/lp_doc/diligence/tree_nav_v2/node';
import { InternalChip, Typography } from '@passthrough/uikit';
import { MatchesDisplay } from './matches_display/index';
import { CommentsAndActivityDisplay } from './comments_and_activity_display/index';
import { AISummaryDisplay } from './ai_summary/index';
import { DiligenceReviewInstructions } from './diligence_review_instructions/index';
import { DocumentViewer } from './document_viewer/index';
import { OwnerDataApproval } from './owner_data_approval';
import {
  reducerActionTypes,
  GP_CAN_EDIT_STATUSES,
  POST_AUTOMATED_SEARCH_STATUSES,
} from './constants';

import { EditDiligenceDocsModal } from './edit_modals/docs_modal';
import { EditSupplementaryDiligenceDocsModal } from './edit_modals/extra_docs_modal';
import { EditDiligenceDetailsModal } from './edit_modals/details_modal';
import { EditDiligenceOwnersModal } from './edit_modals/owners_modal';

const useTabStyle = makeStyles(() => ({
  root: {
    fontWeight: '400',
  },
  selected: {
    '&$selected': {
      fontWeight: '500',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
  leftSide: {
    flex: 1,
    overflowY: 'auto',
    maxWidth: '480px',
    minWidth: '360px',
  },
  rightSide: {
    flex: 3,
    display: 'flex',
    position: 'relative',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  actionsContainer: {
    overflowY: 'auto',
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  docViewSlider: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    flex: '0 0 auto',
  },
  tabsTopBorder: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  tabStyling: {
    margin: theme.spacing(1, 0),
    fontSize: '1rem',
  },
  alertBottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  isHidden: {
    display: 'none',
  },
  dataApprovalSpacing: {
    margin: theme.spacing(0, 2),
  },
  instructionsContainer: {
    flex: '0 1 auto',
  },
  activityTabLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  aiLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const MATCHES_TAB_VALUE = 0;
const COMMENTS_AND_ACTIVITY_TAB_VALUE = 1;
const AI_SUMMARY_TAB_VALUE = 2;

function getAllDiligenceAnswersForEditing(answerData, jurisdiction) {
  // As answerData will not include data on fields not provided due to opt out
  // or waived diligence, we must determine all required detail & doc keys
  // and correctly initialize default values for any missing fields
  return buildInitialForm(answerData, jurisdiction);
}

const isMatchNotEvaluated = (match) => match.matchStatus === 'NO_CHOICE';

const calculateNumUnevaluatedMatches = (potentialMatches) => {
  if (!potentialMatches) {
    return 0;
  }

  const unevaluatedMatches = potentialMatches.filter((matchData) =>
    isMatchNotEvaluated(matchData),
  );
  return unevaluatedMatches.length;
};

export function DiligenceReviewArea({
  fundName,
  allQuestions,
  nodeData,
  dispatch,
  dataIsOutOfSync,
  hasInProgressAnswerChange,
  isLoading,
  docCollectionMode,
  managedDiligenceEnabled,
  jurisdiction,
  answerData,
  numCommentEvents,
  reusedNodeIds,
  hasSearch,
  isDiligenceApprover,
  currOwnerIndex,
  numOwners,
  closingStatus,
  shouldNotDisplayInstructions,
  setShouldNotDisplayInstructions,
  hideInstructionsUserSetting,
  setHideInstructionsUserSetting,
  onLeftNavClick,
  onRightNavClick,
  setLocalNumApproved,
  onGpAnswerSubmission,
  selectedQuestionId,
  lastSubmittedAnswer,
  updated,
  refreshDiligenceData,
}) {
  const classes = useStyles();
  const { fundId, closingId, lpClosingId } = useParams();
  const [me] = useMe();
  const tabStyles = useTabStyle();
  const { toast } = useToast();
  const [fundEdit] = useFundEdit();

  const initialActionsTab = docCollectionMode
    ? COMMENTS_AND_ACTIVITY_TAB_VALUE
    : MATCHES_TAB_VALUE;
  const [actionsTab, setActionsTab] = useState(initialActionsTab);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [documentData, setDocumentData] = useState({});

  const [showDocsEditModal, setShowDocsEditModal] = useState(false);
  const [showExtraDocsEditModal, setShowExtraDocsEditModal] = useState(false);
  const [showDetailsEditModal, setShowDetailsEditModal] = useState(false);
  const [showOwnersEditModal, setShowOwnersEditModal] = useState(false);

  const handleOpenDocument = (docData) => {
    setShowDocumentViewer(true);
    setDocumentData(docData);
  };

  const structuredNodesRoot = getQuestionsAsNodes(
    allQuestions?.[0],
    allQuestions,
    jurisdiction,
  );
  const numUnevaluatedPotentialMatches = calculateNumUnevaluatedMatches(
    nodeData?.potentialMatches,
  );
  const allPotentialMatchesEvaluated =
    docCollectionMode || numUnevaluatedPotentialMatches === 0;

  const atLeastOneTrueMatch = docCollectionMode
    ? false
    : nodeData?.potentialMatches?.some(
        (match) => match.matchStatus === 'TRUE_MATCH',
      );
  const atLeastOneUnsureMatch = docCollectionMode
    ? false
    : nodeData?.potentialMatches?.some(
        (match) => match.matchStatus === 'NOT_SURE',
      );

  const hideEditButtons =
    !fundEdit || !GP_CAN_EDIT_STATUSES.includes(closingStatus);

  const nodeDataSettled = nodeData.questionId === selectedQuestionId;
  const isRootNode = nodeDataSettled && nodeData.questionId === 'QD1';

  const allAnswers = getAllDiligenceAnswersForEditing(answerData, jurisdiction);

  const detailFieldKeys = getDetailKeysForAdminEditing(
    answerData?.type,
    jurisdiction,
    isRootNode,
  );
  const detailAnswers = detailFieldKeys.reduce((acc, detailField) => {
    acc[detailField] = allAnswers[detailField];
    return acc;
  }, {});

  const docFieldConfigData = getDocumentFields(
    answerData?.type,
    jurisdiction,
    isRootNode,
  );
  const documentAnswers = docFieldConfigData.reduce((acc, docField) => {
    acc[docField.key] = allAnswers[docField.key];
    return acc;
  }, {});

  const ownerFieldConfigData = getOwnerFields(answerData?.type, jurisdiction);
  const ownerAnswers = ownerFieldConfigData.reduce((acc, ownerField) => {
    acc[ownerField.key] = allAnswers[ownerField.key];
    return acc;
  }, {});

  useEffect(() => {
    if (nodeData.questionId !== selectedQuestionId) {
      return;
    }
    setShowDocumentViewer(false);
    setDocumentData({});

    dispatch({ type: reducerActionTypes.REFRESH_ACTIVITY_EVENTS });

    setActionsTab(initialActionsTab);
  }, [nodeData.questionId, selectedQuestionId]);

  return (
    <div className={classes.root}>
      <EditDiligenceOwnersModal
        key={`${nodeData.questionId}-edit-owners`}
        jurisdiction={jurisdiction}
        initialOwnerAnswers={ownerAnswers}
        structuredNodesRoot={structuredNodesRoot}
        ownerFieldData={ownerFieldConfigData}
        open={showOwnersEditModal}
        diligenceQuestions={annotateOwnersWithTypes(allQuestions)}
        questionId={nodeData.questionId}
        onClose={() => {
          setShowOwnersEditModal(false);
        }}
        onSubmit={(updatedOwnerAnswers, changed) => {
          const newAnswer = { ...answerData, ...updatedOwnerAnswers };
          const cleanedNewAnswer = parseRelevantProps(
            newAnswer,
            isRootNode,
            jurisdiction,
          );

          return api
            .gpEditNodeDiligence({
              fundId,
              lpClosingId,
              label: nodeData.questionId,
              answer: cleanedNewAnswer,
            })
            .then(() => {
              setShowOwnersEditModal(false);
              toast('Changes saved');

              if (changed) {
                onGpAnswerSubmission();
              } else {
                refreshDiligenceData();
                dispatch({
                  type: reducerActionTypes.EDIT_NODE_DETAILS,
                  newAnswer: cleanedNewAnswer,
                });
              }
            });
        }}
      />

      <EditSupplementaryDiligenceDocsModal
        initialSupplementaryDocs={nodeData?.supplementaryFiles || []}
        key={`${nodeData.questionId}-edit-supplementary-docs`}
        open={showExtraDocsEditModal}
        onClose={() => {
          setShowExtraDocsEditModal(false);
        }}
        onSubmit={(updatedFiles) => {
          const fileIds = updatedFiles.map((file) => file.fileId);

          return api
            .gpAddExtraDiligenceFiles({
              fundId,
              lpClosingId,
              label: nodeData.questionId,
              updatedFileIds: fileIds,
            })
            .then(() => {
              toast('Updated additional documents');

              dispatch({ type: reducerActionTypes.REFRESH_ACTIVITY_EVENTS });
              dispatch({
                type: reducerActionTypes.REFRESH_SUPPLEMENTARY_FILES,
                newSupplementaryFiles: updatedFiles,
              });
            });
        }}
      />

      <EditDiligenceDocsModal
        investorName={answerData?.name}
        key={`${nodeData.questionId}-edit-docs`}
        open={showDocsEditModal}
        initialDocumentAnswers={documentAnswers}
        docInputData={docFieldConfigData}
        jurisdiction={jurisdiction}
        onClose={() => {
          setShowDocsEditModal(false);
        }}
        onSubmit={(updatedFileData) => {
          // once altered file data has overwritten any saved file data, we can
          // then filter out empty file keys so that serializers don't validate them
          const rawNewAnswer = { ...answerData, ...updatedFileData };
          const cleanedNewAnswer = filterEmptyDataFields(rawNewAnswer);

          return api
            .gpEditNodeDiligence({
              fundId,
              lpClosingId,
              label: nodeData.questionId,
              answer: cleanedNewAnswer,
            })
            .then(() => {
              setShowDocsEditModal(false);
              toast('Changes saved');

              refreshDiligenceData();
              dispatch({
                type: reducerActionTypes.EDIT_NODE_DETAILS,
                newAnswer: cleanedNewAnswer,
              });
            });
        }}
      />

      <EditDiligenceDetailsModal
        key={`${nodeData.questionId}-edit-details`}
        label={nodeData.questionId}
        open={showDetailsEditModal}
        structuredNodesRoot={structuredNodesRoot}
        jurisdiction={jurisdiction}
        initialAnswers={detailAnswers}
        isRootNode={isRootNode}
        hasMatches={
          docCollectionMode ? false : nodeData?.potentialMatches?.length > 0
        }
        onClose={() => {
          setShowDetailsEditModal(false);
        }}
        onValidateData={(updatedDetailAnswers) => {
          // combine updated details data with preexisting answers
          // so that we always submit owner keys which are required
          // even if no owners were provided
          const rawNewAnswer = { ...answerData, ...updatedDetailAnswers };
          const cleanedNewAnswer = filterEmptyDataFields(rawNewAnswer);

          return api.adminSideDiligenceDataValidation({
            fundId,
            closingId,
            lpClosingId,
            questionToValidate: nodeData.questionId,
            newAnswer: cleanedNewAnswer,
          });
        }}
        onSubmit={(
          updatedDetailAnswers,
          wasTypeChanged,
          changesWillTriggerSearch,
        ) => {
          let newAnswer = { ...answerData, ...updatedDetailAnswers };
          if (wasTypeChanged) {
            newAnswer = updatedDetailAnswers;
          }

          // to ensure that we only submit nonempty data
          // and that we only submit ids for file fields
          const cleanedNewAnswer = parseRelevantProps(
            newAnswer,
            isRootNode,
            jurisdiction,
          );

          return api
            .gpEditNodeDiligence({
              fundId,
              lpClosingId,
              label: nodeData.questionId,
              answer: cleanedNewAnswer,
            })
            .then(() => {
              setShowDetailsEditModal(false);

              if (changesWillTriggerSearch) {
                onGpAnswerSubmission();
              } else {
                refreshDiligenceData();

                dispatch({
                  type: reducerActionTypes.EDIT_NODE_DETAILS,
                  newAnswer: cleanedNewAnswer,
                });
                toast('Changes saved');
              }
            });
        }}
      />

      <div className={classes.leftSide}>
        <OwnerDataApproval
          fundName={fundName}
          jurisdiction={jurisdiction}
          hasSearch={hasSearch}
          dataIsOutOfSync={dataIsOutOfSync}
          supplementaryFiles={nodeData?.supplementaryFiles || []}
          hasInProgressAnswerChange={hasInProgressAnswerChange}
          questionId={nodeData.questionId}
          answerData={answerData}
          numCommentEvents={numCommentEvents}
          isApproved={nodeData.isApproved}
          note={nodeData.draftComment}
          commentThreadsResolved={nodeData.numUnresolvedThreads === 0}
          hasUnresolvedInternalNote={nodeData.hasUnresolvedInternalNote}
          isDiligenceApprover={isDiligenceApprover}
          allPotentialMatchesEvaluated={allPotentialMatchesEvaluated}
          atLeastOneTrueMatch={atLeastOneTrueMatch}
          atLeastOneUnsureMatch={atLeastOneUnsureMatch}
          currOwnerIndex={currOwnerIndex}
          numOwners={numOwners}
          closingStatus={closingStatus}
          onLeftNavClick={onLeftNavClick}
          onRightNavClick={onRightNavClick}
          handleOpenDocument={handleOpenDocument}
          nodeIsReused={reusedNodeIds?.length > 0}
          hideEditButtons={hideEditButtons}
          handleOpenDocsEditModal={() => {
            setShowDocsEditModal(true);
          }}
          handleOpenExtraDocsEditModal={() => {
            setShowExtraDocsEditModal(true);
          }}
          handleOpenDetailsEditModal={() => {
            setShowDetailsEditModal(true);
          }}
          handleOpenOwnersEditModal={() => {
            setShowOwnersEditModal(true);
          }}
          setLocalNumApproved={setLocalNumApproved}
          dispatch={dispatch}
          className={classes.dataApprovalSpacing}
          lastSubmittedAnswer={lastSubmittedAnswer}
          updated={updated}
          nodeDataSettled={nodeDataSettled}
          loadingUnresolvedThreads={nodeData.loadingUnresolvedThreads}
          docCollectionMode={docCollectionMode}
          adverseMediaEnabled={nodeData?.adverseMediaEnabled}
          handleEnableAdverseMedia={() => {
            api
              .enableAdverseMediaOnNode({
                fundId,
                lpClosingId,
                label: nodeData?.questionId,
              })
              .then(() => {
                onGpAnswerSubmission();
              });
          }}
          isRootNode={isRootNode}
        />
      </div>

      <div className={classes.rightSide}>
        <Slide
          in={showDocumentViewer}
          direction="left"
          timeout={500}
          mountOnEnter
          unmountOnExit
          className={classes.docViewSlider}
        >
          <div>
            <DocumentViewer
              documentData={documentData}
              setShowDocumentViewer={setShowDocumentViewer}
            />
          </div>
        </Slide>

        <div className={classes.actionsContainer}>
          <div className={classes.instructionsContainer}>
            <Collapse
              in={!shouldNotDisplayInstructions && !managedDiligenceEnabled}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <DiligenceReviewInstructions
                setShouldNotDisplayInstructions={
                  setShouldNotDisplayInstructions
                }
                hideInstructionsUserSetting={hideInstructionsUserSetting}
                setHideInstructionsUserSetting={setHideInstructionsUserSetting}
              />
            </Collapse>
          </div>

          <Tabs
            className={clsx(classes.tabs, {
              [classes.tabsTopBorder]: !shouldNotDisplayInstructions,
            })}
            value={actionsTab}
            onChange={(e, v) => {
              setActionsTab(v);
            }}
            aria-label="top-navigation-tabs"
          >
            {docCollectionMode ? null : (
              <Tab
                aria-label="Diligence matches"
                className={classes.tabStyling}
                classes={tabStyles}
                data-test="diligence_matches"
                label={
                  <TabLabelWithNumChip
                    label="Matches"
                    count={numUnevaluatedPotentialMatches}
                    isSelected={actionsTab === MATCHES_TAB_VALUE}
                  />
                }
                value={MATCHES_TAB_VALUE}
              />
            )}

            <Tab
              aria-label="Activity and comments"
              className={classes.tabStyling}
              data-test="diligence_activity"
              classes={tabStyles}
              label={
                <TabLabelWithNumChip
                  label="Activity and comments"
                  count={nodeData.numUnresolvedThreads}
                  isSelected={actionsTab === COMMENTS_AND_ACTIVITY_TAB_VALUE}
                />
              }
              value={COMMENTS_AND_ACTIVITY_TAB_VALUE}
            />

            {me.isStaff ? (
              <Tab
                aria-label="AI Summary"
                className={classes.tabStyling}
                classes={tabStyles}
                label={
                  <Typography className={classes.aiLabel}>
                    <CreationIcon />
                    AI summary
                    <InternalChip />
                  </Typography>
                }
                value={AI_SUMMARY_TAB_VALUE}
              />
            ) : null}
          </Tabs>

          {actionsTab === MATCHES_TAB_VALUE ? (
            <MatchesDisplay
              questionId={nodeData.questionId}
              dataIsOutOfSync={dataIsOutOfSync}
              hasSearch={hasSearch}
              isDiligenceApprover={isDiligenceApprover}
              postAutomatedSearch={POST_AUTOMATED_SEARCH_STATUSES.includes(
                closingStatus,
              )}
              onManualSearch={() =>
                api
                  .gpManualNodeSearch({
                    fundId,
                    lpClosingId,
                    label: nodeData.questionId,
                  })
                  .then(() => {
                    onGpAnswerSubmission();
                  })
              }
              isNodeLoading={isLoading}
              potentialMatches={
                docCollectionMode ? [] : nodeData.potentialMatches
              }
              queuedMatchId={nodeData.queuedMatchId}
              showMatchStatus={nodeData.showMatchStatus}
              selectedMatchId={nodeData.selectedMatchId}
              dispatch={dispatch}
              managedDiligenceEnabled={managedDiligenceEnabled}
            />
          ) : null}

          <CommentsAndActivityDisplay
            fundName={fundName}
            jurisdiction={jurisdiction}
            nodeName={answerData?.name || 'N/A'}
            structuredNodesRoot={structuredNodesRoot}
            refreshActivityEvents={nodeData.refreshActivityEvents}
            originalQuestionId={nodeData.questionId}
            reusedNodeIds={reusedNodeIds}
            dispatch={dispatch}
            draftComment={nodeData.draftComment}
            className={clsx({
              [classes.isHidden]:
                actionsTab !== COMMENTS_AND_ACTIVITY_TAB_VALUE,
            })}
          />

          {me.isStaff ? (
            <AISummaryDisplay
              key={nodeData.questionId}
              questionId={nodeData.questionId}
              className={clsx({
                [classes.isHidden]: actionsTab !== AI_SUMMARY_TAB_VALUE,
              })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
