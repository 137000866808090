import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'components/button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import PublicIcon from '@material-ui/icons/Public';
import BusinessIcon from '@material-ui/icons/Business';
import UndoIcon from 'mdi-material-ui/Undo';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckIcon from 'mdi-material-ui/Check';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { NoteHistoryDisplay } from './note_history';

import { SIGNERS, REVIEW_STATE } from './constants';
import { QueryBuilder } from './query_builder';
import { SignatureBoxInput } from './inputs/signature_box_input';
import { AdvancedSettings } from './inputs/advanced_settings';
import { InputDiv } from './common/input_div';
import { Paper } from './common/paper';

const useStyles = makeStyles((theme) => ({
  leftInput: {
    marginRight: theme.spacing(1),
  },
  restrictedThreadDisplay: {
    margin: 'auto',
  },
}));

export const EditSignature = ({
  id,
  isAsked,
  setIsAsked,
  hellosignBoxes,
  replaceHellosignBox,
  deleteHellosignBox,
  setDragSelected,
  setPage,
  handleAddHellosignBox,
  deps,
  setDeps,
  questions,
  hasCustomLogic,
  setHasCustomLogic,
  newNotes,
  onDragEnd,
  handleSave,
  hasUnsavedChanges,
  isValid,
  isSaving,
  highlightBox,
  setHighlightBoxId,
  handleClickItem,
  signer,
  setSigner,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  onboardingLogs,
  handleReview,
  currentState,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
  selectDocument,
  showAdvanced,
  toggleAdvanced,
}) => {
  const classes = useStyles();
  return (
    <DragDropContext onDragEnd={onDragEnd} key={id}>
      <Paper>
        <NoteHistoryDisplay
          notes={newNotes[id] || []}
          className={classes.restrictedThreadDisplay}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          questionID={id}
          currentLog={onboardingLogs[0]}
          handleReview={handleReview}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
          selectedIds={selectedIds}
        />
        <InputDiv>
          <TextField
            value={id}
            className={classes.leftInput}
            disabled
            variant="outlined"
            label="Signature ID"
          />

          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="signer">Signer</InputLabel>
            <Select
              label="Signer"
              labelId="signer"
              value={signer || ''}
              onChange={(e) => {
                setSigner(e.target.value);
              }}
            >
              {SIGNERS.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {currentState === REVIEW_STATE ? (
            <Tooltip title={<Typography>Mark as reviewed</Typography>}>
              <IconButton
                aria-label="reviewed"
                onClick={() => {
                  handleReview(!reviewedQuestionIds.includes(id));
                }}
              >
                {reviewedQuestionIds.includes(id) ? (
                  <UndoIcon />
                ) : (
                  <CheckIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : null}
        </InputDiv>

        <InputDiv>
          <ToggleButtonGroup
            value={isAsked}
            exclusive
            onChange={(e, v) => {
              if (v !== null) {
                setIsAsked(v);
              }
            }}
          >
            <ToggleButton value="always" aria-label="always ask">
              <Tooltip title={<Typography>Always ask</Typography>}>
                <PublicIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="natural_person" aria-label="natural person">
              <Tooltip title={<Typography>Only natural people</Typography>}>
                <PersonIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="entity" aria-label="entity">
              <Tooltip title={<Typography>Only entities</Typography>}>
                <BusinessIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </InputDiv>

        {hellosignBoxes
          .filter((box) => !box.choice)
          .map((box) => (
            <SignatureBoxInput
              key={box.id}
              box={box}
              onChange={replaceHellosignBox}
              onDelete={deleteHellosignBox}
              onSelectDrag={setDragSelected}
              setPage={setPage}
              signer={signer}
              highlight={
                highlightBox.id === box.id ? highlightBox.timestamp : null
              }
              setHighlightBoxId={setHighlightBoxId}
              selectDocument={selectDocument}
            />
          ))}

        <InputDiv>
          <Button
            startIcon={<SelectionDragIcon />}
            onClick={() =>
              handleAddHellosignBox({ type: `${signer}_signature` })
            }
          >
            Signature
          </Button>
        </InputDiv>

        <InputDiv>
          <QueryBuilder
            questionIds={[id]}
            value={deps}
            onChange={(d) => {
              setDeps(d);
            }}
            questions={questions}
            handleClickItem={handleClickItem}
            hideAddCondition={false}
            showThisAndFutureQuestions
          />
        </InputDiv>

        <AdvancedSettings
          hasCustomLogic={hasCustomLogic}
          setHasCustomLogic={setHasCustomLogic}
          disableInteroperableNote
          showAdvanced={showAdvanced}
          toggleAdvanced={toggleAdvanced}
        />

        <InputDiv>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid() || !hasUnsavedChanges}
            onClick={handleSave}
            fullWidth
            loading={isSaving}
          >
            Save
          </Button>
        </InputDiv>
      </Paper>
    </DragDropContext>
  );
};
