import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons, useConfirm } from '@passthrough/uikit';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { TreeItem } from './tree_item';
import { addNameDataToReuseQuestions } from '../utils';
import { addSlices, getQuestionsAsNodes } from './node';
import { useErrorContext } from '../error_context';

const useStyles = makeStyles((theme) => ({
  treeRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    alignSelf: 'center',
    marginRight: theme.spacing(7),
  },
  list: {
    paddingLeft: (isGPSide) => (isGPSide ? theme.spacing(0) : theme.spacing(5)),
    listStyle: 'none',
  },
}));

export function DiligenceTreeNav({
  nav,
  jurisdiction,
  diligenceQuestions,
  selectedQuestionId,
  showNavWarning,
  saving,
  isGPSide = false,
  hideBackButton = false,
  missingDiligenceDataErrors,
}) {
  const classes = useStyles(isGPSide);
  const confirm = useConfirm();
  const { useSupport } = useQuestionnaire();
  const { hasDraftComment } = useSupport();

  const formErrors = useErrorContext();
  const { DISCARD_ANSWER_MODAL_FIX } = useFeatureFlags();

  const onDiligenceClick = DISCARD_ANSWER_MODAL_FIX
    ? () => nav.toRegularNav()
    : () => {
        if (showNavWarning) {
          confirm({
            description:
              'You have not saved your changes. Are you sure you want to leave?',
            destructive: true,
          })
            .then(() => {
              nav.toRegularNav();
            })
            .catch(() => {});
        } else if (hasDraftComment) {
          confirm({
            description:
              'You have not saved your comment. Are you sure you want to leave?',
            destructive: true,
          })
            .then(() => {
              nav.toRegularNav();
            })
            .catch(() => {});
        } else {
          nav.toRegularNav();
        }
      };

  if (!diligenceQuestions) {
    return null;
  }

  const namedDiligenceQuestions =
    addNameDataToReuseQuestions(diligenceQuestions);
  const rootNode = getQuestionsAsNodes(
    namedDiligenceQuestions[0],
    namedDiligenceQuestions,
    jurisdiction,
  );
  if (!rootNode) {
    return null;
  }

  addSlices(rootNode);

  return (
    <div className={classes.treeRoot}>
      {hideBackButton ? null : (
        <div className={classes.backButton}>
          <Button
            startIcon={<Icons.KeyboardArrowLeft />}
            variant="text"
            onClick={onDiligenceClick}
          >
            Back
          </Button>
        </div>
      )}
      <ul className={classes.list}>
        {rootNode.getInPreOrder().map((child) => (
          <TreeItem
            key={child.question.label}
            jurisdiction={jurisdiction}
            node={child}
            diligenceQuestions={namedDiligenceQuestions}
            nav={nav}
            selectedQuestionId={selectedQuestionId}
            showNavWarning={showNavWarning}
            saving={saving}
            isGPSide={isGPSide}
            formErrors={{
              ...missingDiligenceDataErrors?.[child.question.label],
              ...formErrors?.[child.question.label],
            }}
          />
        ))}
      </ul>
    </div>
  );
}
