import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { DataValueDisplay } from 'components/data_value_display';
import { Button, Chip, Icons } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core';
import { getGroupedNodeTypes, getOwnerTypeDisplay } from '../utils';
import { GROUP_NONE } from '../constants';

const useStyles = makeStyles((theme) => ({
  legalName: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    // spread out the legal name and the edit button
    '& > *:first-child': {
      flex: 1,
    },
  },
}));

export function TypeInput({
  jurisdiction,
  type,
  typeError,
  onChange,
  dataTestLabelOverride = '',
}) {
  const groupedNodeTypes = getGroupedNodeTypes(jurisdiction);

  return (
    <FormControl variant="outlined" fullWidth error={!!typeError}>
      <InputLabel id="type-label">Type</InputLabel>

      <Select
        label="Type"
        labelId="type-label"
        data-test={dataTestLabelOverride || 'select_diligence_v2_type'}
        value={type}
        onChange={onChange}
      >
        {groupedNodeTypes.map((values, j) =>
          values.map((v, i) => {
            if (v?.isGroupName) {
              return v?.name === GROUP_NONE ? null : (
                <ListSubheader
                  key={v.name}
                  disableSticky
                  // see https://github.com/mui/material-ui/issues/18200
                  onClickCapture={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {v.name}
                </ListSubheader>
              );
            }

            return (
              <MenuItem
                value={v}
                key={v}
                data-test={v}
                divider={
                  i === values.length - 1 && j !== groupedNodeTypes.length - 1
                }
              >
                {getOwnerTypeDisplay(v)}
              </MenuItem>
            );
          }),
        )}
      </Select>
      {typeError ? <FormHelperText>{typeError}</FormHelperText> : null}
    </FormControl>
  );
}

export function NameAndTypeInputs({
  reuseDataChecked,
  jurisdiction,
  name,
  nameError,
  type,
  typeError,
  handleChange,
  changeType,
  legalName,
  preventLegalNameChange = false,
  toLegalName,
}) {
  const classes = useStyles();

  const disableTypeInput = preventLegalNameChange && !name;
  const hideTypeInput = reuseDataChecked || disableTypeInput;
  return (
    <>
      {preventLegalNameChange ? (
        <div className={classes.legalName}>
          <DataValueDisplay
            label="Full legal name"
            value={
              name ||
              legalName || (
                <Chip variant="neutral" size="small" label="Not provided yet" />
              )
            }
          />
          {toLegalName ? (
            <Button
              variant="icon"
              onClick={toLegalName}
              aria-label="Edit legal name"
            >
              <Icons.EditOutlined />
            </Button>
          ) : null}
        </div>
      ) : (
        <TextField
          id="name"
          autoFocus
          label="Full legal name"
          variant="outlined"
          value={name}
          error={!!nameError}
          helperText={nameError}
          data-test="diligence_v2_name_input"
          onChange={(e) => {
            handleChange('name', e.target.value);

            handleChange('idToReuse', null);
          }}
          fullWidth
        />
      )}
      {!hideTypeInput ? (
        <TypeInput
          jurisdiction={jurisdiction}
          type={type}
          typeError={typeError}
          onChange={changeType}
          disabled={preventLegalNameChange && !name}
        />
      ) : null}
    </>
  );
}
