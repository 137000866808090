import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Button, Icons } from '@passthrough/uikit';

const PASSWORD_UI_MAP = {
  [true]: {
    Icon: Icons.VisibilityOutlined,
    tooltip: 'Hide password',
    type: 'text',
  },
  [false]: {
    Icon: Icons.VisibilityOffOutlined,
    tooltip: 'Show password',
    type: 'password',
  },
};

export function PasswordField({
  autoComplete = 'password',
  InputProps = {},
  ...textFieldProps
}) {
  const [showPassword, setShowPassword] = useState(false);
  const { Icon, tooltip, type } = PASSWORD_UI_MAP[showPassword];

  return (
    <TextField
      {...textFieldProps}
      type={type}
      autoComplete={autoComplete}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="icon"
              size="small"
              aria-label={tooltip}
              onClick={() =>
                setShowPassword((prevShowPassword) => !prevShowPassword)
              }
            >
              <Icon color="action" />
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}
