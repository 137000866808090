import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { Typography, Alert, SpotIcon, Icons, Button } from '@passthrough/uikit';
import { MethodTable } from './method_table';
import * as constants from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const METHOD_TEXT_MAP = {
  [constants.TOTP_METHOD]: 'your authenticator app',
  [constants.SMS_METHOD]: 'text messages',
};

export function FinalStep({ method, userId }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { productName } = useWhiteLabelConfig();

  const nextUrl = location.pathname;
  const methodText = METHOD_TEXT_MAP[method];

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <SpotIcon size="large" variant="success">
          <Icons.VerifiedUserOutlined color="success" />
        </SpotIcon>
        <Typography variant="section-heading">
          Two-factor authentication (2FA) is now enabled for your {productName}{' '}
          account.
        </Typography>
      </div>
      <Alert severity="success">
        You have enabled two-factor authentication (2FA) using {methodText}.
      </Alert>
      <Typography>
        You can configure two-factor authentication methods in you account
        settings anytime.
      </Typography>
      <MethodTable
        totpEnabled={method === constants.TOTP_METHOD}
        smsEnabled={method === constants.SMS_METHOD}
      />
      <Button
        variant="primary"
        fullWidth
        onClick={() => history.push(`/signin/${userId}/?next=${nextUrl}`)}
      >
        Sign in again with 2FA
      </Button>
    </div>
  );
}
