import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, useConfirm } from '@passthrough/uikit';

import { Button } from 'components/button';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { StepperThreadIcon } from 'components/stepper_thread_icon';
import { UNSAVED } from 'components/lp_doc/saved';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { getTreeState } from './tree_utils';
import { TreeIcon } from './icon';
import { TreeSpacingList } from './tree_spacing_list';

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: 'flex',
    textAlign: 'left',
  },
  textGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
  sublabelText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.grey,
  },
  highlightedSublabel: {
    color: theme.palette.primary.white,
  },
  treeItem: {
    padding: theme.spacing(1, 2, 1, 1),
    margin: '4px 0',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'inherit',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  gpSideItem: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    cursor: 'default',
    userSelect: 'text',
  },
  textCursor: {
    cursor: 'text',
  },
  highlightedItem: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.primary.white,
  },
  sliceContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
  },
  selectedBadge: {
    '& .MuiBadge-dot': {
      backgroundColor: theme.palette.primary.white,
    },
  },
}));

export function TreeItem({
  node,
  jurisdiction,
  diligenceQuestions,
  selectedQuestionId,
  nav,
  showNavWarning,
  saving,
  isGPSide,
  formErrors,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const { useSupport } = useQuestionnaire();
  const { hasDraftComment } = useSupport();
  const { DISCARD_ANSWER_MODAL_FIX } = useFeatureFlags();

  const { question } = node;
  const { numComments } = question;
  const { answer } = question;
  const isRootNode = question.label === 'QD1';

  const questionIndex = diligenceQuestions.findIndex(
    (q) => q.label === question.label,
  );
  const treeState = getTreeState(
    question,
    isGPSide,
    jurisdiction,
    isRootNode,
    formErrors,
  );

  const goToTreeNode = () => {
    nav.toQuestion(questionIndex);
  };

  const onClick = () => {
    if (isGPSide) {
      return;
    }

    const changed = DISCARD_ANSWER_MODAL_FIX
      ? saving === UNSAVED
      : showNavWarning;

    const goingToDifferentQuestion = question.label !== selectedQuestionId;
    if (changed && goingToDifferentQuestion) {
      confirm({
        description:
          'You have not saved your changes. Are you sure you want to leave?',
        destructive: true,
      })
        .then(() => {
          goToTreeNode();
        })
        .catch(() => {});
    } else if (hasDraftComment) {
      confirm({
        description:
          'You have not saved your comment. Are you sure you want to leave?',
        destructive: true,
      })
        .then(() => {
          goToTreeNode();
        })
        .catch(() => {});
    } else {
      goToTreeNode();
    }
  };

  const labelText = `${answer.name}`;
  const isSelected = selectedQuestionId === question.label;
  const labelIcon = <TreeIcon treeState={treeState} isSelected={isSelected} />;

  return (
    <li className={classes.sliceContainer}>
      <TreeSpacingList treeSlices={node.treeSlices} />
      <Button
        variant="text"
        disableRipple={isGPSide}
        className={clsx(classes.treeItem, {
          [classes.gpSideItem]: isGPSide,
          [classes.highlightedItem]: isSelected,
        })}
        onClick={onClick}
      >
        <div className={classes.labelRoot}>
          {labelIcon}

          <div
            className={clsx(classes.textGroup, {
              [classes.textCursor]: isGPSide,
            })}
          >
            <Typography variant="label" size="medium">
              {labelText}
            </Typography>

            <Typography
              variant="label"
              size="small"
              color={isSelected ? 'inherit' : 'text.secondary'}
            >
              {node.childLabel}
            </Typography>
          </div>

          {numComments > 0 ? (
            <StepperThreadIcon
              isActiveThread={question.needsConfirmation}
              isSelected={isSelected}
              IsDiligenceQuestion
            />
          ) : null}
        </div>
      </Button>
    </li>
  );
}
