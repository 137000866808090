import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Button } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6, 4),
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
}));

export function CTAForMFA({ goToMFASetup }) {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.paper}>
      <Typography variant="card-heading">
        Two-factor authentication not enabled
      </Typography>
      <Typography variant="body" size="small">
        Two-factor authentication adds an extra layer of security to your
        account by requiring more than just a password when you sign in.
      </Typography>
      <Button variant="primary" onClick={goToMFASetup}>
        Enable 2FA
      </Button>
    </Paper>
  );
}
