import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Button } from '@passthrough/uikit';
import { PasswordField } from 'components/password_field';
import { DebouncedPasswordField } from 'components/debounced_password_field';
import { StrengthIndicator } from 'components/strength_indicator';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  passwords: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export function PasswordStep({
  password,
  passwordError,
  setPassword,
  retypePassword,
  retypePasswordError,
  setRetypePassword,
  passwordScore,
  passwordWarning,
  passwordSuggestions,
  onContinue,
  loading,
}) {
  const classes = useStyles();
  const computedRetypePasswordError =
    retypePasswordError && password !== retypePassword
      ? retypePasswordError
      : '';

  function handleSubmit(e) {
    e.preventDefault();
    onContinue();
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <div>
        <Typography variant="card-heading">Set up a password</Typography>
        <Typography>
          First, you will set up a new password for your account.
        </Typography>
      </div>
      <div className={classes.passwords}>
        <div>
          <DebouncedPasswordField
            id="password"
            size="medium"
            label="Password"
            variant="outlined"
            error={!!passwordError || (!!password && passwordScore <= 2)}
            helperText={passwordError}
            fullWidth
            autoComplete="new-password"
            autoFocus
            setDebouncedPassword={setPassword}
            // We need the initial value for when we're going back to this step
            // from the method select step
            initialValue={password}
          />
          <StrengthIndicator
            score={passwordScore}
            warning={passwordWarning}
            suggestions={passwordSuggestions}
          />
        </div>
        <PasswordField
          id="password-again"
          label="Password again"
          variant="outlined"
          value={retypePassword}
          error={!!computedRetypePasswordError}
          helperText={computedRetypePasswordError}
          onChange={(e) => {
            setRetypePassword(e.target.value);
          }}
          fullWidth
          autoComplete="new-password"
        />
      </div>
      <Button
        loading={loading}
        fullWidth
        variant="primary"
        onClick={handleSubmit}
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
}
