import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { Button, Icons } from '@passthrough/uikit';
import { SetupFlow } from 'pages/mfa_setup_flow/flow';
import { useMe } from 'services/providers/me';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(4, 6),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 1),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 4, 8, 4),
  },
  hiddenSpacingButton: {
    visibility: 'hidden',
  },
  backButton: {
    color: `${theme.palette.primary.main} !important`,
  },
}));

export function MFASetupPage({ onFinished, showBackButton, onBack }) {
  const classes = useStyles();
  const [me] = useMe();

  return (
    <div className={classes.root}>
      {showBackButton ? (
        <div>
          <Button
            variant="text"
            startIcon={<Icons.KeyboardArrowLeft />}
            onClick={onBack}
            className={classes.backButton}
          >
            Back
          </Button>
        </div>
      ) : null}
      <Container component="main" maxWidth="md">
        <Paper className={classes.paper} variant="outlined">
          <SetupFlow me={me} skipInitialStep onFinished={onFinished} />
        </Paper>
      </Container>
      {showBackButton ? (
        <div className={classes.hiddenSpacingButton}>
          <Button
            variant="text"
            startIcon={<Icons.KeyboardArrowLeft />}
            onClick={() => {}}
          >
            Back
          </Button>
        </div>
      ) : null}
    </div>
  );
}
