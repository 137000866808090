import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import { BooleanValue } from 'components/boolean_value';
import { SettingsGroup, Setting } from 'components/settings_group';
import {
  SCREENING_CATEGORIES,
  DEFAULT_SCREENING_CATEGORIES,
} from 'services/constants';

const useStyles = makeStyles((theme) => ({
  col: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const Category = ({ label, enabled }) => (
  <BooleanValue label={label} bool={enabled} />
);

const ScreeningCategoriesSetting = ({ enableAdverseMediaOnAllNodes }) => {
  const classes = useStyles();
  const defaultCategories = Object.values(DEFAULT_SCREENING_CATEGORIES);
  const screeningCategoriesDescription = (
    <Typography variant="body" size="small" color="text.secondary">
      Enable categories that investors and their owners are screened against.
    </Typography>
  );

  return (
    <Setting
      label="Screening categories"
      description={screeningCategoriesDescription}
    >
      <div className={classes.col}>
        {defaultCategories.map((category) => (
          <Category key={category} label={category} enabled />
        ))}

        <Category
          label={SCREENING_CATEGORIES.ADVERSE_MEDIA}
          enabled={enableAdverseMediaOnAllNodes}
        />
      </div>
    </Setting>
  );
};

export function DiligenceSettings({ enableAdverseMediaOnAllNodes, onEdit }) {
  return (
    <SettingsGroup internal header="Diligence" onEdit={onEdit}>
      <ScreeningCategoriesSetting
        enableAdverseMediaOnAllNodes={enableAdverseMediaOnAllNodes}
      />
    </SettingsGroup>
  );
}
