import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography, Button } from '@passthrough/uikit';
import * as constants from 'pages/mfa_setup_flow/constants';
import * as api from 'services/api';
import { OTPInput } from 'components/otp_input';
import {
  ResendVerificationButton,
  useSmsOtpTimer,
} from 'components/resend_verification_button';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
}));

export function VerifySecondFactor({ mfaMethod, onVerify, onError }) {
  const [otpCode, setOtpCode] = useState('');
  const [otpCodeError, setOtpCodeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const { timeoutText, allowSend, onSend } = useSmsOtpTimer({
    onEnd: () => setResendDisabled(false),
  });

  const classes = useStyles();

  const { title, description, Icon, fieldLabel } =
    constants.getUIInfoFromMethod(mfaMethod);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setOtpCodeError('');

    api
      .verifyMFAForPasswordReset({ otpCode })
      .then(() => {
        onVerify();
      })
      .catch((error) => {
        if (error.response?.data?.otpCode) {
          setOtpCodeError(error.response.data.otpCode);
          setLoading(false);
        } else {
          onError();
        }
      });
  }

  function resendVerificationCode(useLoading = true) {
    if (!allowSend) return;
    if (useLoading) setResendLoading(true);
    setResendDisabled(true);

    api
      .resendVerificationForPasswordReset()
      .then(() => {
        onSend();
        setResendLoading(false);
      })
      .catch(() => {
        onError();
      });
  }

  useEffect(() => {
    if (mfaMethod === constants.SMS_METHOD) {
      resendVerificationCode(false);
    }
  }, []);

  return (
    <>
      <Icon color="action" />
      <div>
        <Typography variant="card-heading">{title}</Typography>
        <Typography variant="body" size="small" color="text.secondary">
          {description}
        </Typography>
      </div>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          InputProps={{
            inputComponent: OTPInput,
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          id="otpCode"
          label={fieldLabel}
          name="otpCode"
          autoComplete="one-time-code"
          autoFocus
          error={!!otpCodeError}
          helperText={otpCodeError}
          value={otpCode}
          onChange={(e) => setOtpCode(e.target.value)}
          disabled={loading}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ inputMode: 'numeric' }}
        />
        <Button
          variant="primary"
          fullWidth
          onClick={handleSubmit}
          loading={loading}
        >
          Verify
        </Button>

        {mfaMethod === constants.SMS_METHOD ? (
          <ResendVerificationButton
            fullWidth
            variant="secondary"
            disabled={loading || resendDisabled}
            loading={resendLoading}
            onClick={resendVerificationCode}
            timeoutText={timeoutText}
            allowSend={allowSend}
          >
            Resend text message
          </ResendVerificationButton>
        ) : null}
      </form>
    </>
  );
}
