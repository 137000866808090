import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Alert, Modal, Typography } from '@passthrough/uikit';

import { KeyValuePair } from 'components/key_value';
import * as api from 'services/api';
import * as urls from 'services/urls';
import * as constants from 'pages/mfa_setup_flow/constants';
import { OTPInput } from 'components/otp_input';
import {
  ResendVerificationButton,
  useSmsOtpTimer,
} from 'components/resend_verification_button';
import { useToast } from 'services/toast';

import { getSingleErrorFromResponse } from 'services/utils';

export function DisableMFAModal({ open, onClose, mfaMethod, userId }) {
  const [otpCode, setOtpCode] = useState('');
  const [otpCodeError, setOtpCodeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendError, setResendError] = useState('');
  const history = useHistory();
  const { successToast } = useToast();
  const { timeoutText, allowSend, onSend } = useSmsOtpTimer({
    onEnd: () => setResendDisabled(false),
  });

  function handleSubmit() {
    setOtpCodeError('');
    setLoading(true);

    api
      .disableMFA({ otpCode })
      .then(() => {
        history.push(urls.signinUrl({ userId }));
        successToast('Settings updated.');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data?.otpCode) {
          setOtpCodeError(error.response.data.otpCode);
        } else if (error.response?.status === 429) {
          setOtpCodeError(
            'Too many attempts. Please wait a few minutes before trying again.',
          );
        } else {
          setOtpCodeError(getSingleErrorFromResponse(error.response));
        }
      });
  }

  function handleResend(useLoading = true) {
    if (useLoading) setResendLoading(true);
    setResendDisabled(true);

    api
      .resendVerificationLoggedIn()
      .then(() => {
        onSend();
      })
      .catch((error) => {
        setResendDisabled(false);
        setResendError(getSingleErrorFromResponse(error.response));
      })
      .finally(() => {
        setResendLoading(false);
      });
  }

  function handleEntering() {
    setOtpCode('');
    setOtpCodeError('');
    setResendError('');

    if (allowSend && mfaMethod === constants.SMS_METHOD) {
      handleResend(false);
    }
  }

  if (!mfaMethod) {
    return null;
  }

  const { title, description, Icon, fieldLabel } =
    constants.getUIInfoFromMethod(mfaMethod);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel={title}
      onEntering={handleEntering}
      primaryButtonText="Verify and disable 2FA"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!otpCode}
      showCancelButton
      onSubmit={handleSubmit}
    >
      {resendError ? <Alert severity="error">{resendError}</Alert> : null}
      <Alert severity="info">
        Changing your two-factor authentication settings will sign you out on
        all devices, including this one.
      </Alert>
      <Typography>{description}</Typography>
      <KeyValuePair
        disablePadding
        disableGutters
        icon={<Icon color="action" />}
      >
        <TextField
          InputProps={{
            inputComponent: OTPInput,
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          id="otpCode"
          label={fieldLabel}
          name="otpCode"
          autoComplete="one-time-code"
          autoFocus
          error={!!otpCodeError}
          helperText={otpCodeError}
          value={otpCode}
          onChange={(e) => setOtpCode(e.target.value)}
          disabled={loading}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ inputMode: 'numeric' }}
        />
      </KeyValuePair>

      {mfaMethod === constants.SMS_METHOD ? (
        <ResendVerificationButton
          variant="secondary"
          disabled={resendDisabled}
          loading={resendLoading}
          onClick={handleResend}
          timeoutText={timeoutText}
          allowSend={allowSend}
        >
          Resend text message
        </ResendVerificationButton>
      ) : null}
    </Modal>
  );
}
