import { useCallback } from 'react';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { uploadFiles } from 'services/file/upload';

export function useUploadFiles(fileUploadUrl) {
  const { uploadSingleFile } = useQuestionnaire();
  return useCallback(
    (acceptedFiles) =>
      uploadFiles(acceptedFiles, uploadSingleFile, fileUploadUrl),
    [fileUploadUrl, uploadSingleFile],
  );
}
