import React from 'react';
import { Typography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Fade from '@material-ui/core/Fade';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';

import { EmptyState } from 'components/empty_v2';
import { Spinner } from 'components/spinner';
import { useFundReview } from 'services/providers/fund';
import { useMe } from 'services/providers/me';
import intersection from 'lodash/intersection';
import { matchStatuses, reducerActionTypes } from '../constants';
import { TrueMatchSelection } from './true_match_selection';
import { NoMatchesDisplay } from './no_matches_display';
import { PotentialMatchDataDisplay } from './potential_match_display/index';
import { PotentialMatchTab } from './potential_match_tab';
import { UpdatesAvailable } from './updates_available';
import { isMatchRead } from '../utils';

const useStyles = makeStyles((theme) => ({
  matches: {
    display: 'flex',
    minWidth: '1080px',
    flex: 1,
  },
  matchDisplay: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  matchSelection: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  matchRight: {
    flex: 3,
    padding: theme.spacing(2, 4),
  },
  warningIcon: {
    fill: theme.palette.error.main,
  },
  tabLabel: {
    fontSize: '1rem',
    display: 'flex',
    marginLeft: theme.spacing(1),
  },
  leftSpacing: {
    marginLeft: theme.spacing(2),
  },
  tabRoot: {
    alignItems: 'normal',
    marginTop: theme.spacing(2),
  },
}));

const SECTIONS = [
  'sanctionAlerts',
  'warningAlerts',
  'pepAlerts',
  'fitnessProbityAlerts',
  'adverseMediaAlerts',
];
const ALERT_TYPES = {
  sanctionAlerts: ['sanction'],
  warningAlerts: ['warning'],
  fitnessProbityAlerts: ['fitness-probity'],
  pepAlerts: [
    'pep',
    'pep-class-1',
    'pep-class-2',
    'pep-class-3',
    'pep-class-4',
  ],
  adverseMediaAlerts: ['adverse-media-alert'],
};

const partitionAlert = (alerts) =>
  alerts.reduce(
    (acc, alert) => {
      const section = SECTIONS.find(
        (s) => intersection(alert.sourceTypes, ALERT_TYPES[s]).length > 0,
      );

      if (section) {
        acc[section].push(alert);
      }
      return acc;
    },
    {
      sanctionAlerts: [],
      warningAlerts: [],
      pepAlerts: [],
      fitnessProbityAlerts: [],
      adverseMediaAlerts: [],
    },
  );

export function MatchesDisplay({
  questionId,
  dataIsOutOfSync,
  isNodeLoading,
  hasSearch,
  isDiligenceApprover,
  postAutomatedSearch,
  potentialMatches,
  queuedMatchId,
  showMatchStatus,
  selectedMatchId,
  dispatch,
  onManualSearch,
  managedDiligenceEnabled,
}) {
  const classes = useStyles();
  const [fundReview] = useFundReview();
  const [me] = useMe();

  if (isNodeLoading) {
    return <Spinner fullScreen />;
  }

  if (!hasSearch) {
    if (postAutomatedSearch) {
      if (isDiligenceApprover) {
        return (
          <EmptyState
            title="No matches yet"
            text={
              <>
                <Typography variant="body1">
                  This individual/entity is ready for screening.
                </Typography>

                <Typography variant="body1">
                  For individuals, providing a date of birth can improve the
                  quality of the search results.
                </Typography>
              </>
            }
            ctaText="Start screening"
            ctaPermission
            ctaOnClick={onManualSearch}
          />
        );
      }
      return (
        <EmptyState
          title="No matches yet"
          text="This individual/entity has not been screened."
        />
      );
    }

    return (
      <EmptyState
        title="Pending submission from investor"
        text="After the investor submits their questionnaire, potential matches will appear here for review."
        Icon={HourglassEmpty}
        iconVariant="neutral"
      />
    );
  }

  const hasNoMatches =
    !potentialMatches?.length || potentialMatches.length === 0;

  if (hasNoMatches) {
    return <NoMatchesDisplay />;
  }

  const selectedMatchData =
    potentialMatches?.find((match) => match?.id === selectedMatchId) || {};

  const {
    sanctionAlerts = [],
    warningAlerts = [],
    pepAlerts = [],
    fitnessProbityAlerts = [],
    adverseMediaAlerts = [],
  } = partitionAlert(selectedMatchData?.alerts || []);

  let showMatchSelection = fundReview && !dataIsOutOfSync;
  let showUpdatesAvailable =
    !isMatchRead(selectedMatchData) &&
    showMatchSelection &&
    selectedMatchData.matchStatus !== matchStatuses.NO_CHOICE;

  if (managedDiligenceEnabled) {
    showMatchSelection = showMatchSelection && me.isStaff;
    showUpdatesAvailable = showUpdatesAvailable && me.isStaff;
  }

  return (
    <div className={classes.matches}>
      <div className={classes.matchSelection}>
        <Tabs
          orientation="vertical"
          className={classes.tabRoot}
          value={queuedMatchId || selectedMatchId}
          onChange={(e, v) => {
            dispatch({
              type: reducerActionTypes.ENQUEUE_ID,
              id: v,
            });
          }}
        >
          {potentialMatches.map((matchData) => (
            <PotentialMatchTab
              key={matchData.id}
              name={matchData.name}
              value={matchData.id}
              matchStatus={matchData.matchStatus}
              isMatchRead={isMatchRead(matchData)}
            />
          ))}
        </Tabs>
      </div>

      <div className={classes.matchRight}>
        <Fade
          in={!queuedMatchId}
          mountOnEnter
          unmountOnExit
          timeout={150}
          onExited={() => {
            dispatch({
              type: reducerActionTypes.DEQUEUE_ID,
            });
          }}
        >
          <div className={classes.matchDisplay}>
            {showUpdatesAvailable ? (
              <UpdatesAvailable
                questionId={questionId}
                selectedId={selectedMatchData?.id}
                dispatch={dispatch}
              />
            ) : null}
            {showMatchSelection ? (
              <TrueMatchSelection
                questionId={questionId}
                name={selectedMatchData?.name || ''}
                selectedId={selectedMatchData?.id}
                matchStatus={selectedMatchData.matchStatus}
                explanations={selectedMatchData.explanations}
                managedDiligenceEnabled={managedDiligenceEnabled}
                showMatchStatus={showMatchStatus}
                dispatch={dispatch}
              />
            ) : null}

            <PotentialMatchDataDisplay
              name={selectedMatchData?.name || ''}
              aliases={selectedMatchData?.aliases || []}
              associates={selectedMatchData?.associates || []}
              potentialMatchDataFields={selectedMatchData?.fields || {}}
              sanctionHits={sanctionAlerts}
              warningHits={warningAlerts}
              fitnessProbityHits={fitnessProbityAlerts}
              adverseMediaHits={adverseMediaAlerts}
              mediaAlerts={selectedMatchData.mediaAlerts}
              pepHits={pepAlerts}
              lastReviewedAt={
                selectedMatchData.matchStatus === matchStatuses.NO_CHOICE
                  ? null
                  : selectedMatchData.lastReviewedAt
              }
            />
          </div>
        </Fade>
      </div>
    </div>
  );
}
