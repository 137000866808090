import React, { useState } from 'react';

import { Modal } from '@passthrough/uikit';
import { useDiscardConfirmation } from 'services/discard';
import { objectEquals } from 'services/utils';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

export function CustomWorkflowsSettingsModal({
  open,
  onClose,
  initialState,
  errors,
  setErrors,
  updateSettings,
  loading,
}) {
  const [form, setForm] = useState(initialState);
  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: onClose,
    needConfirmation: open && !objectEquals(form, initialState),
  });

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      headerLabel="Custom approvals"
      showCancelButton
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      onEntering={() => {
        setForm(initialState);
        setErrors({});
      }}
      onSubmit={() => updateSettings(form)}
    >
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={!form.optOutOfCustomApproval}
              onChange={(e) => {
                setForm((f) => ({
                  ...f,
                  optOutOfCustomApproval: !e.target.checked,
                }));
              }}
            />
          }
          label="Apply custom approvals"
        />
        {errors.optOutOfCustomApproval ? (
          <FormHelperText error>{errors.optOutOfCustomApproval}</FormHelperText>
        ) : null}
      </FormControl>
    </Modal>
  );
}
