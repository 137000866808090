import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';
import * as api from 'services/api';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { useToast } from 'services/toast';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { getSingleErrorFromResponse } from 'services/utils';
import { useWhiteLabelConfig } from 'services/providers/theme';

import { ExperienceSelector } from '../experience_selector';
import { ExperienceItem } from '../experience_item';
import { ExploreStorage } from '../utils';
import { MenuCard } from './menu_card';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    zIndex: 2,
    maxWidth: '450px',
  },
  step2Div: {
    margin: theme.spacing(1, 0),
  },
}));

export function ExploreMenu({ onClose, open, anchor, fullScreen }) {
  const classes = useStyles();
  const [experience, setExperience] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = React.useState(0);
  const { errorToast } = useToast();
  const { productName } = useWhiteLabelConfig();
  const current = ExploreStorage.getExperience();

  const onSubmit = () => {
    setLoading(true);

    api
      .switchExploreExperience(experience)
      .then((response) => {
        ExploreStorage.setExperience(experience);
        window.location.href = response.data.url;
      })
      .catch((err) => {
        const msg = getSingleErrorFromResponse(err.response);
        errorToast(msg);
        setLoading(false);
      });
  };

  const handleClose = () => {
    onClose();
    setExperience(null);
    setStep(0);
  };

  const stepContent =
    step === 0 ? (
      <MenuCard
        key="step_1"
        onClose={handleClose}
        title={`👋 Welcome to ${productName}`}
        fullScreen={fullScreen}
        actions={
          <>
            <Typography variant="label" color="text.secondary">
              Ready to see more?
            </Typography>
            <Button variant="secondary" onClick={() => setStep(1)}>
              Change experience
            </Button>
          </>
        }
      >
        <Typography>
          You can freely explore and change your user experience anytime. In
          this experience:
        </Typography>
        <ul>
          <li>No emails will be sent.</li>
          <li>Signatures are not legally binding.</li>
        </ul>
      </MenuCard>
    ) : (
      <MenuCard
        key="step_2"
        onClose={handleClose}
        fullScreen={fullScreen}
        title="Change user experience"
        actions={
          <>
            <Button
              variant="text"
              onClick={() => setStep(0)}
              startIcon={<KeyboardArrowLeftIcon />}
              disabled={loading}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={!experience}
              loading={loading}
            >
              Start
            </Button>
          </>
        }
      >
        <ExperienceItem variant="default" experience={current} />
        <div className={classes.step2Div}>
          <Typography variant="label" color="text.secondary">
            Select a new experience to continuing exploring {productName}.
          </Typography>
        </div>
        <ExperienceSelector
          exclude={current}
          experience={experience}
          setExperience={setExperience}
        />
      </MenuCard>
    );

  if (fullScreen) {
    return (
      <Dialog open={open} fullScreen onClose={handleClose}>
        {stepContent}
      </Dialog>
    );
  }

  return (
    <Popper
      open={open}
      anchorEl={anchor?.current}
      disablePortal
      onClose={handleClose}
      placement="top-end"
      className={classes.root}
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      }}
    >
      <Paper elevation={3}>{stepContent}</Paper>
    </Popper>
  );
}
