import { Icons } from '@passthrough/uikit';

export const TOTP_METHOD = 'totp';
export const SMS_METHOD = 'sms';

const METHOD_TO_UI_MAP = {
  [TOTP_METHOD]: {
    title: 'Enter code from your authenticator app',
    description:
      'Open your two-factor authentication app to view your authentication code.',
    Icon: Icons.PhoneIphoneOutlined,
    fieldLabel: 'Authentication code',
  },
  [SMS_METHOD]: {
    title: 'Check your phone',
    description:
      'We just sent a text message with a verification code to your phone.',
    Icon: Icons.SmsOutlined,
    fieldLabel: 'Verification code',
  },
};

export function getMethod({ isTotp, isSms }) {
  if (isTotp) return TOTP_METHOD;
  if (isSms) return SMS_METHOD;
  return null;
}

export function getUIInfoFromMethod(method) {
  return METHOD_TO_UI_MAP[method];
}
