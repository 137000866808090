export const ISSUE_COLUMN = 'issue';
export const RISK_RATING_COLUMN = 'risk_rating';
export const LEGAL_NAME_COLUMN = 'legal_name';
export const INVESTOR_COLUMN = 'investor';
export const CLOSING_COLUMN = 'closing';
export const FUND_COLUMN = 'fund';
export const CREATED_COLUMN = 'created';
export const TRUE_ALERT_KEY = 'newAlert-TRUE_MATCH';
export const UNSURE_ALERT_KEY = 'newAlert-NOT_SURE';
export const REVIEW_KEY = 'review';
export const POTENTIAL_MATCH_KEY = 'potentialMatch';
export const MISIDENTIFIED_KEY = 'misidentified';
export const COMMENT_KEY = 'comment';
export const WAIVED_KEY = 'waived';
export const ISSUE_FILTERS = [
  {
    key: TRUE_ALERT_KEY,
    display: 'New alert (Positive Match)',
  },
  {
    key: UNSURE_ALERT_KEY,
    display: 'New alert (Unsure Match)',
  },
  {
    key: REVIEW_KEY,
    display: 'Ready for review',
  },
  {
    key: WAIVED_KEY,
    display: 'Waived',
  },
  {
    key: POTENTIAL_MATCH_KEY,
    display: 'New potential match',
  },
  {
    key: COMMENT_KEY,
    display: 'Unresolved',
  },
  {
    key: MISIDENTIFIED_KEY,
    display: 'Misidentified investing entity',
  },
];
