import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import CogIcon from 'mdi-material-ui/Cog';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CheckIcon from 'mdi-material-ui/Check';
import Sitemap from 'mdi-material-ui/Sitemap';
import Select from '@material-ui/core/Select';
import MenuItemV4 from '@material-ui/core/MenuItem';
import { Menu, MenuItem, Button, ClickableChip } from '@passthrough/uikit';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ContentSave from 'mdi-material-ui/ContentSave';
import ContentCopy from 'mdi-material-ui/ContentCopy';
import ContentPaste from 'mdi-material-ui/ContentPaste';
import ContentDuplicate from 'mdi-material-ui/ContentDuplicate';
import FileMoveOutline from 'mdi-material-ui/FileMoveOutline';
import MouseIcon from '@material-ui/icons/Mouse';
import PublicIcon from '@material-ui/icons/Public';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Spinner } from 'components/spinner';
import { Breadcrumbs } from 'components/crumb';
import * as api from 'services/api';
import * as urls from 'services/urls';
import { useToast } from 'services/toast';
import { useFeatureFlags } from 'services/providers/feature_flag';
import {
  getSingleErrorFromResponse,
  objectEquals,
  isPageInRange,
} from 'services/utils';
import { useMe } from 'services/providers/me';
import { Button as MuiButton } from 'components/button';

import { PublishModal } from './modals/publish_modal';
import { CompareTemplateModal } from './modals/compare_template_modal';
import { SettingsModal } from './modals/settings_modal';
import { BulkLogicModal } from './modals/bulk_action_modals/bulk_logic_modal';
import { BulkPageShiftModal } from './modals/bulk_action_modals/bulk_page_shift_modal';
import { BulkOutputShiftModal } from './modals/bulk_action_modals/bulk_output_shift_modal';
import { BulkUpdateInvestorTypeModal } from './modals/bulk_action_modals/bulk_update_investor_type_modal';
import { PasteModal } from './modals/bulk_action_modals/paste_modal';
import { SendModal } from './modals/send_modal';
import { MappingModal } from './modals/mapping_modal';
import { Editor } from './editor';
import { AddButton } from './add_button';
import { LastModified } from './last_modified';
import { ReviewTimer } from './review_timer/index';
import {
  reducerActionTypes,
  noteFlags,
  INITIAL_STATE,
  REVIEW_STATE,
  REVISION_STATE,
  COMPLETED_STATE,
  stateDict,
} from './constants';
import {
  createDuplicatedQuestion,
  newId,
  newSectionId,
  getQuestionsToImport,
  getTagsForQuestion,
} from './common/helpers';
import { noteDataReducer } from './note_reducer_utils';
import { MultiOptionPrompt } from './modals/multi_option_prompt';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  page: {
    padding: theme.spacing(1),
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topRowRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(2),
    columnGap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
  },
  lastModifiedContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    height: 'calc(100vh - 220px)',
    overflowY: 'scroll',
    width: '100%',
  },
  publishButton: {
    marginLeft: theme.spacing(1),
  },
  selectionCount: {
    textAlign: 'center',
    fontSize: '0.5rem',
    right: '10%',
    position: 'relative',
  },
  errorIcon: {
    color: theme.palette.error.icon,
  },
  warningIcon: {
    color: theme.palette.warning.icon,
  },
  stateStepper: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2, 0, 0),
    marginBottom: theme.spacing(2),
  },
  stateStepButton: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

function truncate(text) {
  if (!text) {
    return '';
  }
  if (text.length > 500) {
    return `${text.substring(0, 500)}...`;
  }
  return text;
}

const REVIEW_AND_PUBLISH_MODAL = 2;
const QUESTIONNAIRE_SETTINGS_MODAL = 3;
const BULK_UPDATE_INVESTOR_TYPE_MODAL = 4;
const BULK_LOGIC_MODAL = 5;
const BULK_PAGE_SHIFT_MODAL = 6;
const BULK_OUTPUT_SHIFT_MODAL = 7;
const PASTE_MODAL = 8;
const COMPARE_TEMPLATE_MODAL = 9;
const MAPPING_MODAL = 10;

const NONE_FILTER = 'none';
const LINK_FILTER = 'link';
const UNREVIEWED_FILTER = 'unreviewed';
const DISCUSS_WITH_CLIENT_FILTER = 'discuss_with_client';
const NEEDS_REVIEW_FILTER = 'needs_review';
const MAJOR_FILTER = 'major';
const MINOR_FILTER = 'minor';

export function OnboardingPage() {
  const classes = useStyles();
  const { fundId, questionnaireId } = useParams();
  const { toast, errorToast } = useToast();
  const { MAPPING_ENABLED } = useFeatureFlags();

  const [onboarding, setOnboarding] = useState(null);
  const [notes, dispatch] = useReducer(noteDataReducer, {});
  const [latestOnboardingUpdateTime, setlatestOnboardingUpdateTime] =
    useState(null);

  const [activeId, setActiveId] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [draggingId, setDraggingId] = useState('');

  const [questions, setQuestions] = useState([]);
  const [modal, setModal] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [
    questionnaireHasUnpublishedChanges,
    setQuestionnaireHasUnpublishedChanges,
  ] = useState(false);
  const [draftFileIsNotPublished, setDraftFileIsNotPublished] = useState(false);

  const [filter, setFilter] = useState(NONE_FILTER);
  const [showSendModal, setShowSendModal] = useState(null);
  const [me] = useMe();
  const theme = useTheme();
  const [reviewedQuestionIds, setReviewedQuestionIds] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  // ReviewTimer state
  const [savedTimeInSeconds, setSavedTimeInSeconds] = useState(null);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);
  const [saveTimeErrorMsg, setSaveTimeErrorMsg] = useState(null);
  const [saveTimeIsLoading, setSaveTimeIsLoading] = useState(false);

  const [hasUnsavedTime, setHasUnsavedTime] = useState(false);
  const [isSavingTime, setIsSavingTime] = useState(false);

  useEffect(() => {
    if (totalTimeInSeconds && totalTimeInSeconds !== savedTimeInSeconds) {
      setHasUnsavedTime(true);
    } else {
      setHasUnsavedTime(false);
    }
  }, [savedTimeInSeconds, totalTimeInSeconds]);

  const showReviewTimer =
    savedTimeInSeconds !== null &&
    (onboarding?.state === INITIAL_STATE ||
      onboarding?.state === REVIEW_STATE ||
      onboarding?.state === REVISION_STATE);

  const handleMenu = (event) => {
    setAnchorEl((el) => (el ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeQuestion =
    activeId && questions.length
      ? questions.find((q) => q.id === activeId || q.sectionName === activeId)
      : null;

  const showUnpublishedChangesBadge =
    questionnaireHasUnpublishedChanges || draftFileIsNotPublished;

  const filterDict = {
    // onboarding managers requested to be able to see which
    // section questions belonged in even after filtering
    [LINK_FILTER]: (question) =>
      question.links?.length > 0 || question.isSection,
    [NONE_FILTER]: () => true,
    [UNREVIEWED_FILTER]: (question) =>
      !reviewedQuestionIds.includes(question.id) || question.isSection,
    [DISCUSS_WITH_CLIENT_FILTER]: (question) =>
      notes[question.id]?.some(
        (note) => note.flag === noteFlags.DISCUSS_WITH_CLIENT && !note.resolved,
      ) || question.isSection,
    [NEEDS_REVIEW_FILTER]: (question) =>
      notes[question.id]?.some(
        (note) => note.flag === noteFlags.NEEDS_REVIEW && !note.resolved,
      ) || question.isSection,
    [MAJOR_FILTER]: (question) =>
      notes[question.id]?.some(
        (note) => note.severity === 'major' && !note.resolved,
      ) || question.isSection,
    [MINOR_FILTER]: (question) =>
      notes[question.id]?.some(
        (note) => note.severity === 'minor' && !note.resolved,
      ) || question.isSection,
  };

  const filteredQuestions = questions.filter(filterDict[filter]);

  function saveQuestions(newQuestions, investorWord) {
    setIsSaving(true);
    setQuestions(newQuestions);
    return api
      .saveQuestionnaireDraft({
        fundId,
        questionnaireId,
        draftData: newQuestions,
        draftTime: onboarding.updatedAt,
        investorWord,
      })
      .then((response) => {
        const publishedData = response.data.data;
        const savedDraftData = response.data.draftData;

        setOnboarding((o) => ({ ...o, ...response.data }));
        setQuestions(savedDraftData || []);
        setIsSaving(false);
        setQuestionnaireHasUnpublishedChanges(
          !objectEquals(publishedData, savedDraftData),
        );
        return true;
      })
      .catch((e) => {
        const error = getSingleErrorFromResponse(e.response);
        errorToast(error);
        return false;
      });
  }

  function createQuestionNote(ids, note, originalNoteId) {
    return api
      .createQuestionNote({
        fundId,
        questionnaireId,
        qids: ids,
        note,
        originalNoteId,
      })
      .then((response) => {
        if (onboarding.onboardingLogs.length === 0) {
          // this should ONLY ever be triggered for
          // already existing questionnaires that
          // have no onboarding logs-- this refresh
          // is to make sure the frontend has the
          // most recent onboarding log for future logic
          window.location.reload();
        } else {
          for (let i = 0; i < ids.length; i += 1) {
            dispatch({
              type: reducerActionTypes.CREATE_NOTE,
              currState: onboarding.state,
              user: me,
              qid: ids[i],
              logId: response.data.logId,
              createdAt: response.data.createdAtTimes[i],
              originalId: originalNoteId,
              note: {
                note_id: response.data.ids[i],
                qid: ids[i],
                note: note.note,
                flag: note.flag,
                severity: note.severity,
                resolved:
                  !note.flag || note.flag === noteFlags.NO_CHANGE_NEEDED,
              },
            });
          }
        }
      });
  }

  function updateQuestionNote(qid, questionNoteId, note) {
    return api
      .updateQuestionNote({
        fundId,
        questionnaireId,
        questionNoteId,
        note,
      })
      .then((response) => {
        dispatch({
          type: reducerActionTypes.UPDATE_NOTE,
          qid,
          questionNoteId,
          user: me,
          currState: onboarding.state,
          createResolvedNote: Object.prototype.hasOwnProperty.call(
            note,
            'resolved',
          ),
          note: {
            qid,
            note: note.note,
            flag: note.flag,
            severity: note.severity,
            updatedAt: response.data.updatedAt,
            resolved:
              note.resolved ||
              !Object.prototype.hasOwnProperty.call(note, 'flag') ||
              note.flag === noteFlags.NO_CHANGE_NEEDED,
          },
        });
      });
  }

  function deleteQuestionNote(qid, questionNoteId) {
    return api
      .deleteQuestionNote({ fundId, questionnaireId, questionNoteId })
      .then(() => {
        dispatch({
          type: reducerActionTypes.DELETE_NOTE,
          qid,
          questionNoteId,
        });
      });
  }

  function moveQuestionNotes(fromQuestionId, toQuestionId) {
    dispatch({
      type: reducerActionTypes.MOVE_NOTES,
      fromQuestionId,
      toQuestionId,
    });
    return api.moveQuestionNotes({
      fundId,
      questionnaireId,
      fromQuestionId,
      toQuestionId,
    });
  }

  function saveState(state, totalSeconds, reviewerId) {
    setIsSavingTime(true);
    api
      .transitionQuestionnaireState({
        fundId,
        questionnaireId,
        state,
        totalSeconds,
        reviewerId,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        const errorMessage = getSingleErrorFromResponse(error.response);
        errorToast(errorMessage);
      })
      .finally(() => {
        setIsSavingTime(false);
      });
  }

  function saveTotalSeconds(totalSeconds) {
    setSaveTimeErrorMsg(null);
    setSaveTimeIsLoading(true);

    // return promise so that the timer can close the modal
    // and restart the timer after successful save
    return api
      .saveTotalSecondsToOnboardingLog({
        fundId,
        questionnaireId,
        totalSeconds,
      })
      .then((response) => {
        toast('Saved review time');
        setSavedTimeInSeconds(response.data.totalSeconds);
        setSaveTimeIsLoading(false);
      })
      .catch((e) => {
        const error = getSingleErrorFromResponse(e.response);
        setSaveTimeErrorMsg(error);
        setSaveTimeIsLoading(false);
      });
  }

  function wasContiguousMultiSelectKeyUsed(event) {
    return event && event.shiftKey;
  }

  function wasDiscreteMultiSelectKeyUsed(event) {
    return event && (event.metaKey || event.ctrlKey);
  }

  function calculateDestinationIndexOffset(destinationIndex) {
    return selectedIds.reduce((runningOffset, currId) => {
      if (currId === draggingId) {
        return runningOffset;
      }

      const currIndex = questions.findIndex(
        (currQuestion) => currQuestion.id === currId,
      );

      if (currIndex >= destinationIndex) {
        return runningOffset;
      }

      return runningOffset + 1;
    }, 0);
  }

  function onMultiDragStart(start) {
    const currId = start.draggableId;
    const isSelected = selectedIds.find((nextId) => nextId === currId);

    if (!isSelected) {
      setSelectedIds([currId]);
    }

    setDraggingId(currId);
  }

  function onMultiDragEnd(result) {
    setDraggingId('');
    if (result.destination) {
      const offset = calculateDestinationIndexOffset(result.destination.index);
      const insertIndex = result.destination.index - offset;

      const selectedQuestions = questions.filter((currQuestion) =>
        selectedIds.includes(currQuestion.id),
      );

      const newQuestions = questions.filter(
        (currQuestion) => !selectedIds.includes(currQuestion.id),
      );

      newQuestions.splice(insertIndex, 0, ...selectedQuestions);

      saveQuestions(newQuestions);
    }
  }

  function individualSelect(currId) {
    const wasSelected = selectedIds.includes(currId);

    if (!wasSelected || (wasSelected && selectedIds.length > 1)) {
      setSelectedIds([currId]);
      return;
    }

    if (currId === activeId) {
      return;
    }

    setSelectedIds([]);
  }

  function contiguousRangeGroupSelect(currId) {
    if (!selectedIds.length) {
      setSelectedIds([currId]);
    }

    const indexOfNewId = questions.findIndex(
      (currQuestion) => currQuestion.id === currId,
    );

    const lastSelectedId = selectedIds[selectedIds.length - 1];
    const indexOfLastSelectedQuestion = questions.findIndex(
      (currQuestion) => currQuestion.id === lastSelectedId,
    );

    if (indexOfNewId === indexOfLastSelectedQuestion) {
      return;
    }

    const isSelectingForwards = indexOfNewId > indexOfLastSelectedQuestion;

    const start = isSelectingForwards
      ? indexOfLastSelectedQuestion
      : indexOfNewId;
    const end = isSelectingForwards
      ? indexOfNewId
      : indexOfLastSelectedQuestion;

    const inBetweenQuestions = questions.slice(start, end + 1);
    const filteredIds = filteredQuestions.map((q) => q.id);

    const questionsToSelect = inBetweenQuestions.filter((currQuestion) => {
      if (selectedIds.includes(currQuestion.id)) {
        return false;
      }
      if (!filteredIds.includes(currQuestion.id)) {
        return false;
      }
      return true;
    });

    const sortedQuestions = isSelectingForwards
      ? questionsToSelect
      : [...questionsToSelect].reverse();
    const sortedQuestionIds = sortedQuestions.map(
      (currQuestion) => currQuestion.id,
    );

    setSelectedIds((previouslySelectedIds) => [
      ...previouslySelectedIds,
      ...sortedQuestionIds,
    ]);
  }

  function discreteGroupSelect(currId) {
    setSelectedIds((previouslySelectedIds) => {
      const index = previouslySelectedIds.indexOf(currId);

      if (index === -1) {
        return [...previouslySelectedIds, currId];
      }

      if (currId === activeId) {
        return previouslySelectedIds;
      }

      const shallow = [...previouslySelectedIds];
      shallow.splice(index, 1);
      return shallow;
    });
  }

  function handleMultiClickItem(q, event = null) {
    // eslint-disable-next-line no-alert
    if (hasUnsavedChanges && !window.confirm('Discard unsaved changes?')) {
      return;
    }

    if (wasContiguousMultiSelectKeyUsed(event)) {
      contiguousRangeGroupSelect(q.id);
      return;
    }

    if (wasDiscreteMultiSelectKeyUsed(event)) {
      discreteGroupSelect(q.id);
      return;
    }

    setActiveId(q.id);
    individualSelect(q.id);
  }

  function getLatestOnboardingUpdateTime() {
    return api
      .getQuestionnaireDraft({ fundId, questionnaireId })
      .then((response) => {
        setlatestOnboardingUpdateTime(response.data.updatedAt);
      })
      .catch(() => {
        setlatestOnboardingUpdateTime(null);
      });
  }

  function pollLatestOnboardingUpdateTime() {
    const intervalId = setInterval(getLatestOnboardingUpdateTime, 15000);
    return () => clearInterval(intervalId);
  }

  async function checkHasFileChanged() {
    const fileResponse = await api.checkQuestionnaireFileChanged({
      fundId,
      questionnaireId,
    });
    setDraftFileIsNotPublished(fileResponse.data.hasChanged);
  }

  useEffect(pollLatestOnboardingUpdateTime, []);
  useEffect(() => {
    checkHasFileChanged();
  }, []);

  useEffect(() => {
    if (onboarding && latestOnboardingUpdateTime && modal !== MAPPING_MODAL) {
      if (
        Date.parse(onboarding.updatedAt) <
        Date.parse(latestOnboardingUpdateTime)
      ) {
        toast(
          'Another user has updated this questionnaire. You must reload the page ' +
            'immediately to continue working on the most up-to-date draft of the questionnaire.',
          {
            persist: true,
            preventDuplicate: true,
            action: () => (
              <MuiButton
                variant="outlined"
                color="inherit"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </MuiButton>
            ),
          },
        );
      }
    }
  }, [latestOnboardingUpdateTime, modal]);

  function getLatestDraftQuestionnaire() {
    api.getQuestionnaireDraft({ fundId, questionnaireId }).then((response) => {
      const publishedData = response.data.data;
      const savedDraftData = response.data.draftData;

      setOnboarding(response.data);
      if (response.data.onboardingLogs.length > 0) {
        setReviewedQuestionIds(response.data.onboardingLogs[0].reviewedQids);
        setSavedTimeInSeconds(response.data.onboardingLogs[0].totalSeconds);
      }
      const logsWithNotes = response.data.onboardingLogs.filter(
        (log) => log.questionNotes.length > 0,
      );
      if (Object.keys(notes).length === 0 && logsWithNotes.length > 0) {
        dispatch({
          type: reducerActionTypes.INITIALIZE_DATA,
          onboardingLogs: response.data.onboardingLogs,
        });
      }
      setQuestions(savedDraftData || []);
      const hasChanges = !objectEquals(publishedData, savedDraftData);
      setQuestionnaireHasUnpublishedChanges(hasChanges);
    });
  }

  useEffect(() => {
    getLatestDraftQuestionnaire();
  }, [fundId]);

  // NOTE: updatedAt time is modified when publishing, so after successfully
  // publishing, should update the questionnaire data in the frontend to
  // also reflect the modified updatedAt value so that a user is not prompted
  // to refresh after they publish
  function onClosePostPublish() {
    setModal(null);

    getLatestDraftQuestionnaire();
  }

  function onDraftFileUploadSuccess() {
    getLatestDraftQuestionnaire();
    checkHasFileChanged();
  }

  const updateSettings = (newWord) => {
    saveQuestions(questions, newWord);
  };

  function handleBulkDelete() {
    const remainingQuestions = questions.filter(
      (q) => !selectedIds.includes(q.id),
    );

    saveQuestions(remainingQuestions);
    setSelectedIds([]);
  }

  function handleAddSection() {
    const index = questions.findIndex((q) => q.id === activeId) + 1;
    const before = questions.slice(0, index);
    const after = questions.slice(index);

    const sections = questions.filter((q) => q.isSection);
    const newSection = {
      id: newSectionId(sections),
      sectionName: 'Section',
      isSection: true,
    };

    saveQuestions([...before, newSection, ...after]);

    handleMultiClickItem(newSection);
  }

  function handleAddQuestion() {
    const index = questions.findIndex((q) => q.id === activeId) + 1;
    const before = questions.slice(0, index);
    const after = questions.slice(index);

    const newQuestion = {
      id: newId(questions),
      frontendLabel: '',
      hellosignBoxes: [],
      links: [],
      deps: [],
      choices: [],
      booleanType: '',
      question: '',
      isRequired: 'required',
      notInteroperable: false,
      isAsked: 'always',
      reviewed: false,
      answerType: '',
      notes: '',
    };

    saveQuestions([...before, newQuestion, ...after]);
    handleMultiClickItem(newQuestion);
  }

  function handleAddSignature() {
    const index = questions.findIndex((q) => q.id === activeId) + 1;
    const before = questions.slice(0, index);
    const after = questions.slice(index);

    const newSignature = {
      id: newId(questions, 'S'),
      frontendLabel: '',
      hellosignBoxes: [],
      links: [],
      deps: [],
      choices: [],
      booleanType: '',
      question: '',
      isRequired: 'required',
      isAsked: 'always',
      reviewed: false,
      answerType: 'affirmation',
      notes: '',
      isSignature: true,
      signer: 'investor1',
    };

    saveQuestions([...before, newSignature, ...after]);
    handleMultiClickItem(newSignature);
  }

  function handleAddText() {
    const index = questions.findIndex((q) => q.id === activeId) + 1;
    const before = questions.slice(0, index);
    const after = questions.slice(index);

    const newText = {
      id: newId(questions, 'T'),
      hellosignBoxes: [],
      deps: [],
      text: '',
      answerType: 'text',
      isRequired: 'required',
      isAsked: 'always',
      reviewed: false,
      notes: '',
      isText: true,
    };

    saveQuestions([...before, newText, ...after]);
    handleMultiClickItem(newText);
  }

  function handleBulkLogicUpdate(bulkLogic) {
    const updatedQuestions = questions.map((q) => {
      if (!selectedIds.includes(q.id)) {
        return q;
      }

      return {
        ...q,
        deps: bulkLogic,
      };
    });

    saveQuestions(updatedQuestions);
  }

  function handleBulkInvestorTypeUpdate(investorType) {
    const updatedQuestions = questions.map((q) => {
      if (!selectedIds.includes(q.id)) {
        return q;
      }

      return {
        ...q,
        isAsked: investorType,
      };
    });

    saveQuestions(updatedQuestions);
  }
  const importQuestions = (pastedQuestions, shouldReuseIDs) => {
    const importedQuestions = getQuestionsToImport(
      pastedQuestions,
      questions,
      shouldReuseIDs,
    );

    const index = questions.findIndex((q) => q.id === activeId) + 1;
    const before = questions.slice(0, index);
    const after = questions.slice(index);
    saveQuestions([...before, ...importedQuestions, ...after]);
    handleMultiClickItem(importedQuestions[importedQuestions.length - 1]);
    return importedQuestions;
  };

  const copyQuestions = () => {
    if (selectedIds.length) {
      const selectedJSON = JSON.stringify(
        questions.filter((q) => selectedIds.includes(q.id)),
      );
      navigator.clipboard.writeText(selectedJSON);
      toast(
        `Copied ${selectedIds.length} question${
          selectedIds.length > 1 ? 's' : ''
        }`,
      );
    }
  };

  const pasteQuestions = (pastedQuestions, shouldReuseIDs = false) => {
    try {
      const importedQuestions = importQuestions(
        pastedQuestions,
        shouldReuseIDs,
      );
      toast(
        `Imported ${importedQuestions.length} question${
          importedQuestions.length > 1 ? 's' : ''
        }`,
      );
      setModal(null);
    } catch (e) {
      errorToast('Could not paste questions');
    }
  };

  function handleBulkPageShift(shiftNum, pageRange) {
    let movedItems = 0;
    const updatedQuestions = questions.map((q) => {
      if (!selectedIds.includes(q.id) || !q.hellosignBoxes) {
        return q;
      }

      const newOutputBoxes = q.hellosignBoxes.map((box) => {
        if (isPageInRange(box.page, pageRange)) {
          movedItems += 1;
          return {
            ...box,
            page: box.page + shiftNum,
          };
        }
        return box;
      });

      // link pages are saved as strings sometimes
      const newLinks = q.links.map((link) => {
        if (isPageInRange(link.page, pageRange)) {
          movedItems += 1;
          return {
            ...link,
            page: parseInt(link.page, 10) + shiftNum,
          };
        }
        return link;
      });

      return {
        ...q,
        hellosignBoxes: newOutputBoxes,
        links: newLinks,
      };
    });

    saveQuestions(updatedQuestions);
    return movedItems;
  }

  function handleBulkOutputShift(x, y) {
    const updatedQuestions = questions.map((q) => {
      if (!selectedIds.includes(q.id) || !q.hellosignBoxes) {
        return q;
      }

      const newOutputBoxes = q.hellosignBoxes.map((box) => ({
        ...box,
        x: box.x + x,
        y: box.y + y,
      }));

      return {
        ...q,
        hellosignBoxes: newOutputBoxes,
      };
    });

    saveQuestions(updatedQuestions);
  }

  function handleBulkReview() {
    const newReviewedQuestions = selectedIds.concat(
      reviewedQuestionIds.filter((id) => selectedIds.indexOf(id) === -1),
    );

    api
      .updateQuestionnaireReviewed({
        fundId,
        questionnaireId,
        reviewedQids: newReviewedQuestions,
      })
      .then(() => {
        setReviewedQuestionIds(newReviewedQuestions);
      });
  }

  function findLastSelectedQuestionIndex() {
    // the last selectedId may not belong to
    // the last question due to id selection order
    for (let i = questions.length - 1; i >= 0; i -= 1) {
      const currQuestion = questions[i];

      if (selectedIds.includes(currQuestion.id)) {
        return i;
      }
    }

    // this return should never be reached
    return null;
  }

  function handleBulkDuplicate() {
    const selectedQuestions = selectedIds.map((id) =>
      questions.find((q) => q.id === id),
    );
    const duplicatedQuestions = [];

    for (let i = 0; i < selectedQuestions.length; i += 1) {
      const currQuestion = selectedQuestions[i];

      const duplicateQuestion = createDuplicatedQuestion(currQuestion, [
        ...questions,
        ...duplicatedQuestions,
      ]);

      duplicatedQuestions.push(duplicateQuestion);
    }

    // add 1 to index to ensure that slicing properly includes last element
    // in the before group
    const lastSelectedQuestionIndex = findLastSelectedQuestionIndex() + 1;

    const questionsBeforeLast = questions.slice(0, lastSelectedQuestionIndex);
    const questionsAfterLast = questions.slice(lastSelectedQuestionIndex);

    saveQuestions([
      ...questionsBeforeLast,
      ...duplicatedQuestions,
      ...questionsAfterLast,
    ]);
  }

  function selectAll() {
    const allIds = filteredQuestions.map((q) => q.id);

    setSelectedIds(allIds);
  }

  if (onboarding === null) {
    return <Spinner fullScreen />;
  }

  function getBackgroundColor({ snapshot, q, qId }) {
    if (snapshot.isDragging) {
      return 'lightgray';
    }
    if (q.id === qId) {
      return '#2196f338';
    }

    return '';
  }

  function getDisplayIcon(q) {
    if (q.isSection) {
      return null;
    }
    if (onboarding.state === REVIEW_STATE) {
      if (reviewedQuestionIds.includes(q.id)) {
        return <CheckIcon />;
      }
      return <CloseOutlinedIcon className={classes.errorIcon} />;
    }
    if (onboarding.state === REVISION_STATE) {
      if (
        notes[q.id]?.some(
          (note) => note.flag === noteFlags.NEEDS_REVIEW && !note.resolved,
        )
      ) {
        return <ErrorOutlineIcon className={classes.errorIcon} />;
      }
      if (
        notes[q.id]?.some(
          (note) =>
            note.flag === noteFlags.DISCUSS_WITH_CLIENT && !note.resolved,
        )
      ) {
        return <LiveHelpOutlinedIcon className={classes.warningIcon} />;
      }
    }
    if (onboarding.state === COMPLETED_STATE) {
      if (
        notes[q.id]?.some(
          (note) =>
            note.flag === noteFlags.DISCUSS_WITH_CLIENT && !note.resolved,
        )
      ) {
        return <LiveHelpOutlinedIcon className={classes.warningIcon} />;
      }
    }
    return null;
  }

  function getDisplayColor(q) {
    if (q.isSection) {
      return 'black';
    }
    if (onboarding.state === REVIEW_STATE) {
      if (!reviewedQuestionIds.includes(q.id)) {
        return theme.palette.error.main;
      }
    }
    if (onboarding.state === REVISION_STATE) {
      if (
        notes[q.id]?.some(
          (note) => note.flag === noteFlags.NEEDS_REVIEW && !note.resolved,
        )
      ) {
        return theme.palette.error.main;
      }
      if (
        notes[q.id]?.some(
          (note) =>
            note.flag === noteFlags.DISCUSS_WITH_CLIENT && !note.resolved,
        )
      ) {
        return theme.palette.warning.main;
      }
    }
    if (onboarding.state === COMPLETED_STATE) {
      if (
        notes[q.id]?.some(
          (note) =>
            note.flag === noteFlags.DISCUSS_WITH_CLIENT && !note.resolved,
        )
      ) {
        return theme.palette.warning.main;
      }
    }
    return 'black';
  }

  function list() {
    const getName = (item) => {
      if (item.isSignature) {
        return `${item.id} - ${item.signer}`;
      }
      if (item.isText) {
        return `${item.id} - ${item.text}`;
      }
      return `${item.id} - ${truncate(item.question)}`;
    };
    return (
      <List className={classes.list}>
        <DragDropContext
          onDragStart={onMultiDragStart}
          onDragEnd={onMultiDragEnd}
        >
          <Droppable droppableId="droppable">
            {(outerProvided, outerSnapshot) => (
              <div
                {...outerProvided.droppableProps}
                ref={outerProvided.innerRef}
                style={{
                  border: outerSnapshot.isDraggingOver
                    ? '1px dashed black'
                    : '1px dashed transparent',
                }}
              >
                {filteredQuestions.map((q, index) => (
                  <Draggable key={q.id} draggableId={q.id} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        key={q.id}
                        button
                        selected={selectedIds.includes(q.id)}
                        onClick={(event) => {
                          handleMultiClickItem(q, event);
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: getBackgroundColor({
                            snapshot,
                            q,
                            qId: activeId,
                          }),
                          color: getDisplayColor(q),
                        }}
                      >
                        <ListItemIcon {...provided.dragHandleProps}>
                          <DragIndicatorIcon />
                          {getDisplayIcon(q)}
                        </ListItemIcon>
                        <ListItemText style={{ paddingRight: '48px' }}>
                          {q.isSection || q.sectionName ? (
                            <strong>{q.sectionName}</strong>
                          ) : (
                            <span>{getName(q)}</span>
                          )}
                        </ListItemText>

                        {q.id === draggingId && selectedIds.length > 1 ? (
                          <ListItemText
                            {...provided.dragHandleProps}
                            className={classes.selectionCount}
                          >
                            <b>Dragging {selectedIds.length}</b>
                          </ListItemText>
                        ) : null}
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {outerProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    );
  }

  const crumbs = onboarding
    ? [
        {
          name: onboarding.organizationName,
          to:
            onboarding.isOrganizationAdmin && onboarding.organizationId
              ? urls.organizationUrl({
                  organizationId: onboarding.organizationId,
                })
              : null,
        },
        {
          name: onboarding.fundName,
          to: urls.closingsUrl({ fundId }),
        },
        {
          name: onboarding.displayName,
          to: urls.fundStaffToolsUrl({ fundId }),
        },
      ]
    : [];

  let message = 'You have unsaved review time, would you like to save it?';
  const date = new Date();
  if (date.getDate() === 1 && date.getMonth() === 3) {
    message = 'Claudia says to save your review time!';
  }

  return (
    <>
      <MultiOptionPrompt
        when={(hasUnsavedChanges || hasUnsavedTime) && !isSavingTime}
        title={
          hasUnsavedChanges ? 'Discard unsaved changes?' : 'Save review time?'
        }
        onSubmit={
          hasUnsavedChanges ? null : () => saveTotalSeconds(totalTimeInSeconds)
        }
        message={
          hasUnsavedChanges
            ? 'You have unsaved changes, are you sure that you want to leave?'
            : message
        }
      />
      <Grid container className={classes.page}>
        <Grid item xs={12}>
          <div className={classes.topRow}>
            <Breadcrumbs crumbs={crumbs} />
            <div className={classes.topRowRight}>
              {showReviewTimer ? (
                <ReviewTimer
                  savedTimeInSeconds={savedTimeInSeconds}
                  saveTotalSeconds={saveTotalSeconds}
                  errorMsg={saveTimeErrorMsg}
                  isLoading={saveTimeIsLoading}
                  totalTimeInSeconds={totalTimeInSeconds}
                  setTotalTimeInSeconds={setTotalTimeInSeconds}
                />
              ) : null}
              <div className={classes.lastModifiedContainer}>
                <LastModified when={onboarding.updatedAt} />

                {MAPPING_ENABLED ? (
                  <Tooltip title={<Typography>Link questions</Typography>}>
                    <IconButton
                      aria-label="Link questions"
                      size="small"
                      onClick={() => setModal(MAPPING_MODAL)}
                      className={classes.publishButton}
                    >
                      <Sitemap />
                    </IconButton>
                  </Tooltip>
                ) : null}

                {onboarding.canPublish && (
                  <Tooltip title={<Typography>Review and Publish</Typography>}>
                    <IconButton
                      aria-label="Review and Publish"
                      size="small"
                      onClick={() => setModal(REVIEW_AND_PUBLISH_MODAL)}
                      className={classes.publishButton}
                    >
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={!showUnpublishedChangesBadge}
                      >
                        <ContentSave />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={3} md={12}>
          {onboarding.templateQuestionnaire ? (
            <ClickableChip
              onClick={() => setModal(COMPARE_TEMPLATE_MODAL)}
              label={
                <span>
                  <strong>Template:</strong>{' '}
                  {onboarding.templateQuestionnaire.name}
                </span>
              }
            />
          ) : null}
          <Stepper
            nonLinear
            activeStep={stateDict[onboarding.state]}
            className={classes.stateStepper}
          >
            <Step
              completed={
                onboarding.state === REVIEW_STATE ||
                onboarding.state === COMPLETED_STATE
              }
            >
              {onboarding.state === INITIAL_STATE ||
              onboarding.state === REVISION_STATE ? (
                <StepLabel>Edit</StepLabel>
              ) : (
                <StepButton
                  onClick={() => setShowSendModal(REVISION_STATE)}
                  className={classes.stateStepButton}
                >
                  Edit
                </StepButton>
              )}
            </Step>
            <Step completed={onboarding.state === COMPLETED_STATE}>
              {onboarding.state === REVIEW_STATE ? (
                <StepLabel>Review</StepLabel>
              ) : (
                <StepButton
                  disabled={onboarding.state === REVIEW_STATE}
                  onClick={() => setShowSendModal(REVIEW_STATE)}
                  className={classes.stateStepButton}
                >
                  Review
                </StepButton>
              )}
            </Step>
            <Step completed={onboarding.state === COMPLETED_STATE}>
              {onboarding.state === COMPLETED_STATE ? (
                <StepLabel>Completed</StepLabel>
              ) : (
                <StepButton
                  onClick={() => setShowSendModal(COMPLETED_STATE)}
                  className={classes.stateStepButton}
                >
                  Complete
                </StepButton>
              )}
            </Step>
          </Stepper>

          <div className={classes.actions}>
            <Button
              variant="text"
              onClick={handleMenu}
              endIcon={<ArrowDropDownIcon />}
              aria-label="Actions"
            >
              Edit
            </Button>
            <Menu
              id="menu-actions"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  selectAll();
                }}
                icon={<MouseIcon fontSize="small" />}
                text="Select all"
              />

              <MenuItem
                onClick={() => {
                  setModal(QUESTIONNAIRE_SETTINGS_MODAL);
                }}
                icon={<CogIcon fontSize="small" />}
                text="Settings"
                divider
              />

              <MenuItem
                onClick={() => handleBulkDuplicate()}
                disabled={selectedIds.length < 1}
                text={`Duplicate questions (${selectedIds.length})`}
                icon={<ContentDuplicate fontSize="small" />}
              />

              <MenuItem
                onClick={copyQuestions}
                disabled={selectedIds.length < 1}
                icon={<ContentCopy fontSize="small" />}
                text={`Copy questions (${selectedIds.length})`}
              />

              <MenuItem
                onClick={() => setModal(PASTE_MODAL)}
                text="Paste questions"
                icon={<ContentPaste fontSize="small" />}
              />

              <MenuItem
                onClick={() => handleBulkDelete()}
                disabled={selectedIds.length < 1}
                destructive
                text={`Delete questions (${selectedIds.length})`}
                icon={<DeleteIcon fontSize="small" />}
                divider
              />

              <MenuItem
                onClick={() => setModal(BULK_PAGE_SHIFT_MODAL)}
                disabled={selectedIds.length < 1}
                icon={<FileMoveOutline fontSize="small" />}
                text={`Shift pages (${selectedIds.length})`}
              />

              <MenuItem
                onClick={() => setModal(BULK_OUTPUT_SHIFT_MODAL)}
                disabled={selectedIds.length < 1}
                icon={<OpenWithIcon fontSize="small" />}
                text={`Shift outputs (${selectedIds.length})`}
              />

              <MenuItem
                onClick={() => setModal(BULK_UPDATE_INVESTOR_TYPE_MODAL)}
                disabled={selectedIds.length < 2}
                icon={<PublicIcon fontSize="small" />}
                text={`Update investor type (${selectedIds.length})`}
              />

              <MenuItem
                onClick={() => setModal(BULK_LOGIC_MODAL)}
                disabled={selectedIds.length < 2}
                icon={<AccountTreeIcon fontSize="small" />}
                text={`Add conditions (${selectedIds.length})`}
              />

              <MenuItem
                onClick={() => {
                  handleBulkReview();
                }}
                disabled={onboarding.state !== REVIEW_STATE}
                icon={<CheckIcon fontSize="small" />}
                text={`Mark as reviewed (${selectedIds.length})`}
              />
            </Menu>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="filter">Filter</InputLabel>
              <Select
                label="Filter"
                labelId="filter"
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
              >
                <MenuItemV4 value={NONE_FILTER}>None</MenuItemV4>
                <MenuItemV4 value={LINK_FILTER}>Links</MenuItemV4>
                <MenuItemV4 value={DISCUSS_WITH_CLIENT_FILTER}>
                  Discuss with client
                </MenuItemV4>
                <MenuItemV4 value={MAJOR_FILTER}>Major severity</MenuItemV4>
                <MenuItemV4 value={MINOR_FILTER}>Minor severity</MenuItemV4>
                {onboarding.state === REVIEW_STATE ? (
                  <MenuItemV4 value={UNREVIEWED_FILTER}>
                    Not yet reviewed
                  </MenuItemV4>
                ) : null}
                {onboarding.state === REVISION_STATE ? (
                  <MenuItemV4 value={NEEDS_REVIEW_FILTER}>
                    Needs review
                  </MenuItemV4>
                ) : null}
              </Select>
            </FormControl>
            <AddButton
              className={classes.addButton}
              onAddQuestion={handleAddQuestion}
              onAddSignature={handleAddSignature}
              onAddText={handleAddText}
              onAddSection={handleAddSection}
            />
          </div>

          {list()}
        </Grid>
        <Grid item lg={9} md={12}>
          <Editor
            id={activeId}
            activeQuestion={activeQuestion}
            onboarding={onboarding}
            questions={questions}
            saveQuestions={saveQuestions}
            isSaving={isSaving}
            onSave={saveQuestions}
            onDragEnd={onMultiDragEnd}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
            handleClickItem={handleMultiClickItem}
            onDraftFileUploadSuccess={onDraftFileUploadSuccess}
            fundId={fundId}
            questionnaireId={questionnaireId}
            createQuestionNote={createQuestionNote}
            updateQuestionNote={updateQuestionNote}
            deleteQuestionNote={deleteQuestionNote}
            moveQuestionNotes={moveQuestionNotes}
            newNotes={notes}
            reviewedQuestionIds={reviewedQuestionIds}
            setReviewedQuestionIds={setReviewedQuestionIds}
            selectedIds={selectedIds}
            tags={getTagsForQuestion(
              onboarding?.questionToTagsAndType[activeId],
            )}
          />
        </Grid>

        {modal === REVIEW_AND_PUBLISH_MODAL ? (
          <PublishModal
            onClose={() => setModal(null)}
            fundId={fundId}
            questionnaireId={questionnaireId}
            draftQuestionnaireUpdateTime={onboarding?.updatedAt}
            onClosePostPublish={onClosePostPublish}
          />
        ) : null}

        {MAPPING_ENABLED ? (
          <MappingModal
            open={modal === MAPPING_MODAL}
            onClose={() => setModal(null)}
            fundId={fundId}
            organizationId={onboarding?.organizationId}
            questionnaireId={questionnaireId}
            questionnaireName={onboarding?.name}
          />
        ) : null}

        <BulkUpdateInvestorTypeModal
          open={modal === BULK_UPDATE_INVESTOR_TYPE_MODAL}
          selectedIds={selectedIds}
          isSaving={isSaving}
          onClose={() => setModal(null)}
          onSave={(investorType) => {
            handleBulkInvestorTypeUpdate(investorType);
          }}
        />
        <BulkLogicModal
          open={modal === BULK_LOGIC_MODAL}
          selectedIds={selectedIds}
          questions={questions}
          isSaving={isSaving}
          onClose={() => setModal(null)}
          onSave={(bulkLogic) => {
            handleBulkLogicUpdate(bulkLogic);
          }}
        />
        <BulkPageShiftModal
          open={modal === BULK_PAGE_SHIFT_MODAL}
          isSaving={isSaving}
          selectedQuestions={selectedIds.map((id) =>
            questions.find((q) => q.id === id),
          )}
          onClose={() => setModal(null)}
          onSave={(shiftNum, pageRange) =>
            handleBulkPageShift(shiftNum, pageRange)
          }
        />
        <BulkOutputShiftModal
          open={modal === BULK_OUTPUT_SHIFT_MODAL}
          isSaving={isSaving}
          selectedQuestions={questions.filter((q) =>
            selectedIds.includes(q.id),
          )}
          onClose={() => setModal(null)}
          onSave={(x, y) => {
            handleBulkOutputShift(x, y);
          }}
        />
        <CompareTemplateModal
          open={modal === COMPARE_TEMPLATE_MODAL}
          onClose={() => setModal(null)}
          onRefresh={() => getLatestDraftQuestionnaire()}
          fundId={fundId}
          questionnaireId={questionnaireId}
          questionnaireName={onboarding.name}
          templateFundId={onboarding.templateQuestionnaire?.fundId}
          templateQuestionnaireId={onboarding.templateQuestionnaire?.id}
          templateQuestionnaireName={onboarding.templateQuestionnaire?.name}
        />
        <PasteModal
          open={modal === PASTE_MODAL}
          usedIds={questions.map((qData) => qData.id)}
          onSave={pasteQuestions}
          onClose={() => setModal(null)}
        />
        <SettingsModal
          open={modal === QUESTIONNAIRE_SETTINGS_MODAL}
          onClose={() => setModal(null)}
          investorWord={onboarding.investorWord}
          updateSettings={updateSettings}
        />
        <SendModal
          open={!!showSendModal}
          onClose={() => setShowSendModal(null)}
          fundId={fundId}
          questionnaireId={questionnaireId}
          saveState={saveState}
          questions={questions}
          reviewedQuestionIds={reviewedQuestionIds}
          currentState={onboarding.state}
          nextState={showSendModal}
          notes={notes}
          hasUnpublishedChanges={showUnpublishedChangesBadge}
          totalTimeInSeconds={totalTimeInSeconds}
          saving={isSavingTime}
        />
      </Grid>
    </>
  );
}
