import React from 'react';
import { Modal } from '@passthrough/uikit';

import { NameForm } from './name_form';

export function NameModal({
  open,
  onClose,
  firstName,
  setFirstName,
  firstNameError,
  lastName,
  setLastName,
  lastNameError,
  onSubmit,
  saving,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Edit name"
      onSubmit={onSubmit}
      primaryButtonLoading={saving}
      primaryButtonText="Save"
      showCancelButton
    >
      <NameForm
        firstName={firstName}
        setFirstName={setFirstName}
        firstNameError={firstNameError}
        lastName={lastName}
        setLastName={setLastName}
        lastNameError={lastNameError}
      />
    </Modal>
  );
}
