import React, { useState } from 'react';
import { Alert, Modal, useConfirm, Typography } from '@passthrough/uikit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

import { objectEquals } from 'services/utils';
import { NameList } from 'components/lp_doc/diligence/components/name_list';
import { genOwnerErrors } from 'components/lp_doc/diligence/utils';
import { SingleChildTypeDisplay } from '../owner_data_approval/children_display/single_child_type_display';
import { getChildrenSubTree } from './utils';

const EDIT_STAGE = 0;
const REVIEW_STAGE = 1;

const useStyles = makeStyles((theme) => ({
  allOwners: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
    margin: theme.spacing(0),
  },
}));

function getAllOwnerIds(ownerAnswers) {
  return Object.values(ownerAnswers).reduce(
    (acc, val) => acc.concat(val.map((o) => o.id)),
    [],
  );
}

function getOriginalNodeIds(ownerAnswers) {
  return Object.values(ownerAnswers).reduce(
    (acc, val) => acc.concat(val.filter((o) => !o.idToReuse).map((o) => o.id)),
    [],
  );
}

function EditStageContent({
  jurisdiction,
  ownerFieldData,
  ownerAnswers,
  ownerErrors,
  onChangeOwners,
  initialOwnerAnswers,
  diligenceQuestions,
  deletedNames,
  setDeletedNames,
  questionId,
}) {
  const classes = useStyles();
  return (
    <ol className={classes.allOwners}>
      {ownerFieldData.map((owner) => (
        <li key={owner.key}>
          <NameList
            key={owner.key}
            names={ownerAnswers[owner.key]}
            namesToDisableEdit={initialOwnerAnswers?.[owner.key] || []}
            namesError={ownerErrors[owner.key]}
            diligenceQuestions={diligenceQuestions}
            onChange={(l) => {
              onChangeOwners(owner.key, l);
            }}
            jurisdiction={jurisdiction}
            formLabel={owner.pluralLabel}
            ownerType={owner.singularLabel}
            helpText={owner.helpText}
            deletedNames={deletedNames}
            setDeletedNames={setDeletedNames}
            label={questionId}
            confirmDeletions={false}
          />
        </li>
      ))}
    </ol>
  );
}

function ReviewStageContent({
  ownerAnswers,
  initialOwnerAnswers,
  ownerFieldData,
  structuredNodesRoot,
}) {
  const initialOwnerIds = getAllOwnerIds(initialOwnerAnswers);
  const newOwnerIds = getAllOwnerIds(ownerAnswers);

  const deletedNodeIds = initialOwnerIds.filter(
    (id) => !newOwnerIds.includes(id),
  );

  return (
    <>
      {deletedNodeIds.length > 0 ? (
        <Alert severity="warning">
          You will lose all details, documents, and comments belonging to the
          owners that you are about to delete. This action cannot be undone.
        </Alert>
      ) : null}

      {deletedNodeIds.map((id) => {
        const nodeToDelete = structuredNodesRoot?.findNodeByLabel(id);
        const nodesThatWillAlsoBeDeleted = getChildrenSubTree(
          id,
          structuredNodesRoot,
        );
        if (nodesThatWillAlsoBeDeleted.length === 0) {
          return null;
        }

        return (
          <Alert key={id} severity="warning" skipTypography>
            By deleting {nodeToDelete.question?.answer?.name}, the following
            individuals/entities will also be deleted:
            <ul>
              {nodesThatWillAlsoBeDeleted.map((childData) => (
                <li key={childData.id}>
                  {childData.name} ({childData.type})
                </li>
              ))}
            </ul>
          </Alert>
        );
      })}

      <Typography>
        New owners (excluding joint accounts and IRAs) will be screened
        immediately.
      </Typography>

      {ownerFieldData.map((ownerConfig) => (
        <SingleChildTypeDisplay
          key={ownerConfig.key}
          typeLabel={ownerConfig.pluralLabel}
          currChildren={ownerAnswers[ownerConfig.key]}
          lastChildren={initialOwnerAnswers?.[ownerConfig.key]}
        />
      ))}

      <Typography>
        Your changes are visible to the investor but they will not be notified.
      </Typography>
    </>
  );
}

export function EditDiligenceOwnersModal({
  open,
  jurisdiction,
  initialOwnerAnswers,
  structuredNodesRoot,
  ownerFieldData,
  onClose,
  onSubmit,
  diligenceQuestions,
  questionId,
}) {
  const [stage, setStage] = useState(EDIT_STAGE);
  const [ownerAnswers, setOwnerAnswers] = useState(initialOwnerAnswers);
  const [ownerErrors, setOwnerErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();

  const initialOriginalNodeIds = getOriginalNodeIds(initialOwnerAnswers);
  const originalNodeIds = getOriginalNodeIds(ownerAnswers);
  const newNodes = originalNodeIds.filter(
    (id) => !initialOriginalNodeIds.includes(id),
  );

  const areAnswersChanged = !objectEquals(initialOwnerAnswers, ownerAnswers);

  const [deletedNames, setDeletedNames] = useState([]);

  return (
    <Modal
      open={open}
      headerLabel="Edit Owners"
      onEntering={() => {
        setOwnerAnswers(initialOwnerAnswers);
        setStage(EDIT_STAGE);
        setOwnerErrors({});
        setLoading(false);
      }}
      onClose={() => {
        if (areAnswersChanged) {
          confirm({
            title: 'Discard unsaved changes?',
            description: 'Your changes have not been saved.',
            destructive: true,
            confirmationText: 'Close',
            size: 'xs',
          })
            .then(() => {
              setDeletedNames([]);
              onClose();
            })
            .catch(() => {});
        } else {
          onClose();
        }
      }}
      showCancelButton
      onBack={
        stage === REVIEW_STAGE
          ? () => {
              setStage(EDIT_STAGE);
            }
          : null
      }
      onSubmit={() => {
        if (stage === EDIT_STAGE) {
          const emptyOwnerInputErrors = genOwnerErrors(ownerAnswers);
          if (Object.keys(emptyOwnerInputErrors).length > 0) {
            setOwnerErrors(emptyOwnerInputErrors);
            return;
          }

          setStage(REVIEW_STAGE);
        } else {
          setLoading(true);

          onSubmit(ownerAnswers, newNodes.length).finally(() => {
            setLoading(false);
          });
        }
      }}
      primaryButtonText={stage === EDIT_STAGE ? 'Next' : 'Save'}
      primaryButtonEndIcon={stage === EDIT_STAGE ? <ArrowForwardIcon /> : null}
      primaryButtonDisabled={stage === EDIT_STAGE && !areAnswersChanged}
      primaryButtonLoading={loading}
    >
      {stage === EDIT_STAGE ? (
        <EditStageContent
          jurisdiction={jurisdiction}
          ownerFieldData={ownerFieldData}
          ownerAnswers={ownerAnswers}
          ownerErrors={ownerErrors}
          onChangeOwners={(key, l) => {
            setOwnerAnswers((oa) => ({ ...oa, [key]: l }));
          }}
          initialOwnerAnswers={initialOwnerAnswers}
          diligenceQuestions={diligenceQuestions}
          deletedNames={deletedNames}
          setDeletedNames={setDeletedNames}
          questionId={questionId}
        />
      ) : (
        <ReviewStageContent
          ownerAnswers={ownerAnswers}
          initialOwnerAnswers={initialOwnerAnswers}
          ownerFieldData={ownerFieldData}
          structuredNodesRoot={structuredNodesRoot}
        />
      )}
    </Modal>
  );
}
