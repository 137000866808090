import * as React from 'react';

import {
  Button,
  ClickableChip,
  Icons,
  Modal,
  Typography,
} from '@passthrough/uikit';
import { DiligenceContext } from 'components/diligence_provider';
import { DataValueDisplay } from 'components/data_value_display';
import { makeStyles } from '@material-ui/core';
import { GenericAnswerDisplay } from './generic_display';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export function DiligenceLegalName({ answer, flatten, questionState }) {
  const [open, setOpen] = React.useState(false);
  const { subdocLegalName, subdocReviewUrl } =
    React.useContext(DiligenceContext);

  const classes = useStyles();
  if (
    !subdocLegalName ||
    subdocLegalName.toLowerCase() === answer.toLowerCase()
  ) {
    return (
      <GenericAnswerDisplay
        answer={answer}
        flatten={flatten}
        questionState={questionState}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography color="warning.text">{answer}</Typography>
      <ClickableChip
        variant="warning"
        size="small"
        onClick={() => setOpen(true)}
        label="Mismatch"
      />
      <Modal
        open={open}
        headerLabel="Legal name mismatch"
        onClose={() => setOpen(false)}
        onSubmit={() => setOpen(false)}
        primaryButtonText="Close"
        primaryButtonProps={{
          variant: 'secondary',
        }}
        tertiaryButton={
          <Button
            variant="text"
            startIcon={<Icons.OpenInNewOutlined color="primary" />}
            href={subdocReviewUrl}
          >
            View questionnaire
          </Button>
        }
      >
        The investor has provided a different legal name in one of the
        submissions.
        <DataValueDisplay
          label="Full legal name submitted for diligence"
          value={<Typography color="warning.text">{answer}</Typography>}
        />
        <DataValueDisplay
          label="Full legal name submitted for questionnaire"
          value={
            <Typography color="warning.text">{subdocLegalName}</Typography>
          }
        />
      </Modal>
    </div>
  );
}
