import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ContentCopy from 'mdi-material-ui/ContentCopy';
import ContentPaste from 'mdi-material-ui/ContentPaste';
import { Button } from 'components/button';
import { CountryInput } from 'components/country';
import { StateInput } from 'components/state';
import clsx from 'clsx';
import { Alert } from 'components/alert';
import { Icons, Modal } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { getRelevantQuestions } from './common/helpers';
import {
  DEFAULT_OPERATORS,
  YES_NO_LOGIC_TYPES,
  COUNTRY_LOGIC_TYPES,
  STATE_LOGIC_TYPES,
  NUMBER_LOGIC_TYPES,
  EMAIL_TYPES,
} from './constants';

const FULL_WIDTH_PLUS = String.fromCharCode(65291);

function spliceOp(op) {
  /* Splice the operation between every element, and give the
   * elements an index attribute so they can remember where they
   * actually fit in.
   */
  function reducer(l, e, index) {
    if (!l.length) return [{ ...e, index }];
    return [...l, { type: 'string', value: op }, { ...e, index }];
  }
  return reducer;
}

const useStyles = makeStyles((theme) => ({
  group: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),
  },
  groupOp: {
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  op: {
    marginLeft: theme.spacing(1),
  },
  fragmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fragments: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  addTopMargin: {
    marginTop: theme.spacing(2),
  },
  row: {
    width: `calc(100% - ${theme.spacing(1)}px)`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    marginRight: theme.spacing(1),
  },
  addButtons: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
}));

function replace(arr, index, value) {
  const newArr = [...arr];
  newArr.splice(index, 1, value);
  return newArr;
}

function remove(arr, index) {
  const newArr = [...arr];
  newArr.splice(index, 1);
  return newArr;
}

function getEmptyGroup() {
  return { type: 'group', op: 'OR', children: [] };
}

function getEmptyCondition() {
  return { type: 'condition', choice: '', questionId: '' };
}

function getEmptyMultiCondition() {
  return {
    type: 'multi_condition',
    multiOp: '',
    questionIds: [''],
    op: 'number_eq',
    answer: '',
  };
}

const IS_COMPARED_TO_ANSWER_MULTI_OP_TYPES = ['sum_to_answer'];

function ChoiceRHS({ question, value, onChange }) {
  if (!question) {
    return null;
  }

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Is</InputLabel>
        <Select
          label="Is"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="eq">Equal</MenuItem>
          <MenuItem value="neq">No answer or not equal</MenuItem>
          <MenuItem value="nneq">Answered and not equal</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-choice">Choice</InputLabel>
        <Select
          label="Choice"
          labelId="depends-on-choice"
          value={value.choice}
          onChange={(e) =>
            onChange({ ...value, choice: e.target.value, answer: null })
          }
        >
          {question.choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.text.substring(0, 100)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

function MultiSelectRHS({ question, value, onChange }) {
  if (!question) {
    return null;
  }

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Is</InputLabel>
        <Select
          label="Is"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="contains">Contains</MenuItem>
          <MenuItem value="ncontains">
            No answer or answer does not contain
          </MenuItem>
          <MenuItem value="nncontains">
            Answered and answer does not contain
          </MenuItem>
          <MenuItem value="ocontains">Only contain</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-choice">Choice</InputLabel>
        <Select
          label="Choice"
          labelId="depends-on-choice"
          value={value.choice}
          onChange={(e) =>
            onChange({ ...value, choice: e.target.value, answer: null })
          }
        >
          {question.choices.map((choice) => (
            <MenuItem value={choice.id}>
              {choice.text.substring(0, 100)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

function YesNoRHS({ question, value, onChange }) {
  if (!question) {
    return null;
  }

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-answer">Answer</InputLabel>
        <Select
          label="Answer"
          labelId="depends-on-operator"
          value={String(value.answer)}
          onChange={(e) =>
            onChange({
              ...value,
              answer: e.target.value === 'true',
              choice: null,
            })
          }
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

function getCountryLogicInputLabel(answerType) {
  if (answerType === 'contact_with_nationality') {
    return 'Nationality is';
  }

  if (COUNTRY_LOGIC_TYPES.includes(answerType)) {
    return 'Country is';
  }

  return 'is';
}

function CountryRHS({ question, value, onChange }) {
  if (!question) {
    return null;
  }
  const label = getCountryLogicInputLabel(question.answerType);

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">{label}</InputLabel>
        <Select
          label={label}
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="eq">Equal</MenuItem>
          <MenuItem value="neq">Not equal</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <CountryInput
          frontendLabel="Answer"
          value={value.answer}
          handleChange={(e, v) =>
            onChange({ ...value, answer: v?.code, choice: null })
          }
        />
      </FormControl>
    </>
  );
}

function StateRHS({ question, value, onChange }) {
  if (!question) {
    return null;
  }
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Is</InputLabel>
        <Select
          label="Is"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="eq">Equal</MenuItem>
          <MenuItem value="neq">Not equal</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <StateInput
          frontendLabel="Answer"
          value={value.answer}
          handleChange={(e, v) => {
            onChange({ ...value, answer: v?.label, choice: null });
          }}
        />
      </FormControl>
    </>
  );
}

function NumberRHS({ value, onChange }) {
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Is</InputLabel>
        <Select
          label="Is"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="number_eq">Equal to</MenuItem>
          <MenuItem value="number_neq">Not equal to</MenuItem>
          <MenuItem value="number_gte">Greater than or equal to</MenuItem>
          <MenuItem value="number_gt">Greater than</MenuItem>
          <MenuItem value="number_lte">Less than or equal to</MenuItem>
          <MenuItem value="number_lt">Less than</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="text"
        type="number"
        label="Number"
        InputProps={{ inputProps: { min: 0 } }}
        variant="outlined"
        value={value.answer}
        onChange={(e) =>
          onChange({ ...value, answer: e.target.value, choice: null })
        }
        fullWidth
      />
    </>
  );
}

function NumberAnswerRHS({
  value,
  onChange,
  relevantQuestions,
  handleClickItem,
}) {
  const question = relevantQuestions.find((q) => q.id === value.answer);
  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Is</InputLabel>
        <Select
          label="Is"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="number_eq">Equal to</MenuItem>
          <MenuItem value="number_neq">Not equal to</MenuItem>
          <MenuItem value="number_gte">Greater than or equal to</MenuItem>
          <MenuItem value="number_gt">Greater than</MenuItem>
          <MenuItem value="number_lte">Less than or equal to</MenuItem>
          <MenuItem value="number_lt">Less than</MenuItem>
        </Select>
      </FormControl>

      {question && handleClickItem ? (
        <IconButton
          aria-label="find"
          onClick={() => {
            handleClickItem(question);
          }}
        >
          <FindInPageIcon />
        </IconButton>
      ) : null}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="answer-to">Answer to</InputLabel>
        <Select
          label="Answer to"
          labelId="answer-to"
          value={value.answer}
          onChange={(e) => onChange({ ...value, answer: e.target.value })}
        >
          {relevantQuestions.map((q) =>
            q.sectionName ? (
              <ListSubheader key={q.sectionName}>{q.sectionName}</ListSubheader>
            ) : (
              <MenuItem key={q.id} value={q.id}>
                {q.id}
                {': '}
                {q.question.substring(0, 50)}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
    </>
  );
}

function QueryCondition({
  questionIds,
  questions,
  value,
  onChange,
  onDelete,
  handleClickItem,
  showThisAndFutureQuestions,
}) {
  const classes = useStyles();

  const question = value.questionId
    ? questions.find((q) => q.id === value.questionId)
    : null;

  const updateDependsOn = (e) => {
    const dependsOn = questions.find((q) => q.id === e.target.value);
    const defaultOperator = DEFAULT_OPERATORS[dependsOn?.answerType];
    onChange({ ...value, questionId: e.target.value, op: defaultOperator });
  };

  const relevantQuestions = getRelevantQuestions(
    questions,
    questionIds,
    showThisAndFutureQuestions,
  );

  return (
    <div className={classes.row}>
      {question && handleClickItem ? (
        <IconButton
          aria-label="find"
          onClick={() => {
            handleClickItem(question);
          }}
        >
          <FindInPageIcon />
        </IconButton>
      ) : null}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on">Depends on</InputLabel>
        <Select
          label="Depends on"
          labelId="depends-on"
          value={value.questionId}
          onChange={updateDependsOn}
        >
          {relevantQuestions.map((q) =>
            q.sectionName ? (
              <ListSubheader key={q.sectionName}>{q.sectionName}</ListSubheader>
            ) : (
              <MenuItem key={q.id} value={q.id}>
                {q.id}
                {': '}
                {q.question.substring(0, 50)}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>

      {question && question.answerType === 'choice' ? (
        <ChoiceRHS
          question={question}
          value={value}
          onChange={(v) => onChange(v)}
        />
      ) : null}

      {question && question.answerType === 'multi_select' ? (
        <MultiSelectRHS
          question={question}
          value={value}
          onChange={(v) => onChange(v)}
        />
      ) : null}

      {question && YES_NO_LOGIC_TYPES.includes(question.answerType) ? (
        <YesNoRHS
          question={question}
          value={value}
          onChange={(v) => onChange(v)}
        />
      ) : null}

      {question && COUNTRY_LOGIC_TYPES.includes(question.answerType) ? (
        <CountryRHS
          question={question}
          value={value}
          onChange={(v) => onChange(v)}
        />
      ) : null}

      {question && STATE_LOGIC_TYPES.includes(question.answerType) ? (
        <StateRHS
          question={question}
          value={value}
          onChange={(v) => onChange(v)}
        />
      ) : null}

      {question && NUMBER_LOGIC_TYPES.includes(question.answerType) ? (
        <NumberRHS value={value} onChange={(v) => onChange(v)} />
      ) : null}

      <IconButton aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

function SumConditionRow({
  questionId,
  updateAnswerTo,
  relevantQuestions,
  addRow,
  deleteRow,
  handleClickItem,
}) {
  const classes = useStyles();
  const question = relevantQuestions.find((q) => q.id === questionId);
  return (
    <div className={classes.row}>
      {question && handleClickItem ? (
        <IconButton
          aria-label="find"
          onClick={() => {
            handleClickItem(question);
          }}
        >
          <FindInPageIcon />
        </IconButton>
      ) : null}
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="answer-to">Answer to</InputLabel>
        <Select
          label="Answer to"
          labelId="answer-to"
          value={questionId}
          onChange={updateAnswerTo}
        >
          {relevantQuestions.map((q) =>
            q.sectionName ? (
              <ListSubheader key={q.sectionName}>{q.sectionName}</ListSubheader>
            ) : (
              <MenuItem key={q.id} value={q.id}>
                {q.id}
                {': '}
                {q.question.substring(0, 50)}
              </MenuItem>
            ),
          )}
        </Select>
      </FormControl>
      {addRow ? (
        <IconButton aria-label="add" onClick={addRow}>
          <AddIcon />
        </IconButton>
      ) : null}
      {deleteRow ? (
        <IconButton aria-label="delete" onClick={deleteRow}>
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

function NumberConditionMixedAnswerTypeWarning({
  usedQuestionIds,
  relevantQuestions,
}) {
  const selectedQuestions = relevantQuestions.filter((q) =>
    usedQuestionIds.includes(q.id),
  );
  const selectedAnswerTypes = new Set(
    selectedQuestions.map((q) => q.answerType),
  );
  if (selectedAnswerTypes.size <= 1) {
    return null;
  }
  return (
    <Alert severity="warning">
      Are you sure you want to combine questions with different answer types?{' '}
      {selectedQuestions.map((q) => `${q.id}: ${q.answerType}`).join(', ')}
    </Alert>
  );
}

function CompareEmailMultiCondition({
  questions,
  questionIds,
  value,
  onChange,
  handleClickItem,
  showThisAndFutureQuestions,
}) {
  const classes = useStyles();

  const relevantQuestions = getRelevantQuestions(
    questions,
    questionIds,
    showThisAndFutureQuestions,
    EMAIL_TYPES,
  );

  const allQuestionIdsUsedInMultiCondition = value.questionIds;

  return (
    <div className={classes.group}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="depends-on-operator">Operation</InputLabel>
        <Select
          label="Operation"
          labelId="depends-on-operator"
          value={value.op}
          onChange={(e) => onChange({ ...value, op: e.target.value })}
        >
          <MenuItem value="eq">Equal</MenuItem>
          <MenuItem value="neq">No answer or not equal</MenuItem>
          <MenuItem value="nneq">Answered and not equal</MenuItem>
        </Select>
      </FormControl>
      <div className={clsx(classes.fragmentContainer, classes.addTopMargin)}>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.fragments}>
          {value.questionIds.map((combinedQuestionId, index) => {
            const isLastRow = index === value.questionIds.length - 1;
            const addRow = isLastRow
              ? () =>
                  onChange({
                    ...value,
                    questionIds: [...value.questionIds, ''],
                  })
              : null;
            const deleteRow = () =>
              value.questionIds.length > 1
                ? onChange({
                    ...value,
                    questionIds: remove(value.questionIds, index),
                  })
                : onChange({
                    ...value,
                    questionIds: [''],
                  });
            const questionsExcludingOtherSelected = relevantQuestions.filter(
              (q) =>
                !allQuestionIdsUsedInMultiCondition.includes(q.id) ||
                combinedQuestionId === q.id,
            );
            return (
              <>
                <SumConditionRow
                  questionId={combinedQuestionId}
                  updateAnswerTo={(e) => {
                    onChange({
                      ...value,
                      questionIds: replace(
                        value.questionIds,
                        index,
                        e.target.value,
                      ),
                    });
                  }}
                  relevantQuestions={questionsExcludingOtherSelected}
                  addRow={addRow}
                  deleteRow={deleteRow}
                  handleClickItem={handleClickItem}
                />
                {!isLastRow ? (
                  <Typography className={classes.op}>
                    {value.op === 'eq' ? '=' : '!='}
                  </Typography>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function NumberMultiCondition({
  questions,
  questionIds,
  value,
  onChange,
  handleClickItem,
  showThisAndFutureQuestions,
  isComparedToAnswer,
}) {
  const classes = useStyles();

  const relevantQuestions = getRelevantQuestions(
    questions,
    questionIds,
    showThisAndFutureQuestions,
    NUMBER_LOGIC_TYPES,
  );

  const allQuestionIdsUsedInMultiCondition = isComparedToAnswer
    ? value.questionIds + [value.answer]
    : value.questionIds;

  return (
    <div className={classes.group}>
      <NumberConditionMixedAnswerTypeWarning
        usedQuestionIds={allQuestionIdsUsedInMultiCondition}
        relevantQuestions={relevantQuestions}
      />
      <div className={clsx(classes.fragmentContainer, classes.addTopMargin)}>
        <Divider orientation="vertical" className={classes.divider} />
        <div className={classes.fragments}>
          {value.questionIds.map((combinedQuestionId, index) => {
            const isLastRow = index === value.questionIds.length - 1;
            const addRow = isLastRow
              ? () =>
                  onChange({
                    ...value,
                    questionIds: [...value.questionIds, ''],
                  })
              : null;
            const deleteRow =
              value.questionIds.length > 1
                ? () =>
                    onChange({
                      ...value,
                      questionIds: remove(value.questionIds, index),
                    })
                : null;
            const questionsExcludingOtherSelected = relevantQuestions.filter(
              (q) =>
                !allQuestionIdsUsedInMultiCondition.includes(q.id) ||
                combinedQuestionId === q.id,
            );
            return (
              <>
                {/* Currently only Sum and Sum to Answer are supported. */}
                {/* SumConditionRow and FULL_WIDTH_PLUS should be parameters */}
                {/* If non-summation multi conditions are added */}
                <SumConditionRow
                  questionId={combinedQuestionId}
                  updateAnswerTo={(e) => {
                    onChange({
                      ...value,
                      questionIds: replace(
                        value.questionIds,
                        index,
                        e.target.value,
                      ),
                    });
                  }}
                  relevantQuestions={questionsExcludingOtherSelected}
                  addRow={addRow}
                  deleteRow={deleteRow}
                  handleClickItem={handleClickItem}
                />
                {!isLastRow ? (
                  <Typography className={classes.op}>
                    {FULL_WIDTH_PLUS}
                  </Typography>
                ) : null}
              </>
            );
          })}
          <div className={clsx(classes.row, classes.addTopMargin)}>
            {isComparedToAnswer ? (
              <NumberAnswerRHS
                value={value}
                onChange={onChange}
                relevantQuestions={relevantQuestions.filter(
                  (q) => !value.questionIds.includes(q.id),
                )}
                handleClickItem={handleClickItem}
              />
            ) : (
              <NumberRHS value={value} onChange={onChange} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function isConditionJsonValid(parsedConditions) {
  try {
    if (parsedConditions.type !== 'group') {
      return false;
    }
    if (!['AND', 'OR'].includes(parsedConditions.op)) {
      return false;
    }
    const childrenValid = parsedConditions.children.every((child) => {
      if (child.type === 'condition') {
        return true;
      }
      if (child.type === 'multi_condition') {
        return true;
      }
      if (child.type === 'group') {
        return isConditionJsonValid(child);
      }
      return false;
    });
    return childrenValid;
  } catch (e) {
    return false;
  }
}

function QueryGroup({
  questionIds,
  value,
  onChange,
  onDelete,
  questions,
  handleClickItem,
  showThisAndFutureQuestions,
  isCopyPasteModal = false,
}) {
  const classes = useStyles();
  const { toast, errorToast } = useToast();
  const [showPasteConditionModal, setShowPasteConditionModal] = useState(false);
  const [conditionsToPaste, setConditionsToPaste] = useState(null);
  const copyQuestions = () => {
    const conditionJSON = JSON.stringify(value);
    navigator.clipboard.writeText(conditionJSON);
    toast('Copied conditions to clipboard');
  };
  const pasteQuestions = () => {
    navigator.clipboard.readText().then((conditionJSON) => {
      try {
        const parsedConditions = JSON.parse(conditionJSON);
        if (!isConditionJsonValid(parsedConditions)) {
          throw new Error('Invalid condition JSON');
        }
        setConditionsToPaste(parsedConditions);
        setShowPasteConditionModal(true);
      } catch (e) {
        setConditionsToPaste(null);
        setShowPasteConditionModal(false);
        errorToast('Cannot read condition data');
      }
    });
  };
  let invalidQuestionIds = [];
  if (conditionsToPaste) {
    const relevantQuestions = getRelevantQuestions(
      questions,
      questionIds,
      showThisAndFutureQuestions,
    );
    const getInvalidQuestionIds = (condition) => {
      if (condition.type === 'condition') {
        return relevantQuestions.some((q) => q.id === condition.questionId)
          ? []
          : [condition.questionId];
      }
      if (condition.type === 'multi_condition') {
        const usedQuestionIds = IS_COMPARED_TO_ANSWER_MULTI_OP_TYPES.includes(
          condition.multiOp,
        )
          ? [...condition.questionIds, condition.answer]
          : condition.questionIds;
        return usedQuestionIds.filter(
          (qId) =>
            !relevantQuestions.some(
              (q) => q.id === qId && NUMBER_LOGIC_TYPES.includes(q.answerType),
            ),
        );
      }
      if (condition.type === 'group') {
        return condition.children.reduce(
          (qIds, childCondition) => [
            ...qIds,
            ...getInvalidQuestionIds(childCondition),
          ],
          [],
        );
      }
      return [];
    };
    invalidQuestionIds = getInvalidQuestionIds(conditionsToPaste);
  }

  return (
    <div className={classes.group}>
      <div className={classes.groupOp}>
        <div className={classes.row}>
          <div>
            <ToggleButtonGroup
              value={value.op}
              exclusive
              onChange={(e, v) => {
                if (v !== null) {
                  onChange({ ...value, op: v });
                }
              }}
            >
              <ToggleButton value="AND" aria-label="and">
                And
              </ToggleButton>
              <ToggleButton value="OR" aria-label="or">
                Or
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton
              aria-label="delete"
              onClick={() => {
                onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          {isCopyPasteModal ? null : (
            <div>
              <IconButton aria-label="copy" onClick={copyQuestions}>
                <ContentCopy />
              </IconButton>
              <IconButton aria-label="paste" onClick={pasteQuestions}>
                <ContentPaste />
              </IconButton>
            </div>
          )}
        </div>
      </div>

      <div className={classes.fragmentContainer}>
        <Divider orientation="vertical" className={classes.divider} />

        <div className={classes.fragments}>
          {value.children.reduce(spliceOp(value.op), []).map((exp) => {
            const { index } = exp;
            if (exp.type === 'string') {
              return (
                <Typography className={classes.op}>{exp.value}</Typography>
              );
            }
            if (exp.type === 'group') {
              return (
                <QueryGroup
                  questionIds={questionIds}
                  value={exp}
                  onChange={(v) => {
                    onChange({
                      ...value,
                      children: replace(value.children, index, v),
                    });
                  }}
                  onDelete={() => {
                    onChange({
                      ...value,
                      children: remove(value.children, index),
                    });
                  }}
                  questions={questions}
                  handleClickItem={handleClickItem}
                  showThisAndFutureQuestions={showThisAndFutureQuestions}
                  isCopyPasteModal={isCopyPasteModal}
                />
              );
            }
            if (exp.type === 'condition') {
              return (
                <QueryCondition
                  questionIds={questionIds}
                  value={exp}
                  handleClickItem={handleClickItem}
                  onChange={(v) => {
                    onChange({
                      ...value,
                      children: replace(value.children, index, v),
                    });
                  }}
                  onDelete={() => {
                    onChange({
                      ...value,
                      children: remove(value.children, index),
                    });
                  }}
                  questions={questions}
                  showThisAndFutureQuestions={showThisAndFutureQuestions}
                />
              );
            }
            if (exp.type === 'multi_condition') {
              const props = {
                questions,
                questionIds,
                value: exp,
                onChange: (v) => {
                  onChange({
                    ...value,
                    children: replace(value.children, index, v),
                  });
                },
                handleClickItem,
                showThisAndFutureQuestions,
                isComparedToAnswer:
                  IS_COMPARED_TO_ANSWER_MULTI_OP_TYPES.includes(exp.multiOp),
              };
              const deleteMultiOp = () => {
                onChange({
                  ...value,
                  children: remove(value.children, index),
                });
              };

              const renderMultiOpComponent = () => {
                if (!exp.multiOp) {
                  return null;
                }
                if (exp.multiOp === 'compare_email') {
                  return <CompareEmailMultiCondition {...props} />;
                }
                return <NumberMultiCondition {...props} />;
              };
              return (
                <>
                  <div className={classes.row}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="multi-op">Multi Op</InputLabel>
                      <Select
                        label="Multi Op"
                        labelId="multi-op"
                        value={exp.multiOp}
                        onChange={(e) => {
                          onChange({
                            ...value,
                            children: replace(value.children, index, {
                              ...getEmptyMultiCondition(),
                              multiOp: e.target.value,
                            }),
                          });
                        }}
                      >
                        <MenuItem value="sum">Sum</MenuItem>
                        <MenuItem value="sum_to_answer">Sum to answer</MenuItem>
                        <MenuItem value="compare_email">Compare Email</MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton aria-label="delete" onClick={deleteMultiOp}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  {renderMultiOpComponent()}
                </>
              );
            }
            return null;
          })}
          <div className={classes.addButtons}>
            <Button
              onClick={() => {
                onChange({
                  ...value,
                  children: [...value.children, getEmptyGroup()],
                });
              }}
              startIcon={<AccountTreeIcon />}
            >
              Add subgroup
            </Button>
            <Button
              onClick={() => {
                onChange({
                  ...value,
                  children: [...value.children, getEmptyCondition()],
                });
              }}
              startIcon={<StopIcon />}
            >
              Add condition
            </Button>
            <Button
              onClick={() => {
                onChange({
                  ...value,
                  children: [...value.children, getEmptyMultiCondition()],
                });
              }}
              startIcon={<Icons.CalculateOutlined />}
            >
              Add multi condition
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={showPasteConditionModal}
        size="sm"
        headerLabel="Paste conditions"
        onClose={() => setShowPasteConditionModal(false)}
        showCancelButton
        primaryButtonText="Paste"
        primaryButtonDisabled={invalidQuestionIds.length > 0}
        onSubmit={() => {
          onChange(conditionsToPaste);
          setShowPasteConditionModal(false);
        }}
        onExited={() => setConditionsToPaste(null)}
      >
        {invalidQuestionIds.length > 0 ? (
          <Alert severity="error">
            The following question ids are invalid:{' '}
            {invalidQuestionIds.join(', ')}
          </Alert>
        ) : null}
        Do you want to paste the following conditions?
        <QueryGroup
          questionIds={questionIds}
          value={conditionsToPaste}
          onChange={setConditionsToPaste}
          questions={questions}
          showThisAndFutureQuestions={showThisAndFutureQuestions}
          isCopyPasteModal
        />
      </Modal>
    </div>
  );
}

export function QueryBuilder({
  questionIds,
  questions,
  value,
  onChange,
  handleClickItem,
  hideAddCondition,
  showThisAndFutureQuestions,
}) {
  if (value.length === 0) {
    if (hideAddCondition) {
      return null;
    }

    return (
      <Button
        onClick={() => onChange([getEmptyGroup()])}
        startIcon={<AccountTreeIcon />}
      >
        Add conditions
      </Button>
    );
  }

  const group = value[0];

  return (
    <QueryGroup
      questionIds={questionIds}
      value={group}
      onChange={(v) => {
        onChange([v]);
      }}
      onDelete={() => {
        onChange([]);
      }}
      questions={questions}
      handleClickItem={handleClickItem}
      showThisAndFutureQuestions={showThisAndFutureQuestions}
    />
  );
}
