// NODE TYPE GROUPINGS
export const GROUP_NATURAL_PERSONS = 'Natural person(s)';
export const GROUP_TRUST = 'Trust';
export const GROUP_COMPANY = 'Company';
export const GROUP_ORGANIZATION = 'Organization';
export const GROUP_OTHER = 'Other';
// for backwards compatibility with old jurisdictions that don't
// require groupings in the ui
export const GROUP_NONE = 'None';

// NODE TYPES
export const TYPE_INDIVIDUAL = 'Individual';
export const TYPE_LIMITED_PARTNERSHIP = 'Limited Partnership';
export const TYPE_JOINT_ACCOUNT = 'Joint Account';
export const TYPE_IRA = 'IRA';
export const TYPE_PRIVATE_COMPANY = 'Private Company';
export const TYPE_PUBLIC_COMPANY = 'Publicly Traded Company';
export const TYPE_LISTED_COMPANY = 'Listed Company';
export const TYPE_LLC = 'Limited Liability Company';
export const TYPE_TRUST = 'Trust';
export const TYPE_PRIVATE_TRUST = 'Private Trust';
export const TYPE_PENSION_SCHEME = 'Pension Scheme';
export const TYPE_CHARITY_OR_FOUNDATION = 'Charity or Foundation';
export const TYPE_SCHOOL = 'School, College or University';
export const TYPE_SCHOOL_ENDOWMENT =
  'School, college or university (includes endowments)';
export const TYPE_S_CORP = 'S-corp';
export const TYPE_PARTNERSHIP = 'Partnership';
export const TYPE_JOINT_ACCOUNT_OR_TENANCY = 'Joint Account / Joint Tenancy';
export const TYPE_OTHER_PRIVATE_CORPORATION = 'Other Private Corporation';
export const TYPE_PRIVATE_CORPORATION = 'Private Corporation';
export const TYPE_SUBSIDIARY_OR_AFFILIATE =
  'Subsidiary Operating in the U.S. of a U.S. Listed Company';
export const TYPE_SPOUSAL_TRUST = 'Spousal Trust';
export const TYPE_OTHER_TRUST = 'Other Trust';
export const TYPE_PENSION_PLAN = 'Pension Plan';
export const TYPE_NON_PROFIT = 'Non-Profit Organization';
export const TYPE_SOLE_PROPRIETORSHIP = 'Sole Proprietorship';
export const TYPE_NON_PROFIT_ENDOWMENT_OR_FOUNDATION =
  'Non-Profit Organization, Endowment, or Foundation';
export const TYPE_REGULATED_FINANCIAL_INSTITUTION =
  'Regulated Financial Institution';
export const TYPE_NOMINEE = 'Nominee';
export const TYPE_GOV_ENTITY = 'Government Entity';
export const TYPE_PRIVATE_SCHOOL = 'Private School or Private University';
export const TYPE_GOV_BODY = 'Government Body or Supranational Body';
export const TYPE_FOUNDATION = 'Foundation';
export const TYPE_INVESTMENT_FUND = 'Investment Fund';
export const TYPE_CLUBS = 'Club or Society';
export const TYPE_PENSION_FUND = 'Pension Fund';
export const TYPE_FOREIGN_ENTITY = 'Foreign Entity (Non-US)';

export const types = [
  TYPE_INDIVIDUAL,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_IRA,
  TYPE_JOINT_ACCOUNT,
  TYPE_LLC,
  TYPE_LISTED_COMPANY,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_PENSION_SCHEME,
  TYPE_PUBLIC_COMPANY,
  TYPE_PRIVATE_COMPANY,
  TYPE_TRUST,
  TYPE_PRIVATE_TRUST,
  TYPE_SCHOOL,
  TYPE_S_CORP,
  TYPE_PARTNERSHIP,
  TYPE_JOINT_ACCOUNT_OR_TENANCY,
  TYPE_OTHER_PRIVATE_CORPORATION,
  TYPE_PRIVATE_CORPORATION,
  TYPE_SUBSIDIARY_OR_AFFILIATE,
  TYPE_SPOUSAL_TRUST,
  TYPE_OTHER_TRUST,
  TYPE_PENSION_PLAN,
  TYPE_NON_PROFIT,
  TYPE_SOLE_PROPRIETORSHIP,
  TYPE_NON_PROFIT_ENDOWMENT_OR_FOUNDATION,
  TYPE_REGULATED_FINANCIAL_INSTITUTION,
  TYPE_NOMINEE,
  TYPE_GOV_ENTITY,
  TYPE_FOREIGN_ENTITY,
];

export const JOINT_ACCOUNT_TYPES = [
  TYPE_JOINT_ACCOUNT,
  TYPE_JOINT_ACCOUNT_OR_TENANCY,
];

// OWNERS
export const TYPE_BENEFICIARIES = 'beneficiaries';
export const TYPE_HOLDERS = 'holders';
export const TYPE_PARTNERS = 'partners';
export const TYPE_GPS = 'gps';
export const TYPE_DIRECTORS = 'directors';
export const TYPE_BENEFICIAL_OWNERS = 'beneficialOwners';
export const TYPE_ULTIMATE_BENEFICIAL_OWNERS = 'ultimateBeneficialOwner';
export const TYPE_MANAGING_MEMBERS = 'managingMembers';
export const TYPE_MEMBERS = 'members';
export const TYPE_TRUSTEES = 'trustees';
export const TYPE_GRANTORS = 'grantors';
export const TYPE_SIGNATORIES = 'signatories';
export const TYPE_AUTHORIZED_SIGNATORIES = 'authorizedSignatories';
export const TYPE_RECIPIENTS = 'recipients';
export const TYPE_OFFICIALS = 'officials';
export const TYPE_LPS = 'lps';
export const TYPE_NON_MINOR_BENEFICIARIES = 'nonMinorBeneficiaries';
export const TYPE_PRINCIPALS = 'principals';
export const TYPE_THIRD_PARTY_ADMIN = 'thirdPartyAdmin';
export const TYPE_SOLE_PROPRIETOR = 'soleProprietor';
export const TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS =
  'directorsSignatoriesControllingPersons';
export const TYPE_INITIATOR = 'initiator';
export const TYPE_ORIGINATOR = 'originator';
export const TYPE_COUNTERPARTIES = 'counterparties';
export const TYPE_SETTLORS = 'settlors';
export const TYPE_MANAGEMENT_COMPANY = 'managementCompany';
export const TYPE_GRANTORS_SETTLORS = 'grantorsSettlors';
export const TYPE_MANAGERS_CONTROLLING_PERSONS = 'managersControllingPersons';
export const TYPE_MANAGERS = 'managers';
export const TYPE_CIO_COMMITTEE_MEMBERS = 'cioCommitteeMembers';
export const TYPE_PRINCIPAL_OFFICERS = 'principalOfficers';
export const TYPE_LISTED_COMPANY_OWNER = 'listedCompany';
export const TYPE_PROTECTORS = 'protectors';
export const TYPE_ENFORCERS = 'enforcers';

export const ownerTypes = [
  TYPE_BENEFICIARIES,
  TYPE_HOLDERS,
  TYPE_PARTNERS,
  TYPE_GPS,
  TYPE_DIRECTORS,
  TYPE_BENEFICIAL_OWNERS,
  TYPE_ULTIMATE_BENEFICIAL_OWNERS,
  TYPE_MANAGING_MEMBERS,
  TYPE_MEMBERS,
  TYPE_TRUSTEES,
  TYPE_GRANTORS,
  TYPE_SIGNATORIES,
  TYPE_AUTHORIZED_SIGNATORIES,
  TYPE_RECIPIENTS,
  TYPE_OFFICIALS,
  TYPE_LPS,
  TYPE_NON_MINOR_BENEFICIARIES,
  TYPE_PRINCIPALS,
  TYPE_THIRD_PARTY_ADMIN,
  TYPE_SOLE_PROPRIETOR,
  TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS,
  TYPE_INITIATOR,
  TYPE_ORIGINATOR,
  TYPE_COUNTERPARTIES,
  TYPE_SETTLORS,
  TYPE_MANAGEMENT_COMPANY,
  TYPE_GRANTORS_SETTLORS,
  TYPE_MANAGERS_CONTROLLING_PERSONS,
  TYPE_MANAGERS,
  TYPE_CIO_COMMITTEE_MEMBERS,
  TYPE_PRINCIPAL_OFFICERS,
  TYPE_LISTED_COMPANY_OWNER,
];

export const ownerTypesToLabels = {
  [TYPE_BENEFICIARIES]: {
    single: 'Beneficiary',
    plural: 'Beneficiaries',
  },
  [TYPE_HOLDERS]: {
    single: 'Joint account holder',
    plural: 'Joint account holders',
  },
  [TYPE_PARTNERS]: {
    single: 'Partner',
    plural: 'Partners',
  },
  [TYPE_GPS]: {
    single: 'General partner',
    plural: 'General partners',
  },
  [TYPE_DIRECTORS]: {
    single: 'Director/Signatory',
    plural: 'Directors/Signatories',
  },
  [TYPE_BENEFICIAL_OWNERS]: {
    single: 'Beneficial owner',
    plural: 'Beneficial owners',
  },
  [TYPE_ULTIMATE_BENEFICIAL_OWNERS]: {
    single: 'Ultimate beneficial owner',
    plural: 'Ultimate beneficial owners',
  },
  [TYPE_MANAGING_MEMBERS]: {
    single: 'Managing member',
    plural: 'Managing members',
  },
  [TYPE_MEMBERS]: {
    single: 'Member of 25% or more',
    plural: 'Members of 25% or more',
  },
  [TYPE_TRUSTEES]: {
    single: 'Trustee',
    plural: 'Trustees',
  },
  [TYPE_GRANTORS]: {
    single: 'Grantor',
    plural: 'Grantors',
  },
  [TYPE_SIGNATORIES]: {
    single: 'Signatory',
    plural: 'Signatories',
  },
  [TYPE_AUTHORIZED_SIGNATORIES]: {
    single: 'Authorized signatory',
    plural: 'Authorized signatories',
  },
  [TYPE_RECIPIENTS]: {
    single: 'Recipient',
    plural: 'Recipients',
  },
  [TYPE_OFFICIALS]: {
    single: 'Official',
    plural: 'Officials',
  },
  [TYPE_LPS]: {
    single: 'Limited partner',
    plural: 'Limited partners',
  },
  [TYPE_NON_MINOR_BENEFICIARIES]: {
    single: 'Beneficiary over 18 years old',
    plural: 'Beneficiaries over 18 years old',
  },
  [TYPE_PRINCIPALS]: {
    single: 'Principal',
    plural: 'Principals',
  },
  [TYPE_THIRD_PARTY_ADMIN]: {
    single: 'Third party administrator',
    plural: 'Third party administrators',
  },
  [TYPE_SOLE_PROPRIETOR]: {
    single: 'Sole proprietor',
    plural: 'Sole proprietors',
  },
  [TYPE_DIRECTORS_SIGNATORIES_CONTROLLING_PERSONS]: {
    single: 'Director/Signatory/Controlling person',
    plural: 'Directors/Signatories/Controlling persons',
  },
  [TYPE_INITIATOR]: {
    single: 'Initiator',
    plural: 'Initiators',
  },
  [TYPE_ORIGINATOR]: {
    single: 'Originator',
    plural: 'Originators',
  },
  [TYPE_COUNTERPARTIES]: {
    single: 'Counterparty',
    plural: 'Counterparties',
  },
  [TYPE_SETTLORS]: {
    single: 'Settlor',
    plural: 'Settlors',
  },
  [TYPE_MANAGEMENT_COMPANY]: {
    single: 'Management company',
    plural: 'Management companies',
  },
  [TYPE_GRANTORS_SETTLORS]: {
    single: 'Grantor or settlor',
    plural: 'Grantors or settlors',
  },
  [TYPE_MANAGERS_CONTROLLING_PERSONS]: {
    single: 'Manager or controlling person',
    plural: 'Managers or controlling persons',
  },
  [TYPE_MANAGERS]: {
    single: 'Manager',
    plural: 'Managers',
  },
  [TYPE_CIO_COMMITTEE_MEMBERS]: {
    single: 'Chief Investment Officer or investment committee member',
    plural: 'Chief Investment Officer or investment committee members',
  },
  [TYPE_PRINCIPAL_OFFICERS]: {
    single: 'Principal officer',
    plural: 'Principal officers',
  },
  [TYPE_LISTED_COMPANY_OWNER]: {
    single: 'Listed company',
    plural: 'Listed company',
  },
};

export function genOwnerKeyAndLabels(ownerTypeKey) {
  return {
    key: ownerTypeKey,
    singularLabel: ownerTypesToLabels?.[ownerTypeKey].single || '',
    pluralLabel: ownerTypesToLabels?.[ownerTypeKey].plural || '',
  };
}

// DOCUMENTS
export const TYPE_VALID_ID = 'validId';
export const TYPE_PROOF_OF_ADDRESS = 'proofOfAddress';
export const TYPE_LPA = 'lpa';
export const TYPE_COP = 'cop';
export const TYPE_OWNERSHIP_SCHEDULE = 'ownershipSchedule';
export const TYPE_SOB = 'sob';
export const TYPE_AML_LETTER = 'amlLetter';
export const TYPE_COI = 'coi';
export const TYPE_AOA = 'aoa';
export const TYPE_REGISTER_OF_DIRECTORS = 'registerOfDirectors';
export const TYPE_REGISTER_OF_SHAREHOLDERS = 'registerOfShareholders';
export const TYPE_EVIDENCE_OF_LISTING = 'evidenceOfListing';
export const TYPE_LETTER_FROM_EXTERNAL_AUDITOR = 'letterFromExternalAuditor';
export const TYPE_ORG_CHART = 'orgChart';
export const TYPE_COF = 'cof';
export const TYPE_OPERATING_AGREEMENT = 'operatingAgreement';
export const TYPE_DOM = 'dom';
export const TYPE_TRUST_DEED = 'trustDeed';
export const TYPE_CERTIFICATE_OF_TRUST = 'certificateOfTrust';
export const TYPE_SCHEDULE_OF_TRUSTEES = 'scheduleOfTrustees';
export const TYPE_FORMATION_DOCUMENT = 'formationDocument';
export const TYPE_COPOC = 'copoc';
export const TYPE_COAS = 'coas';
export const TYPE_AUDITED_FINANCIALS = 'auditedFinancials';
export const TYPE_DONORS_OVER_1OK = 'donorsOver10k';
export const TYPE_CONFIRMATION_OF_OWNERSHIP = 'confirmationOfOwnership';
export const TYPE_DETAILS_OF_MAIN_OFFICIALS = 'detailsOfMainOfficials';
export const TYPE_SCHEDULE_OF_MAJOR_DONORS = 'scheduleOfMajorDonors';
export const TYPE_COR = 'cor';
export const TYPE_PARTNERSHIP_AGREEMENT = 'partnershipAgreement';
export const TYPE_STRUCTURE_CHART = 'structureChart';
export const TYPE_ASL = 'asl';
// CAYMAN specific docs
export const TYPE_PROOF_SOURCE_OF_FUNDS = 'proofSourceOfFunds';
export const TYPE_CERT_COR = 'certCor';
export const TYPE_CERT_AFS = 'certAfs';
export const TYPE_CERT_LPA = 'certLpa';
export const TYPE_CERT_STRUCTURE_CHART = 'certStructureChart';
export const TYPE_CERT_SHAREHOLDER_REGISTRY = 'certShareholderRegistry';
export const TYPE_CERT_DIRECTOR_REGISTER = 'certDirectorRegister';
export const TYPE_CERT_ASL = 'certAsl';
export const TYPE_CERT_COI = 'certCoi';
export const TYPE_CERT_AOA = 'certAoa';
export const TYPE_CERT_AOI = 'certAoi';
export const TYPE_CERT_COF = 'certCof';
export const TYPE_CERT_PARTNERSHIP_AGREEMENT = 'certPartnershipAgreement';
export const TYPE_CERT_TRUST_DEED = 'certTrustDeed';
export const TYPE_CERT_TRUST_AGREEMENT = 'certTrustAgreement';
export const TYPE_CERT_AML_LETTER = 'certAmlLetter';
export const TYPE_CERT_REGISTER_OF_DIRECTORS = 'certRegisterOfDirectors';
export const TYPE_CERT_REGISTER_OF_SHAREHOLDERS = 'certRegisterOfShareholders';
export const TYPE_CERT_ORG_CHART = 'certOrgChart';
export const TYPE_CERT_OPERATING_AGREEMENT = 'certOperatingAgreement';
export const TYPE_CERT_DOM = 'certDom';
export const TYPE_CERT_SCHEDULE_OF_TRUSTEES = 'certScheduleOfTrustees';
export const TYPE_CERT_FORMATION_DOCUMENT = 'certFormationDocument';
export const TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS = 'certScheduleOfMajorDonors';
export const TYPE_CERT_SOB = 'certSob';
export const TYPE_CERT_CERTIFICATE_OF_TRUST = 'certCertificateOfTrust';
export const TYPE_CERT_DONORS_OVER_1OK = 'certDonorsOver10k';
// USA V2 docs
export const TYPE_LIST_ACCOUNT_OWNERS = 'listAccountOwners';
export const TYPE_SEC_FILING = 'secFiling';
export const TYPE_TRUST_AGREEMENT = 'trustAgreement';
export const TYPE_CERTIFICATE_OF_INCUMBENCY = 'certificateOfIncumbency';
export const TYPE_STATEMENT_OF_FINANCIAL_CRIMES_COMPLIANCE =
  'statementOfFinancialCrimesCompliance';
export const TYPE_PENSION_PLAN_DOCUMENT = 'pensionPlanDocument';
export const TYPE_COPY_OF_BUSINESS_LICENSES = 'copyOfBusinessLicenses';
export const TYPE_PARTNERSHIP_AGREEMENT_OR_COPY_OF_BUSINESS_LICENSES =
  'partnershipAgreementOrCopyOfBusinessLicenses';
export const TYPE_PARTICIPANT_STATEMENT_REPORT_OR_QUARTERLY_ACCT =
  'participantStatementReportOrQuarterlyAcct';
export const TYPE_COI_AOA_OR_COPY_OF_BUSINESS_LICENSES =
  'coiAoaOrCopyOfBusinessLicences';
export const TYPE_AOO_OR_OPERATING_AGREEMENT = 'aooOrOperatingAgreement';
export const TYPE_TRUST_CERTIFICATE_OR_AGREEMENT =
  'trustCertificateOrAgreement';
export const TYPE_IRS_LETTER_COF_OR_AOA = 'irsLetterCofOrAoa';
export const TYPE_IRS_DETERMINATION_LETTER = 'irsDeterminationLetter';
export const TYPE_GOVERNING_TRUST_DOC = 'governingTrustDoc';
export const TYPE_REGULATORY_AGENCY_FORM = 'regulatoryAgencyForm';
export const TYPE_BENEFICIAL_OWNERS_LIST = 'beneficialOwnersList';
export const TYPE_REGISTER_OF_COMMERCE = 'roc';
export const TYPE_AUDITED_ANNUAL_REPORT = 'aar';
export const TYPE_COMFORT_LETTER = 'comfortLetter';
export const TYPE_DONOR_LIST = 'donorList';
export const TYPE_PROSPECTUS = 'prospectus';
export const TYPE_PROOF_OF_EXISTENCE = 'proofOfExistence';
export const TYPE_PROOF_OF_EMPLOYER_EXISTENCE = 'pee';
export const TYPE_INVESTMENT_MANAGEMENT_AGREEMENT = 'ima';
export const TYPE_DIRECTORS_LIST = 'directorsList';
export const TYPE_MEMBERS_LIST = 'membersList';
export const TYPE_IRA_ACCT_STATEMENT = 'iraAcctStatement';
export const TYPE_FORM_990 = 'form990';

export const docTypesToLabels = {
  [TYPE_VALID_ID]: 'Valid ID',
  [TYPE_PROOF_OF_ADDRESS]: 'Proof of address',
  [TYPE_LPA]: 'Limited partnership agreement',
  [TYPE_COP]: 'Certificate of partnership',
  [TYPE_OWNERSHIP_SCHEDULE]:
    'Document with schedule of ownership and percentages',
  [TYPE_SOB]: 'Schedule of beneficiaries',
  [TYPE_AML_LETTER]: 'AML letter',
  [TYPE_COI]: 'Certificate of incorporation',
  [TYPE_COR]: 'Certificate of registration / establishment or equivalent',
  [TYPE_PARTNERSHIP_AGREEMENT]: 'Partnership agreement',
  [TYPE_ASL]: 'Authorized signatory list',
  [TYPE_STRUCTURE_CHART]: 'Structure chart',
  [TYPE_AOA]: 'Memorandum and articles of association',
  [TYPE_REGISTER_OF_DIRECTORS]: 'Register of directors',
  [TYPE_REGISTER_OF_SHAREHOLDERS]: 'Register of shareholders',
  [TYPE_EVIDENCE_OF_LISTING]: 'Evidence of listing',
  [TYPE_LETTER_FROM_EXTERNAL_AUDITOR]: 'Letter from external auditor',
  [TYPE_ORG_CHART]: 'Organization chart',
  [TYPE_COF]: 'Certificate of formation',
  [TYPE_OPERATING_AGREEMENT]: 'Operating agreement',
  [TYPE_DOM]: 'Details of all managers',
  [TYPE_TRUST_DEED]: 'Trust deed',
  [TYPE_CERTIFICATE_OF_TRUST]: 'Certificate of trust',
  [TYPE_SCHEDULE_OF_TRUSTEES]: 'Schedule of trustees',
  [TYPE_FORMATION_DOCUMENT]: 'Formation document',
  [TYPE_COPOC]: 'Confirmation of nature / purpose of charity',
  [TYPE_COAS]: 'Confirmation of approved status',
  [TYPE_AUDITED_FINANCIALS]: 'Audited financials',
  [TYPE_DONORS_OVER_1OK]: 'Donors over 10k',
  [TYPE_CONFIRMATION_OF_OWNERSHIP]: 'Confirmation of ownership',
  [TYPE_DETAILS_OF_MAIN_OFFICIALS]: 'Details of main officials',
  [TYPE_SCHEDULE_OF_MAJOR_DONORS]: 'Schedule of major donors',
  [TYPE_CERT_COR]: 'Certified certificate of registration',
  [TYPE_CERT_AFS]: 'Certified copy of latest audited financial statements',
  [TYPE_CERT_LPA]: 'Certified limited partnership agreement',
  [TYPE_CERT_STRUCTURE_CHART]: 'Certified structure chart',
  [TYPE_CERT_SHAREHOLDER_REGISTRY]: 'Certified shareholder registry',
  [TYPE_CERT_DIRECTOR_REGISTER]: 'Certified register of directors',
  [TYPE_CERT_ASL]: 'Certified authorized signatory list',
  [TYPE_CERT_COI]: 'Certified certificate of incorporation',
  [TYPE_CERT_AOA]: 'Certified memorandum and articles of association',
  [TYPE_CERT_AOI]: 'Certified copy of evidence of authorization of investment',
  [TYPE_CERT_COF]: 'Certified certificate of formation',
  [TYPE_CERT_TRUST_DEED]: 'Certified trust deed',
  [TYPE_CERT_TRUST_AGREEMENT]: 'Certified trust agreement',
  [TYPE_CERT_PARTNERSHIP_AGREEMENT]: 'Certified partnership agreement',
  [TYPE_PROOF_SOURCE_OF_FUNDS]: 'Proof of source of funds',
  [TYPE_CERT_AML_LETTER]: 'Certified AML letter',
  [TYPE_CERT_REGISTER_OF_DIRECTORS]: 'Certified register of directors',
  [TYPE_CERT_REGISTER_OF_SHAREHOLDERS]: 'Certified register of shareholders',
  [TYPE_CERT_ORG_CHART]: 'Certified organization chart',
  [TYPE_CERT_OPERATING_AGREEMENT]: 'Certified operating agreement',
  [TYPE_CERT_DOM]: 'Certified details of all managers',
  [TYPE_CERT_SCHEDULE_OF_TRUSTEES]: 'Certified schedule of trustees',
  [TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS]: 'Certified schedule of major donors',
  [TYPE_CERT_SOB]: 'Certified schedule of beneficiaries',
  [TYPE_CERT_CERTIFICATE_OF_TRUST]: 'Certified certificate of trust',
  [TYPE_CERT_DONORS_OVER_1OK]: 'Certified schedule of donors over 10k',
  [TYPE_CERT_FORMATION_DOCUMENT]: 'Certified formation document',
  [TYPE_LIST_ACCOUNT_OWNERS]: 'List of account holders',
  [TYPE_PARTICIPANT_STATEMENT_REPORT_OR_QUARTERLY_ACCT]:
    'Participant statement report or Quarterly account statement',
  [TYPE_SEC_FILING]: 'Listed SEC filing',
  [TYPE_TRUST_AGREEMENT]: 'Trust agreement',
  [TYPE_CERTIFICATE_OF_INCUMBENCY]: 'Certificate of incumbency',
  [TYPE_STATEMENT_OF_FINANCIAL_CRIMES_COMPLIANCE]:
    'Statement of financial crimes compliance',
  [TYPE_PENSION_PLAN_DOCUMENT]: 'Pension plan document',
  [TYPE_IRS_LETTER_COF_OR_AOA]:
    'IRS letter, Certificate of formation, or Articles of association',
  [TYPE_COPY_OF_BUSINESS_LICENSES]: 'Copy of business licenses',
  [TYPE_PARTNERSHIP_AGREEMENT_OR_COPY_OF_BUSINESS_LICENSES]:
    'Partnership agreement or Copy of business licenses',
  [TYPE_COI_AOA_OR_COPY_OF_BUSINESS_LICENSES]:
    'Certificate of incorporation, Articles of association, or Copy of business licenses',
  [TYPE_AOO_OR_OPERATING_AGREEMENT]:
    'Articles of organization or Operating agreement',
  [TYPE_TRUST_CERTIFICATE_OR_AGREEMENT]: 'Trust certificate or Trust agreement',
  [TYPE_IRS_DETERMINATION_LETTER]:
    'Copy of IRS determination letter for 501(c)(3), form 990 (US entity only), or copy of memorandum and articles of incorporation',
  [TYPE_GOVERNING_TRUST_DOC]:
    'Governing trust document including trustee designation and beneficiary information',
  [TYPE_REGULATORY_AGENCY_FORM]:
    'Evidence of regulatory agency and registration number, or non-us financial institution certification form',
  [TYPE_BENEFICIAL_OWNERS_LIST]: 'List of beneficial owners',
  [TYPE_REGISTER_OF_COMMERCE]: 'Register of commerce',
  [TYPE_AUDITED_ANNUAL_REPORT]: 'Latest audited annual report',
  [TYPE_PROSPECTUS]: 'Prospectus',
  [TYPE_PROOF_OF_EXISTENCE]: 'Proof of existence',
  [TYPE_PROOF_OF_EMPLOYER_EXISTENCE]: 'Proof of employer existence',
  [TYPE_INVESTMENT_MANAGEMENT_AGREEMENT]: 'Investment management agreement',
  [TYPE_COMFORT_LETTER]: 'Comfort letter from bank',
  [TYPE_DONOR_LIST]: 'List of donor names and countries of residence',
  [TYPE_DIRECTORS_LIST]: 'List of directors',
  [TYPE_MEMBERS_LIST]: 'List of members',
  [TYPE_IRA_ACCT_STATEMENT]: 'IRA account statement',
  [TYPE_FORM_990]: 'Form 990 (Return of Organization Exempt From Income Tax)',
};

export const proofOfSourceOfFundsDocConfig = {
  key: TYPE_PROOF_SOURCE_OF_FUNDS,
  label: docTypesToLabels[TYPE_PROOF_SOURCE_OF_FUNDS],
  helpText:
    'Provide a copy of a bank statement reflecting your source of funds for the investment. The statement should contain the same banking information as the bank account used for your subscription to the fund.',
};

export function genDocumentKeyAndLabel(docTypeKey) {
  return {
    key: docTypeKey,
    label: docTypesToLabels?.[docTypeKey] || '',
  };
}

// MISC
export const genericDisplayDetailKeys = ['name', 'type'];

export const jurisdictions = {
  USA: 'USA',
  USA_V2: 'USA_V2',
  CAYMAN: 'CYM',
  CAYMAN_V2: 'CYM_V2',
  SEI: 'SEI',
  LUXEMBOURG: 'LUX',
  USA_V3: 'USA_V3',
};

export const SUBSECTION_DETAILS = 'DETAILS';
export const SUBSECTION_DOCUMENTS = 'DOCUMENTS';
export const SUBSECTION_OWNERS = 'OWNERS';

export const addressKeys = ['hqAddress', 'residentialAddress'];

export const MULTI_FILE_JURISDICTIONS = [
  jurisdictions.USA_V3,
  jurisdictions.CAYMAN_V2,
];

export const SOURCE_OF_WEALTH_JURISDICTIONS = [
  jurisdictions.CAYMAN,
  jurisdictions.CAYMAN_V2,
  jurisdictions.SEI,
  jurisdictions.LUXEMBOURG,
];
