import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Icons } from '@passthrough/uikit';
import { ResendVerificationButton } from 'components/resend_verification_button';
import { OTPInput } from 'components/otp_input';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

export function SMSForm({
  onSubmit,
  smsOtpCode,
  setSmsOtpCode,
  smsOtpError,
  setSmsOtpError,
  loading,
  resendLoading,
  allowSend,
  timeoutText,
  onResend,
  resendDisabled,
  onBack = () => {},
  onLogin = () => {},
}) {
  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit()
      .then(onLogin)
      .catch(() => {});
  }

  function handleBack() {
    setSmsOtpCode('');
    setSmsOtpError('');
    onBack();
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate>
      <Icons.SmsOutlined color="action" />
      <div>
        <Typography variant="card-heading">Text message</Typography>
        <Typography variant="body" size="small" color="text.secondary">
          You should receive a text message with a verification code on your
          phone shortly.
        </Typography>
      </div>
      <TextField
        InputProps={{
          inputComponent: OTPInput,
        }}
        variant="outlined"
        margin="normal"
        fullWidth
        id="smsOtpCode"
        label="Verification code"
        name="smsOtpCode"
        autoComplete="one-time-code"
        autoFocus
        error={!!smsOtpError}
        helperText={smsOtpError}
        value={smsOtpCode}
        onChange={(e) => setSmsOtpCode(e.target.value)}
        disabled={loading}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ inputMode: 'numeric' }}
      />
      <Button
        fullWidth
        variant="primary"
        type="submit"
        onClick={handleSubmit}
        loading={loading}
        disabled={!smsOtpCode}
      >
        Verify
      </Button>
      <ResendVerificationButton
        allowSend={allowSend}
        timeoutText={timeoutText}
        variant="secondary"
        onClick={onResend}
        disabled={resendDisabled || loading}
        loading={resendLoading}
        fullWidth
      >
        Resend text message
      </ResendVerificationButton>
      <Button fullWidth variant="secondary" onClick={handleBack}>
        Back
      </Button>
    </form>
  );
}
