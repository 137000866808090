import React, { useState, cloneElement } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { validatePath } from 'services/urls';

import { EnterPasswordForMFA } from './enter_password_for_mfa';

const useStyles = makeStyles(() => ({
  step: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  hiddenStep: {
    height: 0,
  },
}));

const PasswordStepTimeouts = {
  enter: 0,
  exit: 500,
};

const STEP_PASSWORD = 'password';
const STEP_MFA = 'mfa';

function MethodForm({ component, onBack }) {
  const location = useLocation();

  function handleLogin() {
    // User is now logged in, redirect to the next url or '/'
    const params = new URLSearchParams(location.search);
    const next = validatePath(params.get('next') || '/');
    // Do a full page refresh to get a new CSRF token
    window.location.href = next;
  }

  return cloneElement(component, { onBack, onLogin: handleLogin });
}

export function MFAFlow({
  email,
  errorComponent,
  onSwitch,
  password,
  setPassword,
  onForgotPassword,
  methodComponent,
  onPasswordSuccess = () => {},
}) {
  const [step, setStep] = useState(STEP_PASSWORD);
  const classes = useStyles();

  function handleConfirm() {
    onPasswordSuccess();
    setStep(STEP_MFA);
  }

  return (
    <>
      <Slide
        direction="right"
        in={step === STEP_PASSWORD}
        appear={false}
        mountOnEnter
        unmountOnExit
        timeout={PasswordStepTimeouts}
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_PASSWORD,
          })}
        >
          <EnterPasswordForMFA
            email={email}
            errorComponent={errorComponent}
            onConfirm={handleConfirm}
            onSwitch={onSwitch}
            password={password}
            setPassword={setPassword}
            onForgotPassword={onForgotPassword}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === STEP_MFA} mountOnEnter unmountOnExit>
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_MFA,
          })}
        >
          <>
            {errorComponent}
            <MethodForm
              component={methodComponent}
              onBack={() => setStep(STEP_PASSWORD)}
            />
          </>
        </div>
      </Slide>
    </>
  );
}
