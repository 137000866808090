import React, { useState, useEffect } from 'react';
import * as api from 'services/api';

import { PageContainer } from 'components/page_container';
import { Breadcrumbs, Button, PageHeader, Tab, Tabs } from '@passthrough/uikit';
import { Chip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Spinner } from 'components/spinner';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { filterTasksByIssue, KYCIssueTable } from './kyc_issue_table';
import {
  COMMENT_KEY,
  MISIDENTIFIED_KEY,
  POTENTIAL_MATCH_KEY,
  REVIEW_KEY,
  TRUE_ALERT_KEY,
  UNSURE_ALERT_KEY,
  WAIVED_KEY,
} from './constants';

export function KYCDashboardPage() {
  const TABS = [
    {
      id: 'all',
      name: 'All',
    },
    {
      id: 'alerts',
      name: 'Alerts',
    },
  ];

  const [currTab, setCurrTab] = useState(TABS[0].id);
  const [issueFilter, setIssueFilter] = useState([
    TRUE_ALERT_KEY,
    UNSURE_ALERT_KEY,
    REVIEW_KEY,
    MISIDENTIFIED_KEY,
    COMMENT_KEY,
    POTENTIAL_MATCH_KEY,
    WAIVED_KEY,
  ]);
  const [issues, setIssues] = useState({
    reviews: [],
    comments: [],
    misidentified: [],
    waived: [],
    potentialMatches: [],
    newAlerts: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const { LEGAL_NAME_MISMATCH_ISSUE_TYPE } = useFeatureFlags();

  const reviews = issues.reviews.map((review) => ({
    ...review,
    key: `${review.lpClosingId}-review`,
    issue: 'review',
  }));
  const misidentified = LEGAL_NAME_MISMATCH_ISSUE_TYPE
    ? issues.misidentified.map((misidentifiedEntity) => ({
        ...misidentifiedEntity,
        key: `${misidentifiedEntity.lpClosingId}-misidentified`,
        issue: 'misidentified',
      }))
    : [];
  const comments = issues.comments.map((comment) => ({
    ...comment,
    key: `${comment.lpClosingId}-comment`,
    issue: 'comment',
  }));
  const waivedTasks = issues.waived.map((waived) => ({
    ...waived,
    key: `${waived.lpClosingId}-waived`,
    issue: 'waived',
  }));
  const potentialMatches = issues.potentialMatches.map((potentialMatch) => ({
    ...potentialMatch,
    key: `${potentialMatch.potentialMatchId}`,
    issue: `potentialMatch`,
  }));
  const newAlerts = issues.newAlerts.map((newAlert) => ({
    ...newAlert,
    key: `${newAlert.alertId}`,
    issue: `newAlert-${newAlert.matchStatus}`,
  }));
  const tasks = reviews.concat(
    misidentified,
    comments,
    waivedTasks,
    potentialMatches,
    newAlerts,
  );

  useEffect(() => {
    api.diligenceIssueList().then((response) => {
      setIssues(response.data);
      setIsLoading(false);
    });
  }, []);

  const issueCount = filterTasksByIssue(
    [
      TRUE_ALERT_KEY,
      UNSURE_ALERT_KEY,
      REVIEW_KEY,
      MISIDENTIFIED_KEY,
      COMMENT_KEY,
      POTENTIAL_MATCH_KEY,
      WAIVED_KEY,
    ],
    tasks,
  ).length;
  const alertCount = filterTasksByIssue(
    [TRUE_ALERT_KEY, UNSURE_ALERT_KEY],
    tasks,
  ).length;
  const escalatedCount = filterTasksByIssue([], tasks).length;
  const allCount = tasks.length;

  const tabCounts = {
    issues: issueCount,
    alerts: alertCount,
    escalated: escalatedCount,
    all: allCount,
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <PageHeader
        heading="Diligence hub"
        crumbs={
          <Breadcrumbs
            crumbs={[
              {
                name: 'Diligence hub',
              },
            ]}
          />
        }
        tabs={
          <Tabs
            value={currTab}
            onChange={(ev, nextTab) => {
              setCurrTab(nextTab);
              switch (nextTab) {
                case 'alerts':
                  setIssueFilter([
                    TRUE_ALERT_KEY,
                    UNSURE_ALERT_KEY,
                    POTENTIAL_MATCH_KEY,
                  ]);
                  break;
                case 'all':
                  setIssueFilter([
                    TRUE_ALERT_KEY,
                    UNSURE_ALERT_KEY,
                    REVIEW_KEY,
                    COMMENT_KEY,
                    POTENTIAL_MATCH_KEY,
                    WAIVED_KEY,
                    MISIDENTIFIED_KEY,
                  ]);
                  break;
                default:
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.name}
                value={tab.id}
                endIcon={
                  tabCounts[tab.id] > 0 ? (
                    <Chip
                      color={currTab === tab.id ? 'primary' : 'default'}
                      label={tabCounts[tab.id]}
                      size="small"
                    />
                  ) : (
                    <></>
                  )
                }
              />
            ))}
          </Tabs>
        }
        actionButtons={
          <Button
            variant="text"
            startIcon={<Refresh />}
            onClick={() => {
              setIsLoading(true);
              api.diligenceIssueList().then((response) => {
                setIssues(response.data);
                setIsLoading(false);
              });
            }}
          >
            Refresh
          </Button>
        }
      />
      <PageContainer>
        <KYCIssueTable
          setCurrTab={setCurrTab}
          issueFilter={issueFilter}
          setIssueFilter={setIssueFilter}
          tasks={tasks}
        />
      </PageContainer>
    </>
  );
}
