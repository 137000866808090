import React from 'react';
import { useUploadFiles } from 'services/file/hooks';

import { FileUpload } from '@passthrough/uikit';

export const DndMultiFileUploadV2 = ({
  files,
  onChange,
  url,
  acceptedFileTypes = '',
  helperText = '',
  ...extraInputProps
}) => {
  const uploadFiles = useUploadFiles(url);

  function onDelete(fileId) {
    onChange(files.filter((file) => file.fileId !== fileId));
  }

  return (
    <FileUpload
      files={files}
      onAccept={onChange}
      onDelete={onDelete}
      uploadFiles={uploadFiles}
      multiple
      helperText={helperText}
      acceptedFileTypes={acceptedFileTypes}
      extraInputProps={extraInputProps}
    />
  );
};
