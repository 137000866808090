import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export function KeyValuePairs({ children }) {
  return <List>{children}</List>;
}

const useStyles = makeStyles(() => ({
  inputItem: {
    alignItems: 'start',
  },
  inputIcon: {
    marginTop: '18px',
  },
  icon: {
    marginRight: '16px',
    minWidth: '0px',
  },
  flexStart: {
    alignItems: 'flex-start',
  },
  disablePadding: {
    padding: '0',
  },
  alignToTop: {
    position: 'relative',
    top: '6px',
  },
}));

export function KeyValuePair({
  icon,
  label,
  children,
  input,
  iconClassName,
  dense,
  disableGutters,
  disablePadding,
  alignIconToTop,
}) {
  const classes = useStyles();
  const isString = typeof children === 'string' || children instanceof String;
  const value = isString ? <Typography>{children}</Typography> : children;
  const iconToRender = icon
    ? cloneElement(icon, {
        className: clsx({
          [icon.props?.className]: !!icon.props?.className,
          [classes.alignToTop]: alignIconToTop,
        }),
      })
    : null;

  return (
    <ListItem
      dense={dense}
      disableGutters={disableGutters}
      className={clsx({
        [classes.inputItem]: input,
        [classes.flexStart]: alignIconToTop,
        [classes.disablePadding]: disablePadding,
      })}
    >
      <ListItemIcon
        className={clsx(
          iconClassName || {},
          { [classes.inputIcon]: input },
          classes.icon,
        )}
      >
        {iconToRender}
      </ListItemIcon>

      <ListItemText primary={value} secondary={label} />
    </ListItem>
  );
}
