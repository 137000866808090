import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ExploreMenu } from './menu';
import { MilestoneMenu } from './milestone';
import { Banner } from '../banner';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    right: '30px',
    zIndex: '3',
    border: `1px solid ${theme.palette.primary.contrastText}`,
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  helpLabel: {
    marginLeft: theme.spacing(1),
  },
  fabPassthrough: {
    backgroundColor: theme.palette.demoMode[1],
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

const ExploreContext = React.createContext();

export const useExplore = () => React.useContext(ExploreContext);

export function ExploreProvider({ children }) {
  const anchor = useRef(null);
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const classes = useStyles();
  const isPassthrough = !window.WHITE_LABEL_CONFIG;
  const isSmallScreen = !useMediaQuery('(min-height: 600px)');

  if (!window.EXPLORE_MODE) {
    return (
      <ExploreContext.Provider
        value={{
          completeMilestone: () => {},
        }}
      >
        {children}
      </ExploreContext.Provider>
    );
  }

  return (
    <ExploreContext.Provider
      value={{
        completeMilestone: () => {
          setTimeout(() => {
            setCompleted(true);
            setOpen(true);
          }, 1000);
        },
      }}
    >
      <Banner />
      {children}

      <Fab
        ref={anchor}
        variant="extended"
        color="primary"
        aria-label="contact support"
        className={clsx(classes.fab, {
          [classes.fabPassthrough]: isPassthrough,
        })}
        onClick={() => setOpen(!open)}
      >
        <InfoIcon />

        <Typography className={classes.helpLabel}>Explore</Typography>
      </Fab>

      {completed ? (
        <MilestoneMenu
          open={open}
          anchor={anchor}
          onClose={() => setOpen(false)}
          fullScreen={isSmallScreen}
        />
      ) : (
        <ExploreMenu
          open={open}
          anchor={anchor}
          onClose={() => setOpen(false)}
          fullScreen={isSmallScreen}
        />
      )}
    </ExploreContext.Provider>
  );
}
