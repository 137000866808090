import React, { useState } from 'react';
import { Modal, useConfirm, Alert } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';

import { DndMultiFileUploadV2 } from 'components/dnd_file_upload/multi_file/multi_file_upload_v2';
import { DndMultiFileUpload } from 'components/dnd_file_upload/multi_file/multi_file_upload';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { haveDocumentsChanged } from './docs_modal';

export function EditSupplementaryDiligenceDocsModal({
  initialSupplementaryDocs,
  open,
  onClose,
  onSubmit,
}) {
  const { fundId } = useParams();
  const [localDocAnswers, setLocalDocAnswers] = useState(
    initialSupplementaryDocs,
  );
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const { FILE_UPLOAD_COMPONENT_V2 } = useFeatureFlags();
  const haveAnswersChanged = haveDocumentsChanged(
    initialSupplementaryDocs,
    localDocAnswers,
  );

  return (
    <Modal
      open={open}
      onEntering={() => {
        setLocalDocAnswers(initialSupplementaryDocs);
      }}
      onClose={() => {
        if (!haveAnswersChanged) {
          onClose();
          return;
        }

        confirm({
          title: 'Discard unsaved changes?',
          description: 'Your changes have not been saved.',
          destructive: 'true',
          confirmationText: 'Close',
          size: 'xs',
        })
          .then(() => {
            setLocalDocAnswers(initialSupplementaryDocs);
            onClose();
          })
          .catch(() => {});
      }}
      onSubmit={() => {
        setLoading(true);

        onSubmit(localDocAnswers)
          .then(() => {
            onClose();
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      headerLabel="Manage additional documents"
      showCancelButton
      primaryButtonText="Save changes"
      primaryButtonDisabled={!haveAnswersChanged}
      primaryButtonLoading={loading}
    >
      <Alert severity="warning">
        All additional documents will be deleted if the investor deletes this
        owner.
      </Alert>

      {FILE_UPLOAD_COMPONENT_V2 ? (
        <DndMultiFileUploadV2
          files={localDocAnswers}
          onChange={(newFiles) => {
            setLocalDocAnswers(newFiles);
          }}
          url={`/api/fund/${fundId}/file/upload/`}
        />
      ) : (
        <DndMultiFileUpload
          files={localDocAnswers}
          onChange={(newFiles) => {
            setLocalDocAnswers(newFiles);
          }}
          url={`/api/fund/${fundId}/file/upload/`}
        />
      )}
    </Modal>
  );
}
