import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';
import { Alert, UIKitSettingsProvider } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { SignUp } from 'pages/signup_v2';
import * as Sentry from '@sentry/react';
import { FundPage } from 'pages/fund';
import { FundListPage } from 'pages/fund_list';
import { OnboardingPage as OnboardingV3Page } from 'pages/onboarding_v3/index';
import { LpDocPage } from 'pages/lp_doc/index';
import { SecondSignerPage } from 'pages/second_signer/index';
import { CountersignPage } from 'pages/countersign/index';
import { UserSettingsPage } from 'pages/user_settings/index';
import { ManageProfilesPage } from 'pages/manage_profiles/index';
import { Profile as ProfileV2 } from 'pages/profile_data/v2';
import { SavedDataPage } from 'pages/profile_data/v2/saved_data_page';
import { InvestmentsPage } from 'pages/investments';
import { InvestorPage } from 'pages/investor/index';
import { FundStaffToolsPage } from 'pages/fund_staff_pages/index';
import { OrganizationPage } from 'pages/organization';
import { OrganizationFundListPage } from 'pages/organization_funds';
import { OrganizationAccessPage } from 'pages/organization_access/index';
import { QuestionTagsPage } from 'pages/question_tags/index';
import { SettingsPage } from 'pages/integration';
import { IntegrationRedirect } from 'pages/integration/integration_redirect';
import { SelectStaffOrgPage } from 'pages/select_staff_org';
import { FundSettingsPage } from 'pages/fund_settings';
import { MFASetupFlowPage } from 'pages/mfa_setup_flow';
import { Spinner } from 'components/spinner';
import { OrganizationNav } from 'components/organization_nav';
import { FundProvider } from 'services/providers/fund';
import { OrganizationProvider } from 'services/providers/organization';
import { MeContext } from 'services/providers/me';
import { ClosingProvider } from 'services/providers/closing';
import {
  FeatureFlagContext,
  useFeatureFlags,
} from 'services/providers/feature_flag';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { showCustomSignup, CUSTOM_SIGNUP } from 'pages/custom_signup';

import { WebAppQuestionnaireProvider } from 'services/providers/questionnaire';
import { SignProvider } from 'services/providers/sign';
import {
  LPAggregateFeatureFlagProvider,
  GPAggregateFeatureFlagProvider,
} from 'services/providers/flag_aggregator';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { getSelectedStaffOrg } from 'services/utils';
import { TasksProvider } from 'services/providers/tasks';
import { SessionProvider } from 'services/providers/session';
import { bootSupport, useSupport } from 'components/support';
import {
  PassthroughSpecificRedirect,
  PassthroughSpecificRedirectProvider,
} from 'services/passthrough_specific_redirect';

import { FundClosingOverviewPage } from 'pages/fund_closing_overview/index';
import { ClosingSettingsPage } from 'pages/closing_settings/index';
import { TasksPage } from 'pages/tasks_v2/index';
import { OfferingPage } from 'pages/offering_v2/index';
import { ReviewPage } from 'pages/review_v2/index';
import { FundNav } from 'components/fund_nav_v2';
import { DocsRedirect } from 'pages/docs_redirect';
import { AcceptPassthroughTerms } from 'pages/accept_terms';
import { AccessPage } from 'pages/access_v2';
import { GlobalNavigation } from 'components/GlobalNavigation';
import { ClosingsPage } from 'pages/closings_v2';
import { CustomApprovalPage } from 'pages/custom_approval/index';
import { KYCDashboardPage } from 'pages/kyc_dashboard/index';
import { ExploreProvider } from 'components/explore/button';
import { MAX_LARGE_FILE_UPLOAD_SIZE } from 'services/file/constants';
import { StaffUikit } from './staff_uikit';
import { CLOSING_TYPES } from './closings_v2/constants';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    flexDirection: 'column',
  },
  growContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
  },
  signup: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
}));

function getName(data) {
  return data.firstName && data.lastName
    ? `${data.firstName} ${data.lastName}`
    : null;
}

export function Dashboard({ pollTasks, setPollTasks }) {
  const [me, setMe] = useState(null);
  const [systemMessage, setSystemMessage] = useState('');
  const [changeMe, setChangeMe] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const config = useWhiteLabelConfig();
  const { showSupport } = useSupport();

  function getMe() {
    api
      .me()
      .then((response) => {
        setMe(response.data);
        Sentry.setUser({ id: response.data.id, email: response.data.email });
        bootSupport({
          email: response.data.email,
          name: getName(response.data),
          jwtToken: response.data.kustomerSecret,
        });
        if (
          !response.data.isStaff &&
          process.env.SENTRY_SESSION_REPLAY_ENABLED
        ) {
          const sentry = Sentry.getCurrentHub().getClient();
          sentry.addIntegration(
            new Sentry.Replay({
              blockAllMedia: false,
              maskAllInputs: true,
              maskAllText: false,
            }),
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          const nextUrl = location.pathname;
          history.push(`/signin/?next=${nextUrl}`);
        } else {
          throw error;
        }
      });
  }

  function getSystemMessage() {
    api
      .systemMessage()
      .then((response) => {
        setSystemMessage(response.data.message);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setSystemMessage('');
        } else {
          throw error;
        }
      });
  }

  function redirectToStaffOrgPage() {
    const selectedStaffOrg = getSelectedStaffOrg(me);

    if (me?.isStaff && !selectedStaffOrg) {
      history.push(urls.SELECT_STAFF_ORG);
    }
  }

  useEffect(redirectToStaffOrgPage, [me]);
  useEffect(getMe, [changeMe]);
  useEffect(getSystemMessage, [changeMe]);

  if (!me) {
    return <Spinner fullScreen />;
  }

  const shouldShowCustomSignup = showCustomSignup({ me, config });

  if (shouldShowCustomSignup) {
    const CustomSignup = CUSTOM_SIGNUP[config.terms];
    return (
      <CustomSignup
        me={me}
        onSignUp={() => {
          setChangeMe((c) => c + 1);
        }}
      />
    );
  }

  // Until you've signed up, we don't know your first name.
  // We may know your "last name" if you were invited as a collaborator.
  const showSignUp = !me.firstName;
  if (showSignUp) {
    return (
      <Fade in={showSignUp} unmountOnExit>
        <div className={classes.signup}>
          <SignUp
            onSignUp={() => {
              setChangeMe((c) => c + 1);
            }}
          />
        </div>
      </Fade>
    );
  }

  const needToAcceptPrivacyTerms = !me.acceptedTermsAndPrivacyAt;
  if (needToAcceptPrivacyTerms) {
    // This shows the old BgWaves version of the terms page. New users should
    // never reach this, because it should be impossible to have firstName set
    // without having accepted the terms.
    return (
      <AcceptPassthroughTerms
        onAcceptance={() => {
          setChangeMe((c) => c + 1);
        }}
      />
    );
  }

  // Can't use useFeatureFlags to get the flag value because we haven't
  // rendered FeatureFlagContext.Provider yet. Similarly, we have to pass me into
  // MFASetupFlow because we haven't rendered MeContext.Provider yet.
  const needToSetupMFA =
    me.featureFlags.MULTI_FACTOR_AUTHENTICATION &&
    me.requiresMfa &&
    !me.mfaEnabled;
  if (needToSetupMFA) {
    return <MFASetupFlowPage me={me} />;
  }

  return (
    <MeContext.Provider value={[me, () => setChangeMe((c) => c + 1), setMe]}>
      <FeatureFlagContext.Provider value={me.featureFlags}>
        <SessionProvider userId={me?.id}>
          <TasksProvider pollTasks={pollTasks} setPollTasks={setPollTasks}>
            <ExploreProvider>
              <WebAppQuestionnaireProvider>
                <UIKitSettingsProvider
                  linkComponent={RouterLink}
                  showSupport={showSupport}
                  maxFileSize={MAX_LARGE_FILE_UPLOAD_SIZE}
                >
                  <SignProvider>
                    <PassthroughSpecificRedirectProvider>
                      <DashboardRoutes
                        systemMessage={systemMessage}
                        me={me}
                        showSignUp={showSignUp}
                      />
                    </PassthroughSpecificRedirectProvider>
                  </SignProvider>
                </UIKitSettingsProvider>
              </WebAppQuestionnaireProvider>
            </ExploreProvider>
          </TasksProvider>
        </SessionProvider>
      </FeatureFlagContext.Provider>
    </MeContext.Provider>
  );
}

function DashboardRoutes({ systemMessage, me, showSignUp }) {
  const closingId = urls.useClosingId();
  const fundId = urls.useFundId();
  const organizationId = urls.useOrganizationId();
  const classes = useStyles();
  const [showTopBar, setShowTopBar] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const { DILIGENCE_ON_SEPARATE_CLOSING, INVESTMENTS_PAGE } = useFeatureFlags();

  return (
    <div className={classes.main}>
      <GlobalNavigation me={me} open={showTopBar} />

      {systemMessage ? (
        <Alert severity="warning" variant="sticky">
          {systemMessage}
        </Alert>
      ) : null}

      <Switch>
        <Route path={urls.DOCS_REDIRECT_URL}>
          <DocsRedirect />
        </Route>
        <Route path={urls.LP_DOC_URL}>
          <LPAggregateFeatureFlagProvider>
            <LpDocPage
              setShowTopBar={setShowTopBar}
              key={refresh}
              refresh={() => setRefresh((c) => c + 1)}
            />
          </LPAggregateFeatureFlagProvider>
        </Route>
        <Route>
          <Grow in={!showSignUp} appear={false}>
            <div className={classes.growContainer}>
              <Route exact path={urls.DASH_URL}>
                <TasksPage />
              </Route>
              {INVESTMENTS_PAGE ? (
                <Route exact path={urls.INVESTMENTS_URL}>
                  <InvestmentsPage />
                </Route>
              ) : null}
              <Route exact path={urls.USER_SETTINGS_URL}>
                <PassthroughSpecificRedirect>
                  <UserSettingsPage />
                </PassthroughSpecificRedirect>
              </Route>
              <Route exact path={urls.MANAGE_PROFILES_URL}>
                <PassthroughSpecificRedirect>
                  <ManageProfilesPage />
                </PassthroughSpecificRedirect>
              </Route>
              <Switch>
                <Route path={urls.PROFILE_SAVED_DATA_URL}>
                  <PassthroughSpecificRedirect>
                    <SavedDataPage />
                  </PassthroughSpecificRedirect>
                </Route>
                <Route path={urls.MANAGE_PROFILE_URL}>
                  <PassthroughSpecificRedirect>
                    <ProfileV2 />
                  </PassthroughSpecificRedirect>
                </Route>
              </Switch>
              <Route exact path={urls.FUNDS_URL}>
                <FundListPage />
              </Route>
              <Route path={urls.SECOND_SIGNER_URL}>
                <SecondSignerPage />
              </Route>
              <Route path={urls.ORGANIZATION_URL}>
                <OrganizationProvider organizationId={organizationId}>
                  <OrganizationNav />
                  <Switch>
                    <Route path={urls.ORGANIZATION_FUNDS_URL}>
                      <OrganizationFundListPage />
                    </Route>
                    <Route path={urls.ORGANIZATION_ACCESS_URL}>
                      <OrganizationAccessPage />
                    </Route>
                    <Route path={urls.ORGANIZATION_INTEGRATION_URL}>
                      <IntegrationRedirect />
                    </Route>
                    <Route path={urls.ORGANIZATION_SETTINGS_URL}>
                      <SettingsPage />
                    </Route>
                    <Route path={urls.ORGANIZATION_URL}>
                      <OrganizationPage />
                    </Route>
                  </Switch>
                </OrganizationProvider>
              </Route>
              <Route path={urls.FUND_URL}>
                <FundProvider fundId={fundId}>
                  <GPAggregateFeatureFlagProvider>
                    <Switch>
                      <Route path={urls.ONBOARDING_V2_URL}>
                        <OnboardingV3Page />
                      </Route>
                      <Route path={urls.CLOSING_URL}>
                        <ClosingProvider fundId={fundId} closingId={closingId}>
                          <Route path={urls.INVESTOR_URL} exact>
                            <>
                              <FundNav />
                              <InvestorPage />
                            </>
                          </Route>
                          <Switch>
                            <Route path={urls.REVIEW_URL}>
                              <ReviewPage />
                            </Route>
                            <Route path={urls.CLOSING_URL} exact>
                              <FundClosingOverviewPage key={closingId} />
                            </Route>
                            <Route path={urls.COUNTERSIGN_URL} exact>
                              <CountersignPage />
                            </Route>
                            <Route path={urls.CLOSING_SETTINGS_URL} exact>
                              <ClosingSettingsPage />
                            </Route>
                          </Switch>
                        </ClosingProvider>
                      </Route>
                      <Route>
                        <FundNav />
                        <Switch>
                          {DILIGENCE_ON_SEPARATE_CLOSING ? (
                            [
                              <Route path={urls.CLOSINGS_URL} exact>
                                <ClosingsPage
                                  key={CLOSING_TYPES.closings}
                                  closingType={CLOSING_TYPES.closings}
                                />
                              </Route>,
                              <Route path={urls.DILIGENCES_URL} exact>
                                <ClosingsPage
                                  key={CLOSING_TYPES.diligence}
                                  closingType={CLOSING_TYPES.diligence}
                                />
                              </Route>,
                            ]
                          ) : (
                            <Route path={urls.CLOSINGS_URL} exact>
                              <ClosingsPage />
                            </Route>
                          )}

                          <Route path={urls.OFFERING_URL} exact>
                            <OfferingPage />
                          </Route>
                          <Route path={urls.ACCESS_URL} exact>
                            <AccessPage />
                          </Route>
                          <Route path={urls.FUND_URL} exact>
                            <FundPage />
                          </Route>
                          <Route path={urls.FUND_SETTINGS_URL} exact>
                            <FundSettingsPage />
                          </Route>
                          {me.isStaff ? (
                            <Route path={urls.FUND_STAFF_TOOLS_URL} exact>
                              <FundStaffToolsPage />
                            </Route>
                          ) : null}
                          {me.isStaff ? (
                            <Route path={urls.FUND_CUSTOM_APPROVALS_URL} exact>
                              <CustomApprovalPage />
                            </Route>
                          ) : null}
                        </Switch>
                      </Route>
                    </Switch>
                  </GPAggregateFeatureFlagProvider>
                </FundProvider>
              </Route>
              {me.isStaff && (
                <>
                  <Route exact path={urls.SELECT_STAFF_ORG}>
                    <SelectStaffOrgPage />
                  </Route>
                  <Route path={urls.UIKIT_URL}>
                    <StaffUikit />
                  </Route>
                </>
              )}
              <Route exact path={urls.QUESTION_TAGS_URL}>
                <QuestionTagsPage />
              </Route>
              <Route exact path={urls.DILIGENCE_HUB_URL}>
                <KYCDashboardPage />
              </Route>
            </div>
          </Grow>
        </Route>
      </Switch>
    </div>
  );
}
