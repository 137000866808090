import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@passthrough/uikit';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { getSingleErrorFromResponse } from 'services/utils';
import * as api from 'services/api';

import { EmailChip } from './email_chip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
}));

export function ForgotPassword({ email, onConfirm, onSwitch, setMiscError }) {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  function handleConfirm() {
    setLoading(true);
    api
      .requestPasswordReset({ email })
      .then(() => {
        onConfirm();
      })
      .catch((error) => {
        setMiscError(getSingleErrorFromResponse(error?.response));
        setLoading(false);
      });
  }

  return (
    <div className={classes.container}>
      <Typography>
        {productName} will send you a password reset link to this email address:
      </Typography>
      <EmailChip email={email} onClick={onSwitch} />
      <div className={classes.buttons}>
        <Button
          variant="primary"
          onClick={handleConfirm}
          fullWidth
          loading={loading}
        >
          Send password reset email
        </Button>
        <Button
          variant="secondary"
          onClick={onSwitch}
          fullWidth
          disabled={loading}
        >
          Back to sign in
        </Button>
      </div>
    </div>
  );
}
