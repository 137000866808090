import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Alert } from '@passthrough/uikit';
import { PageContainer } from 'components/page_container';
import { Spinner } from 'components/spinner';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { useFund } from 'services/providers/fund';
import { getSingleErrorFromResponse } from 'services/utils';

import { GeneralSettingsModal } from './modals/general_settings';
import { QuestionnaireSettingsModal } from './modals/questionnaire_settings';
import { DiligenceSettingsModal } from './modals/diligence_settings';
import { InvestorNotificationSettingsModal } from './modals/investor_notification_settings';
import { FundCollaboratorNotificationSettingsModal } from './modals/fund_collaborator_notification_settings';
import { ComplianceSettingsModal } from './modals/compliance_settings';

import { GeneralSettings } from './general';
import { QuestionnaireSettings } from './questionnaire';
import { DiligenceSettings } from './diligence';
import { InvestorNotificationSettings } from './investor_notifications';
import { FundCollaboratorNotificationSettings } from './fund_collaborator_notifications';
import { ComplianceSettings } from './compliance';
import { REQUEST_REVIEW_DISABLED } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

const GENERAL_SETTINGS_MODAL = 'general_settings';
const QUESTIONNAIRE_SETTINGS_MODAL = 'questionnaire_settings';
const INVESTOR_NOTIFICATION_SETTINGS_MODAL = 'investor_notification_settings';
const FUND_COLLABORATOR_NOTIFICATION_SETTINGS_MODAL =
  'fund_collaborator_notification_settings';
const COMPLIANCE_SETTINGS_MODAL = 'compliance_settings';
const DILIGENCE_SETTINGS_MODAL = 'diligence_settings';

export function FundSettingsPage() {
  const [fundSettings, setFundSettings] = useState(null);
  const [modal, setModal] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { fundId } = useParams();
  const [, , refreshFund] = useFund();
  const classes = useStyles();
  const { toast } = useToast();

  function fetchSettings() {
    return api.fundSettings({ fundId }).then((response) => {
      setFundSettings(response.data);
    });
  }

  function updateSettings(form) {
    setErrors({});
    setErrorMsg(null);
    setUpdateLoading(true);
    api
      .updateFundSettings({ fundId, form })
      .then(() => {
        refreshFund();
        fetchSettings()
          .then(() => {
            setModal(null);
            setUpdateLoading(false);
            toast('Changes saved');
          })
          .catch(() => window.location.reload());
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setErrors(error.response.data);
        } else if (error?.response) {
          setErrorMsg(getSingleErrorFromResponse(error.response));
        }
        setUpdateLoading(false);
      });
  }

  useEffect(() => {
    fetchSettings();
  }, [fundId]);

  if (fundSettings === null) {
    return <Spinner fullScreen />;
  }

  const {
    // General settings
    logo,
    currencyCode,
    currencySymbol,

    // Questionnaire settings
    lpRequestReview,

    // Diligence settings
    enableAdverseMediaOnAllNodes,
    showDiligenceSettings,

    // Investor notification settings
    subdocCompleteEmail,
    fullyExecutedEmail,
    documentTypesToInclude,

    // Fund collaborator notification settings
    fullyExecutedAdminEmailEnabled,

    // Compliance settings
    bccRecipients,
  } = fundSettings;

  // The API can return None for lpRequestReview. The default is disabled
  const lpRequestReviewValue = lpRequestReview || REQUEST_REVIEW_DISABLED;

  return (
    <PageContainer className={classes.container} maxWidth="md">
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}

      <GeneralSettings
        logo={logo}
        currencyCode={currencyCode}
        currencySymbol={currencySymbol}
        onEdit={() => setModal(GENERAL_SETTINGS_MODAL)}
      />
      <QuestionnaireSettings
        lpRequestReview={lpRequestReviewValue}
        onEdit={() => setModal(QUESTIONNAIRE_SETTINGS_MODAL)}
      />
      {showDiligenceSettings ? (
        <DiligenceSettings
          enableAdverseMediaOnAllNodes={enableAdverseMediaOnAllNodes}
          onEdit={() => setModal(DILIGENCE_SETTINGS_MODAL)}
        />
      ) : null}
      <InvestorNotificationSettings
        subdocCompleteEmail={subdocCompleteEmail}
        fullyExecutedEmail={fullyExecutedEmail}
        documentTypesToInclude={documentTypesToInclude}
        onEdit={() => setModal(INVESTOR_NOTIFICATION_SETTINGS_MODAL)}
      />
      <FundCollaboratorNotificationSettings
        fullyExecutedAdminEmailEnabled={fullyExecutedAdminEmailEnabled}
        onEdit={() => setModal(FUND_COLLABORATOR_NOTIFICATION_SETTINGS_MODAL)}
      />
      <ComplianceSettings
        bccRecipients={bccRecipients}
        onEdit={() => setModal(COMPLIANCE_SETTINGS_MODAL)}
      />

      <GeneralSettingsModal
        open={modal === GENERAL_SETTINGS_MODAL}
        initialState={{
          logo,
          currencyCode,
          currencySymbol,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
      <QuestionnaireSettingsModal
        open={modal === QUESTIONNAIRE_SETTINGS_MODAL}
        initialState={{
          lpRequestReview: lpRequestReviewValue,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
      <DiligenceSettingsModal
        open={modal === DILIGENCE_SETTINGS_MODAL}
        initialState={{
          enableAdverseMediaOnAllNodes,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
      <InvestorNotificationSettingsModal
        open={modal === INVESTOR_NOTIFICATION_SETTINGS_MODAL}
        initialState={{
          subdocCompleteEmail,
          fullyExecutedEmail,
          documentTypesToInclude,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
      <FundCollaboratorNotificationSettingsModal
        open={modal === FUND_COLLABORATOR_NOTIFICATION_SETTINGS_MODAL}
        initialState={{
          fullyExecutedAdminEmailEnabled,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
      <ComplianceSettingsModal
        open={modal === COMPLIANCE_SETTINGS_MODAL}
        initialState={{
          bccRecipients,
        }}
        onClose={() => setModal(null)}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={updateLoading}
      />
    </PageContainer>
  );
}
