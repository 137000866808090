import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Alert, Modal } from '@passthrough/uikit';
import { JurisdictionSelect } from 'components/jurisdiction_select';
import * as React from 'react';
import * as api from 'services/api';
import { useFund } from 'services/providers/fund';
import { useToast } from 'services/toast';

export function ManagedDiligenceInputs({
  possibleDiligenceReviewers,
  diligenceReviewerEmail,
  setDiligenceReviewerEmail,
  error,
  initialJurisdiction,
  setInitialJurisdiction,
  askJurisdiction = false,
}) {
  const getDiligenceReviewerLabel = React.useCallback(
    (option) => {
      let reviewer = option;

      if (typeof option === 'string') {
        reviewer = possibleDiligenceReviewers.find((c) => c.email === option);
      }

      return `${reviewer?.name} - ${reviewer?.email}`;
    },
    [possibleDiligenceReviewers],
  );

  return (
    <>
      <Autocomplete
        options={possibleDiligenceReviewers}
        value={diligenceReviewerEmail}
        onChange={(e, v) => {
          setDiligenceReviewerEmail(v?.email || null);
        }}
        getOptionLabel={getDiligenceReviewerLabel}
        getOptionSelected={(option, value) => option.email === value}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Diligence reviewer"
            variant="outlined"
            fullWidth
            required
            error={Boolean(error?.diligenceReviewerEmail)}
            helperText={error?.diligenceReviewerEmail}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'chrome-off',
            }}
          />
        )}
      />
      {askJurisdiction ? (
        <JurisdictionSelect
          jurisdiction={initialJurisdiction}
          onChange={(e) => setInitialJurisdiction(e.target.value)}
          managedJurisdictions
          jurisdictionError={error?.initialJurisdiction}
        />
      ) : null}
    </>
  );
}

export function ManagedDiligenceModal({
  fundId,
  open,
  onClose,
  setManagedDiligenceEnabled,
  investorCount,
  askJurisdiction = false,
}) {
  const [, , refreshFund] = useFund();
  const [diligenceReviewerEmail, setDiligenceReviewerEmail] =
    React.useState(null);
  const [possibleDiligenceReviewers, setPossibleDiligenceReviewers] =
    React.useState([]);
  const [error, setError] = React.useState(null);
  const [initialJurisdiction, setInitialJurisdiction] = React.useState(null);
  const { successToast } = useToast();

  React.useEffect(() => {
    api.listDiligenceReviewers().then((response) => {
      setPossibleDiligenceReviewers(response.data);
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLabel="Enable managed diligence"
      primaryButtonText="Enable managed diligence"
      onSubmit={(event) => {
        event.preventDefault();
        api
          .enableManagedDiligence({
            fundId,
            diligenceReviewerEmail,
            initialJurisdiction,
          })
          .then(() => {
            successToast('Managed diligence enabled');
            setManagedDiligenceEnabled(true);
            refreshFund();
            onClose();
          })
          .catch((exc) => {
            setError(exc.response.data);
          });
      }}
    >
      {error?.nonFieldErrors ? (
        <Alert severity="error">{error.nonFieldErrors}</Alert>
      ) : null}
      {investorCount > 0 ? (
        <Alert severity="warning">
          Custom approval groups and statuses will be applied to existing
          investors.
        </Alert>
      ) : null}
      <ManagedDiligenceInputs
        possibleDiligenceReviewers={possibleDiligenceReviewers}
        diligenceReviewerEmail={diligenceReviewerEmail}
        setDiligenceReviewerEmail={setDiligenceReviewerEmail}
        error={error}
        initialJurisdiction={initialJurisdiction}
        setInitialJurisdiction={setInitialJurisdiction}
        askJurisdiction={askJurisdiction}
      />
    </Modal>
  );
}
