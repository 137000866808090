import {
  TYPE_IRA,
  TYPE_INDIVIDUAL,
  TYPE_PRIVATE_TRUST,
  TYPE_PENSION_SCHEME,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_LLC,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_PARTNERSHIP,
  TYPE_BENEFICIARIES,
  TYPE_HOLDERS,
  TYPE_GPS,
  TYPE_DIRECTORS,
  TYPE_BENEFICIAL_OWNERS,
  TYPE_MEMBERS,
  TYPE_TRUSTEES,
  TYPE_GRANTORS,
  TYPE_SIGNATORIES,
  TYPE_RECIPIENTS,
  TYPE_LPS,
  TYPE_VALID_ID,
  TYPE_PROOF_OF_ADDRESS,
  TYPE_EVIDENCE_OF_LISTING,
  TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
  TYPE_COPOC,
  TYPE_COAS,
  TYPE_CONFIRMATION_OF_OWNERSHIP,
  TYPE_CERT_COR,
  TYPE_CERT_AFS,
  TYPE_CERT_LPA,
  TYPE_CERT_STRUCTURE_CHART,
  TYPE_CERT_ASL,
  TYPE_CERT_COI,
  TYPE_CERT_AOA,
  TYPE_CERT_COF,
  TYPE_CERT_TRUST_DEED,
  TYPE_CERT_PARTNERSHIP_AGREEMENT,
  TYPE_CERT_AML_LETTER,
  TYPE_CERT_REGISTER_OF_DIRECTORS,
  TYPE_CERT_REGISTER_OF_SHAREHOLDERS,
  TYPE_CERT_ORG_CHART,
  TYPE_CERT_OPERATING_AGREEMENT,
  TYPE_CERT_DOM,
  TYPE_CERT_FORMATION_DOCUMENT,
  TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS,
  TYPE_CERT_DONORS_OVER_1OK,
  GROUP_NONE,
  TYPE_PARTNERS,
  TYPE_MANAGERS,
  TYPE_PROTECTORS,
  TYPE_ENFORCERS,
  TYPE_SCHOOL_ENDOWMENT,
  TYPE_CIO_COMMITTEE_MEMBERS,
  TYPE_JOINT_ACCOUNT_OR_TENANCY,
  GROUP_COMPANY,
  GROUP_OTHER,
} from '../../constants';

export const CAYMAN_V2_REQUIREMENTS = {
  [GROUP_NONE]: {
    [TYPE_INDIVIDUAL]: {
      documents: [
        {
          key: TYPE_VALID_ID,
          label: 'Certified passport or birth certificate',
          helpText:
            'Upload a photo of your valid passport or birth certificate.',
        },
        {
          key: TYPE_PROOF_OF_ADDRESS,
          label: 'Certified proof of residence',
          helpText:
            'Upload your proof of residence, such as a utility bill, dated in the last three months.',
        },
      ],
      owners: [],
    },
    [TYPE_JOINT_ACCOUNT_OR_TENANCY]: {
      documents: [],
      owners: [
        {
          key: TYPE_HOLDERS,
          singularLabel: 'Joint account holder',
          pluralLabel: 'Joint account holders',
          helpText:
            'Add the names of all joint account holders of the joint account.',
          minNum: 2,
        },
      ],
    },
    [TYPE_IRA]: {
      documents: [
        {
          key: TYPE_CERT_AML_LETTER,
          label: 'Certified AML letter',
          helpText:
            'Upload a certified letter attesting that there is not substantive money laundering activity associated with the IRA.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Beneficiary',
          pluralLabel: 'Beneficiaries',
          helpText: 'Add the names of all beneficiaries of the IRA.',
        },
      ],
    },
  },
  [GROUP_COMPANY]: {
    [TYPE_LIMITED_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_CERT_COR,
          label: 'Certified certificate of registration',
          helpText:
            'Upload a certified copy of a certificate of registration or equivalent.',
        },
        {
          key: TYPE_CERT_LPA,
          label: 'Certified limited partnership agreement',
          helpText:
            'Upload a certified signed copy of the limited partnership’s limited partnership agreement.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the limited partnership’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: 'Certified structure chart',
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_LPS,
          singularLabel: 'Limited partner of 10% or more',
          pluralLabel: 'Limited partners of 10% or more',
          helpText:
            'Add the names of all limited partners of 10% or more of the limited partnership',
        },
        {
          key: TYPE_GPS,
          singularLabel: 'General partner',
          pluralLabel: 'General partners',
          helpText:
            'Add the names of all general partners of the limited partnership',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: 'Signatory',
          pluralLabel: 'Signatories',
          helpText:
            'Add at least two signatories for the limited partnership, including the signatory that will execute the subscription documents.',
        },
      ],
    },
    [TYPE_PARTNERSHIP]: {
      documents: [
        {
          key: TYPE_CERT_COR,
          label: 'Certified certificate of registration',
          helpText:
            'Upload a certified copy of a certificate of registration or equivalent.',
        },
        {
          key: TYPE_CERT_PARTNERSHIP_AGREEMENT,
          label: 'Certified partnership agreement',
          helpText:
            'Upload a certified signed copy of the partnership’s partnership agreement.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the partnership’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: 'Certified structure chart',
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
        {
          key: TYPE_CERT_AFS,
          label: 'Certified copy of latest audited financial statements',
          helpText:
            'Upload a certified copy of the latest audited financial statements for the partnership, where available.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_PARTNERS,
          singularLabel: 'Partner of 10% or more',
          pluralLabel: 'Partners of 10% or more',
          helpText:
            'Add the names of all partners of 10% or more of the partnership.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: 'Signatory',
          pluralLabel: 'Signatories',
          helpText: 'Add the names of all signatories of the partnership.',
        },
      ],
    },
    [TYPE_PRIVATE_COMPANY]: {
      documents: [
        {
          key: TYPE_CERT_COI,
          label: 'Certified certificate of incorporation',
          helpText:
            'Upload a certified copy of the company’s certificate of incorporation.',
        },
        {
          key: TYPE_CERT_AOA,
          label: 'Certified memorandum and articles of association',
          helpText:
            'Upload a certified copy of the company’s memorandum and articles of association.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the company’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_DIRECTORS,
          label: 'Certified register of directors',
          helpText: 'Upload a certified register of the company’s directors.',
        },
        {
          key: TYPE_CERT_REGISTER_OF_SHAREHOLDERS,
          label: 'Certified register of shareholders',
          helpText:
            'Upload a certified register of the company’s shareholders.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: 'Certified structure chart',
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Directors',
          helpText:
            'Add the names of at least two directors for the private company.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: 'Signatory',
          pluralLabel: 'Signatories',
          helpText: 'Add the names of all signatories of the private company.',
        },
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the private company.',
        },
      ],
    },
    [TYPE_LISTED_COMPANY]: {
      documents: [
        {
          key: TYPE_EVIDENCE_OF_LISTING,
          label: 'Evidence of listing on a recognized stock exchange',
          helpText:
            'Upload evidence of the company’s listing on a recognized stock exchange.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the company’s behalf in relation to its investment), together with specimen signatures.',
        },
      ],
      owners: [
        {
          key: TYPE_BENEFICIAL_OWNERS,
          singularLabel: 'Beneficial owner of 10% or more',
          pluralLabel: 'Beneficial owners of 10% or more',
          helpText:
            'Add the names of all beneficial owners of 10% or more of the listed company.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: 'Signatory',
          pluralLabel: 'Signatories',
          helpText: 'Add the names of all signatories of the listed company.',
        },
      ],
    },
    [TYPE_LLC]: {
      documents: [
        {
          key: TYPE_CERT_COF,
          label: 'Certified certificate of formation',
          helpText:
            'Upload a certified copy of the limited liability company’s certificate of formation.',
        },
        {
          key: TYPE_CERT_OPERATING_AGREEMENT,
          label: 'Certified operating agreement',
          helpText:
            'Upload a certified copy of the limited liability company’s operating agreement.',
        },
        {
          key: TYPE_CERT_DOM,
          label: 'Certified schedule of all managers',
          helpText:
            'Upload a certified schedule indicating the ownership of all managers of the LLC.',
        },
        {
          key: TYPE_CERT_ORG_CHART,
          label: 'Certified organizational chart',
          helpText:
            'Upload a certified copy of the limited liability company’s organizational chart.',
        },
      ],
      owners: [
        {
          key: TYPE_MEMBERS,
          singularLabel: 'Member of 10% or more',
          pluralLabel: 'Members of 10% or more',
          helpText:
            'Add the names of all of the members of 10% or more of the limited liability company.',
        },
        {
          key: TYPE_MANAGERS,
          singularLabel: 'Manager',
          pluralLabel: 'Managers',
          helpText:
            'Add the names of all of the managers of the limited liability company.',
        },
      ],
    },
  },
  [GROUP_OTHER]: {
    [TYPE_PRIVATE_TRUST]: {
      documents: [
        {
          key: TYPE_CERT_TRUST_DEED,
          label: 'Certified trust deed',
          helpText:
            'Upload a certified copy of the trust deed with any amendments.',
        },

        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the trust’s behalf in relation to its investment), together with specimen signatures.',
        },
        {
          key: TYPE_CERT_STRUCTURE_CHART,
          label: 'Certified structure chart',
          helpText:
            'Upload a certified structure chart (only required for multi-level structured entities).',
          optional: true,
        },
        {
          key: TYPE_CERT_AFS,
          label: 'Certified copy of latest audited financial statements',
          helpText:
            'Upload a certified copy of the latest audited financial statements for the trust, where available.',
          optional: true,
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: 'Trustee',
          pluralLabel: 'Trustees',
          helpText:
            'Add the names of all of the trustees of the private trust.',
        },
        {
          key: TYPE_GRANTORS,
          singularLabel: 'Grantor / Settlor',
          pluralLabel: 'Grantors / Settlors',
          helpText: 'Add the names of all grantors of the private trust.',
        },
        {
          key: TYPE_PROTECTORS,
          singularLabel: 'Protector',
          pluralLabel: 'Protectors',
          helpText: 'Add the names of all protector of the private trust.',
        },
        {
          key: TYPE_ENFORCERS,
          singularLabel: 'Enforcer',
          pluralLabel: 'Enforcers',
          helpText: 'Add the names of all enforcers of the private trust.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Primary beneficiary',
          pluralLabel: 'Primary beneficiaries',
          helpText:
            'Add the names of all primary beneficiaries of the private trust.',
        },
      ],
    },
    [TYPE_PENSION_SCHEME]: {
      documents: [
        {
          key: TYPE_CERT_AML_LETTER,
          label: 'Certified AML letter',
          helpText:
            'Upload a certified letter attesting that there is no substantive money laundering activity associated with the pension scheme.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the trust’s behalf in relation to its investment), together with specimen signatures.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: 'Trustee',
          pluralLabel: 'Trustees',
          helpText: 'Add the names of all trustees of the pension scheme.',
        },
        {
          key: TYPE_SIGNATORIES,
          singularLabel: 'Signatory',
          pluralLabel: 'Signatories',
          helpText: 'Add the names of all signatories of the pension scheme.',
        },
      ],
    },
    [TYPE_CHARITY_OR_FOUNDATION]: {
      documents: [
        {
          key: TYPE_CERT_FORMATION_DOCUMENT,
          label: 'Certified formation document',
          helpText:
            'Upload a certified copy of the charity or foundation’s formation document.',
        },
        {
          key: TYPE_COPOC,
          label: 'Confirmation of nature / purpose of charity',
          helpText:
            'Upload a confirmation of the charity or foundation’s nature or purpose.',
        },
        {
          key: TYPE_COAS,
          label: 'Confirmation of approved status',
          helpText:
            'Upload a confirmation of the charity or foundation’s approved status.',
        },
        {
          key: TYPE_CERT_AFS,
          label: 'Certified audited financials',
          helpText:
            'Upload a certified copy of the charity or foundation’s audited financial statements.',
        },
        {
          key: TYPE_LETTER_FROM_EXTERNAL_AUDITOR,
          label: 'Letter of good standing from external auditor',
          helpText:
            'Upload a letter of good standing from an external auditor.',
        },
        {
          key: TYPE_CERT_AML_LETTER,
          label: 'Certified AML letter',
          helpText:
            'Upload a certified letter attesting there is not substantive money laundering activity associated with the charity or foundation.',
        },
        {
          key: TYPE_CERT_DONORS_OVER_1OK,
          label: 'Certified schedule of major donors over $10,000',
          helpText:
            'Upload a certified schedule of the charity’s major donors of more than $10,000.',
        },
      ],
      owners: [
        {
          key: TYPE_TRUSTEES,
          singularLabel: 'Trustee',
          pluralLabel: 'Trustees',
          helpText:
            'Add the names of all trustees of the charity or foundation.',
        },
        {
          key: TYPE_DIRECTORS,
          singularLabel: 'Director',
          pluralLabel: 'Directors',
          helpText:
            'Add the names of all directors of the charity or foundation.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Beneficiary',
          pluralLabel: 'Beneficiaries',
          helpText:
            'Add the names of all beneficiaries of the charity or foundation.',
        },

        {
          key: TYPE_RECIPIENTS,
          singularLabel: 'Primary recipient of funds',
          pluralLabel: 'Primary recipients of funds',
          helpText:
            'Add the names of all primary recipients of funds of the charity or foundation.',
        },
      ],
    },
    [TYPE_SCHOOL_ENDOWMENT]: {
      documents: [
        {
          key: TYPE_CERT_FORMATION_DOCUMENT,
          label: 'Certified constitution or formation document',
          helpText:
            'Upload a certified copy of the school, college, or university’s constitution or formation document.',
        },
        {
          key: TYPE_CONFIRMATION_OF_OWNERSHIP,
          label: 'Confirmation of ownership (public vs. private)',
          helpText:
            'Upload a document confirming the school, college, or university’s ownership.',
        },
        {
          key: TYPE_CERT_SCHEDULE_OF_MAJOR_DONORS,
          label: 'Certified schedule of major donors',
          helpText:
            'Upload a certified schedule of the school, college, or university’s major donors.',
        },
        {
          key: TYPE_CERT_ORG_CHART,
          label: 'Certified organizational chart',
          helpText:
            'Upload a certified copy of the school, college, or university’s organizational chart.',
        },
        {
          key: TYPE_CERT_ASL,
          label: 'Certified copy of authorized signatory list',
          helpText:
            'Upload a certified copy of the authorized signatory list (including all individuals authorized to act on the school, college, or university’s behalf in relation to its investment), together with specimen signatures.',
        },
      ],
      owners: [
        {
          key: TYPE_CIO_COMMITTEE_MEMBERS,
          singularLabel: 'Chief Investment Officer (CIO)',
          pluralLabel: 'Chief Investment Officer (CIO)',
          helpText:
            'Add the name of the Chief Investment Officer (CIO) of the school, college, or university.',
        },
        {
          key: TYPE_BENEFICIARIES,
          singularLabel: 'Beneficiary',
          pluralLabel: 'Beneficiaries',
          helpText:
            'Add the names of all beneficiaries of the school, college, or university.',
        },
      ],
    },
  },
};
