import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ExternalLogo } from 'components/logo';
import { Copyright } from 'pages/lp_doc/copyright_v2';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100dvh',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    alignSelf: 'stretch',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function SignInPageContainer({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <ExternalLogo />
      </div>
      <div className={classes.container}>{children}</div>
      <Copyright />
    </div>
  );
}
