import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { QuestionSelector } from './question_selector';

const useStyles = makeStyles((theme) => ({
  fragmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fragments: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  addTopMargin: {
    marginTop: theme.spacing(2),
  },
}));

export function MultiQuestionSelector({
  questions,
  questionIds,
  onUpdate,
  op,
}) {
  const classes = useStyles();
  const [showExtra, setShowExtra] = React.useState(!questions.length);

  const addRow = (qId) => {
    onUpdate([...questionIds, qId]);
  };

  const deleteRow = (qId) => {
    onUpdate(questionIds.filter((id) => id !== qId));
  };

  const onSelect = (questionId, idx) => {
    onUpdate(questionIds.map((id, i) => (i === idx ? questionId : id)));
  };

  return (
    <div>
      <div className={classes.fragmentContainer}>
        <Divider orientation="vertical" />
        <div className={classes.fragments}>
          {questionIds.map((qId, idx) => (
            <div key={qId}>
              <QuestionSelector
                questionId={qId}
                updateAnswerTo={(e) => {
                  if (e.target.value) {
                    onSelect(e.target.value, idx);
                  }
                }}
                questions={questions.filter(
                  (q) => q.id === qId || !questionIds.includes(q.id),
                )}
                addRow={
                  idx < questionIds.length - 1 || showExtra
                    ? null
                    : () => setShowExtra(true)
                }
                deleteRow={() => deleteRow(qId)}
                op={idx === 0 ? null : op}
              />
            </div>
          ))}

          {showExtra || !questionIds.length ? (
            <>
              <QuestionSelector
                questionId=""
                updateAnswerTo={(e) => {
                  if (e.target.value) {
                    addRow(e.target.value);
                    setShowExtra(false);
                  }
                }}
                questions={questions.filter((q) => !questionIds.includes(q.id))}
                addRow={null}
                deleteRow={() => setShowExtra(false)}
                op={op}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
