import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Button, Typography } from '@passthrough/uikit';
import { Collapse } from '@material-ui/core';
import { AutoFillInput } from './auto_fill_input';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  advanced: {
    display: 'flex',
  },
}));

const SettingItem = ({
  title,
  description,
  checked,
  onChange,
  children,
  name,
}) => (
  <Paper variant="outlined" elevation={0}>
    <ListItem>
      <ListItemIcon>
        <Checkbox checked={checked} onChange={onChange} name={name} />
      </ListItemIcon>
      <ListItemText
        primary={<strong>{title}</strong>}
        secondary={description}
      />
    </ListItem>
    {children}
  </Paper>
);

export const AdvancedSettings = ({
  hasCustomLogic,
  setHasCustomLogic,
  notInteroperable,
  setNotInteroperable,
  disableInteroperableNote,
  autoFillEnabled,
  questions,
  answerType,
  currentQuestionId,
  autoFill,
  setAutoFill,
  hiddenEnabled,
  hidden,
  setHidden,
  showAdvanced,
  toggleAdvanced,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        variant="text"
        endIcon={showAdvanced ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={toggleAdvanced}
      >
        <Typography variant="card-heading" className={classes.advanced}>
          Advanced settings
        </Typography>
      </Button>

      <Collapse in={showAdvanced} unmountOnExit>
        <List className={classes.root}>
          {!disableInteroperableNote ? (
            <SettingItem
              title="Not interoperable"
              name="notInteroperable"
              description="When checked, this question will not be reused in other subscriptions or saved to investor profiles."
              checked={notInteroperable}
              onChange={(e) => {
                setNotInteroperable(e.target.checked);
              }}
            />
          ) : null}

          <SettingItem
            title="Needs custom logic"
            name="hasCustomLogic"
            description="When checked, an alert will appear on the onboarding page if the custom logic has not been implemented."
            checked={hasCustomLogic || false}
            onChange={(e) => {
              setHasCustomLogic(e.target.checked);
            }}
          />

          {hiddenEnabled ? (
            <SettingItem
              title="Hide question"
              name="hidden"
              description="When checked, this question will not be visible to the investor."
              checked={hidden}
              onChange={(e) => {
                setHidden(e.target.checked);
              }}
            />
          ) : null}

          {autoFillEnabled ? (
            <SettingItem
              title="Autofill"
              name="autoFill"
              description="When checked, a pre-determined value or formula can be used to prefill this question. If the question is shown to the investor, they will have the option to edit the value."
              checked={Boolean(autoFill)}
              onChange={(e) => {
                if (e.target.checked) {
                  setAutoFill({
                    type: 'copy',
                    raw: '',
                    questionIds: [],
                    questionId: '',
                  });
                } else {
                  setAutoFill(null);
                }
              }}
            >
              {autoFill ? (
                <AutoFillInput
                  currentQuestionId={currentQuestionId}
                  questions={questions}
                  answerType={answerType}
                  autoFill={autoFill}
                  setAutoFill={setAutoFill}
                />
              ) : null}
            </SettingItem>
          ) : null}
        </List>
      </Collapse>
    </div>
  );
};
