import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { Typography, Icons, Button, Chip } from '@passthrough/uikit';

import { MethodTable } from 'pages/mfa_setup_flow/method_table';
import * as constants from 'pages/mfa_setup_flow/constants';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { Button as OldButton } from 'components/button';
import { useMe } from 'services/providers/me';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { PageContainer } from 'components/page_container';
import { AddPassword } from './add_password';
import { ChangePassword } from './change_password';
import { NameForm } from './name_form';
import { NameModal } from './name_modal';
import { DisableMFAModal } from './disable_mfa_modal';
import { CTAForMFA } from './cta_for_mfa';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 1),
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  withChip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  gap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  // Remove when removing the MFA_UI flag
  submit: {
    marginTop: theme.spacing(3),
  },
}));

const NAME_MODAL = 'name';
const DISABLE_MODAL = 'disable';

export function AccountPage({ goToMFASetup }) {
  const classes = useStyles();
  const [me, onChangeMe] = useMe();
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(me.firstName || '');
  const [lastName, setLastName] = useState(me.lastName || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [enablePassword, setEnablePassword] = useState(me.hasPassword);
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordScore, setPasswordScore] = useState(null);
  const [passwordWarning, setPasswordWarning] = useState(null);
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);
  const [retypePassword, setRetypePassword] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [modal, setModal] = useState(null);
  const { successToast } = useToast();

  const { MFA_UI } = useFeatureFlags();

  const passwordChanged =
    (enablePassword === true && me.hasPassword === false) || oldPassword;
  const disabled =
    firstName === me.firstName && lastName === me.lastName && !passwordChanged;

  const fullName = `${me.firstName} ${me.lastName}`;
  const mfaMethod = constants.getMethod({
    isTotp: me.totpEnabled,
    isSms: me.smsEnabled,
  });

  function onSubmit(e) {
    e.preventDefault();

    setSaving(true);

    const props = { firstName, lastName };

    if (!MFA_UI) {
      if (enablePassword) {
        props.password = password;
        props.retypePassword = retypePassword;
      }

      if (me.hasPassword) {
        props.oldPassword = oldPassword;
      }
    }

    api
      .saveUserSettings(props)
      .then(() => {
        setSaving(false);
        successToast('Settings updated.');
        setFirstNameError('');
        setLastNameError('');
        setPasswordError('');
        setOldPasswordError('');
        setRetypePasswordError('');
        onChangeMe();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setSaving(false);
          setFirstNameError(error.response.data.firstName);
          setLastNameError(error.response.data.lastName);
          setPasswordError(error.response.data.password);
          setOldPasswordError(error.response.data.oldPassword);
          setRetypePasswordError(error.response.data.retypePassword);
        }
      });
  }

  function checkPassword() {
    if (!password) {
      setPasswordScore(null);
      setPasswordError('');
      return;
    }

    api.checkPassword({ firstName, lastName, password }).then((response) => {
      const { score, feedback } = response.data;
      setPasswordScore(score);
      setPasswordWarning(feedback.warning);
      setPasswordSuggestions(feedback.suggestions);
    });
  }

  useEffect(checkPassword, [password]);
  useEffect(() => {
    setFirstName(me.firstName);
    setLastName(me.lastName);
  }, [me]);

  return (
    <>
      <PageContainer maxWidth="sm">
        <Paper className={classes.paper}>
          {/* This form tag will be unnecessary when the MFA_UI
          flag is removed */}
          <form onSubmit={onSubmit} autoComplete="off">
            <div className={classes.top}>
              <Icons.PersonOutline fontSize="large" />
              <Typography variant="section-heading">
                Account settings
              </Typography>
            </div>
            <KeyValuePairs>
              <KeyValuePair icon={<Icons.EmailOutlined />}>
                <Typography>{me.email}</Typography>
              </KeyValuePair>

              {MFA_UI ? (
                <>
                  <KeyValuePair icon={<Icons.PersonOutline />}>
                    <div className={classes.spaceBetween}>
                      <Typography>{fullName}</Typography>
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => setModal(NAME_MODAL)}
                      >
                        Edit
                      </Button>
                    </div>
                  </KeyValuePair>

                  {me.hasPassword && !me.mfaEnabled ? (
                    <KeyValuePair icon={<Icons.LockOutlined />}>
                      <div className={classes.spaceBetween}>
                        <div className={classes.withChip}>
                          <Typography>Password</Typography>
                          <Chip
                            label="Enabled"
                            variant="success"
                            size="small"
                          />
                        </div>
                        <Typography
                          variant="label"
                          size="small"
                          color="text.secondary"
                        >
                          Contact support to make changes
                        </Typography>
                      </div>
                    </KeyValuePair>
                  ) : null}

                  {me.requiresSaml ? null : (
                    <KeyValuePair
                      alignIconToTop
                      icon={<Icons.SecurityOutlined />}
                    >
                      <div className={classes.gap}>
                        <div className={classes.spaceBetween}>
                          <Typography variant="card-heading">
                            Two-factor authentication (2FA)
                          </Typography>
                          {me.mfaEnabled ? (
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => setModal(DISABLE_MODAL)}
                            >
                              Disable 2FA
                            </Button>
                          ) : null}
                        </div>
                        {me.mfaEnabled ? (
                          <MethodTable
                            totpEnabled={me.totpEnabled}
                            smsEnabled={me.smsEnabled}
                          />
                        ) : (
                          <CTAForMFA goToMFASetup={goToMFASetup} />
                        )}
                      </div>
                    </KeyValuePair>
                  )}
                </>
              ) : null}

              {MFA_UI ? null : (
                <>
                  <NameForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    firstNameError={firstNameError}
                    lastName={lastName}
                    setLastName={setLastName}
                    lastNameError={lastNameError}
                    disableGutters={false}
                  />
                  {me.hasPassword ? (
                    <ChangePassword
                      oldPassword={oldPassword}
                      setOldPassword={setOldPassword}
                      oldPasswordError={oldPasswordError}
                      password={password}
                      passwordError={passwordError}
                      passwordScore={passwordScore}
                      setPassword={setPassword}
                      passwordWarning={passwordWarning}
                      passwordSuggestions={passwordSuggestions}
                      retypePassword={retypePassword}
                      retypePasswordError={retypePasswordError}
                      setRetypePassword={setRetypePassword}
                    />
                  ) : (
                    <AddPassword
                      enablePassword={enablePassword}
                      setEnablePassword={setEnablePassword}
                      password={password}
                      passwordError={passwordError}
                      passwordScore={passwordScore}
                      setPassword={setPassword}
                      passwordWarning={passwordWarning}
                      passwordSuggestions={passwordSuggestions}
                      retypePassword={retypePassword}
                      retypePasswordError={retypePasswordError}
                      setRetypePassword={setRetypePassword}
                      socialProvider={me.socialProvider}
                    />
                  )}
                </>
              )}
            </KeyValuePairs>

            {MFA_UI ? null : (
              <OldButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                disabled={disabled}
                loading={saving}
              >
                Save
              </OldButton>
            )}
          </form>
        </Paper>
      </PageContainer>

      {MFA_UI ? (
        <>
          <NameModal
            open={modal === NAME_MODAL}
            onClose={() => setModal(null)}
            firstName={firstName}
            setFirstName={setFirstName}
            firstNameError={firstNameError}
            lastName={lastName}
            setLastName={setLastName}
            lastNameError={lastNameError}
            onSubmit={onSubmit}
            saving={saving}
          />
          <DisableMFAModal
            mfaMethod={mfaMethod}
            open={modal === DISABLE_MODAL}
            onClose={() => setModal(null)}
            userId={me.id}
          />
        </>
      ) : null}
    </>
  );
}
